<div class="main-container">
  <div class="popup-schedule" *ngIf="!isMobile">
    <div class="popup-schedule-inner" #schedule>
      <div class="schedule-title">
        <h3>График погашений займа</h3>
      </div>
      <div class="schedule-body">
        <table>
          <thead>
            <th>Период</th>
            <th>Остаток долга на начало периода (руб.)</th>
            <th>Часть основного долга (руб.)</th>
            <th>Процентная часть (руб.)</th>
            <th>Сумма для оплаты за период* (руб.)</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of paymentSchedules; let i = index">
              <tr *ngIf="i !== 0">
                <td class="period">{{ getPeriod(i) }}</td>
                <td>{{ paymentSchedules[i - 1].remain }}</td>
                <td>{{ item.debt }}</td>
                <td>{{ item.fee }}</td>
                <td>{{ +item.debt + +item.fee }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="schedule-footer">
        <span></span>
      </div>
    </div>
  </div>

  <div class="popup-schedule mobile" *ngIf="isMobile">
    <div class="popup-schedule-inner" #schedule>
      <div class="schedule-title">
        <h3>График погашений займа</h3>
        <h3>Период</h3>
      </div>
      <div class="schedule-body">
        <ul>
          <ng-container *ngFor="let item of paymentSchedules; let i = index">
            <li *ngIf="i !== 0">
              <div class="list-item-header">
                <div>до {{ getPeriod(i, 'mobile') }}</div>
                <div>{{ +item.debt + +item.fee }}</div>
                <div
                  class="toggle"
                  [ngClass]="item.additionalInfo ? 'open' : 'close'"
                  (click)="item.additionalInfo = !item.additionalInfo"
                ></div>
              </div>
              <div class="list-item-body" *ngIf="item.additionalInfo">
                <div class="payment">
                  <div class="payment-title">
                    Остаток долга на начало периода (руб.)
                  </div>
                  <div class="payment-value">
                    {{ paymentSchedules[i - 1].remain }}
                  </div>
                </div>
                <div class="payment">
                  <div class="payment-title">Часть основного долга (руб.)</div>
                  <div class="payment-value">{{ item.debt }}</div>
                </div>
                <div class="payment">
                  <div class="payment-title">Процентная часть (руб.)</div>
                  <div class="payment-value">{{ item.fee }}</div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="schedule-footer">
        <span></span>
      </div>
    </div>
  </div>
</div>
