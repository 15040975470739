<header
  *ngIf="isLoaded"
  [ngClass]="{ mobile: isMobile, 'vivus-style': brand === 'VIVUS' }"
>
  <div class="header row align-items-center">
    <div class="col-6 d-flex justify-content-center">
      <div class="header__logo">
        <a href="/">
          <img
            [src]="
              brand === 'VIVUS'
                ? 'assets/images/logo-vivus.png'
                : 'assets/images/smsfinance.png'
            "
            alt=""
          />
        </a>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-lg-center justify-content-end">
      <button pButton pRipple *ngIf="!client.firstName && loginPage" class="payment-btn" (click)="payment()">
        Оплатить
      </button>
      <button pButton pRipple *ngIf="!client.firstName" (click)="login()" class="login-btn">
        Личный кабинет
      </button>

      <button pButton pRipple *ngIf="client.firstName" (click)="openModal()" class="history-btn">
        История займов
      </button>
      <button
        *ngIf="client.firstName"
        (click)="logout()"
        pButton
        pRipple
        icon="pi pi-sign-out"
        class="p-button-rounded"
      ></button>
    </div>
  </div>
</header>
<app-history-loan [isOpen]="isOpen" *ngIf="client.firstName"></app-history-loan>
