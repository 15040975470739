<p-dialog
  [modal]="true"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  [(visible)]="isOpen"
  [draggable]="false"
  [header]="'История займов'"
>
  <table class="table" [ngClass]="[isMobile ? 'mobile' : '', brand === 'VIVUS' ? 'vivus-style' : '']">
    <thead>
    <tr>
      <th>Дата</th>
      <th>Номер договора</th>
      <th>Скачать</th>
    </tr>
    </thead>
    <br>
    <tbody>
    <ng-container *ngFor="let item of historyLoanData">
      <tr [ngClass]="item.active ? 'active-state' : ''">
        <td>{{item.issueDateConvert}}</td>
        <td>{{item.contractNumber}}</td>
        <td class="download-document" (click)="getDocumentIndividualContract('', item.issueDate)" style="">PDF</td>
      </tr>
    </ng-container>
    </tbody>
  </table>

  <app-document-link [ngClass]="[brand === 'VIVUS' ? 'vivus-style' : '', 'history-loan__footer']" [name]="'Справка об отсутствии задолженности'"
                     [documentType]="'ABSENCE_OF_DEBTS'"></app-document-link>
</p-dialog>
