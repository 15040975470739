import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable()
export class WindowRef {
  sendMatomoTitle(title: string) {
    _window().sendMatomoTitle(title);
  }
  pushMatomo(data: any) {
    _window()._paq.push(data);
  }
  sendPageVisitedEvent(data: any) {
    _window()._sendPageVisitedEvent(data);
  }
  get nativeWindow(): any {
    return _window();
  }
}
