import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[appAbTestVersion]',
})
export class abTestVersionDirective implements OnInit {
  private versions: string[];

  @Input()
  set abTestVersion(value: string) {
    this.versions = value.replace(/\s+/g, '').split(',');
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const elem: any = this.elementRef.nativeElement;
    const isExists: boolean = this.versions.some(
      (e) => e === environment.version
    );
    !isExists ? (elem.style.display = 'none') : '';
  }
}
