
export const environment = {
  production: true,
  apiUrl: "/registration/api",
  cabinetApiUrl: "/lk/api",
  apiUrlConfig: "/config",
  assetsPath: "/registration/",
  gtmPath: "/made",
  gtmTag: {'default': 'GTM_TAG_IS_NOT_FOUND_IN_DB', 'base': 'GTM-5MMB3JN'},
  gtmId: "GTM-TW7PNKG",
  brand: "SMSFINANCE",
  openCabinetUrlInNewContext: false,
  dadataSuggestionUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
  dadataToken: "85c82308f58161b9b05541c43ac1cb5255ede07a",
  scripts: ['https://consultsystems.ru/script/4200/'],
  googleAnalyticsId: "UA-25229005-1",
  yandexMapKey: "123456789",
  yandexMetrikaId: "20986729",
  yandexMetrikaIdI: "undefined",
  mailRuId: "3313479",
  version: "v2",
  imageUploadService: "/adddocs",
  equifaxPartnerId: "2691",
  captchaKey: "6LeZzoIpAAAAAHl9R0fITurkF0Uu0TLD21pN5ekW",
  juicyApiKey: "TITRbLuxAl4WGNJyY9WCYlfi",
};
