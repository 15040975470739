<p-dialog
  [modal]="true"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  [(visible)]="isShow"
  [draggable]="false"
>
  <h4 *ngIf="title" class="title">{{ title }}</h4>
  <ng-content></ng-content>
</p-dialog>
