import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientInfoService } from '../../services/client-info.service';
import * as moment from 'moment-mini-ts';
import { environment } from '../../../environments/environment';
import declOfNum from '../../utils/decl-of-number';
import { ActionsService } from 'src/app/api/actions.service';
import {
  InformationTabs,
  LoanNotificationCode,
} from './infomation-block.class';
import { Client } from 'src/app/classes/client.class';
import detectMobile from 'src/app/utils/detect-mobile';
import { Loans } from 'src/app/classes/loans.class';
import roundingForHundredths from 'src/app/utils/rounding-for-hundredths';
import {diffDateNow} from "../../utils/date-utils";

const NORMALLY = environment.brand === 'VIVUS' ? '#006d38' : '#1a5260';

const DAY_MILLISECOND = 24 * 60 * 60 * 1000;

@Component({
  selector: 'app-info-block',
  templateUrl: `information-block.component.html`,
  styleUrls: [`information-block.component.sass`],
})
export class InfoBlockComponent implements OnInit, OnDestroy {
  public brand: string;
  public client: Client;
  public loans: Loans;
  public isMobile = detectMobile();
  public infoDto: InformationTabs;

  public title: string;
  public bodyText: string;
  public countTitle: string;
  public countValue: number;
  public countSign: string;
  public lessHours: string;
  public amountToPayTitle: string;
  public amountToPayValue: number;
  public amountToPaySign: string;

  private _sub: Subscription = new Subscription();

  constructor(
    private _clientService: ClientInfoService,
    private _actionsService: ActionsService
  ) {}

  ngOnInit(): void {
    this.brand = environment.brand;
    this._sub.add(
      this._clientService.getClient().subscribe((res) => {
        this.client = {
          ...res,
          limit: Math.round(res.limit),
        };
        this.updateBlock();
      })
    );
    this._sub.add(
      this._clientService.getLoans().subscribe((res) => {
        this.loans = res;
        this.updateBlock();
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  /**
   * Update information content
   */
  public updateBlock(): void {
    this._actionsService
      .getInfoBlock()
      .then((response) => {
        this.infoDto = response;
        this.applySetting(this.infoDto.code);
        // this.applySetting('FIRST_GRACE_END');
      })
      .catch((error) => {});
  }

  /**
   * Setting appropriate a text, title and conter
   * @param {type: LoanNotificationCode}
   */
  // public applySetting(type: LoanNotificationCode): void {
  public applySetting(type): void {
    switch (type) {
      case LoanNotificationCode.EXTENDABLE_GRACE_PERIOD:
        this.countTitle = 'До погашения без процентов';
        this.setCounter(this.infoDto.model.graceLastDate);
        this.title = `<a href="${
          this.infoDto.model.campaignLink
        }" target="_blank">Акция</a> действует до ${this.getDate(
          this.infoDto.model.graceLastDate
        )}.`;
        if (!this.infoDto.model.enoughMoneyOrGraceDiscountUnavailable) {
          this.title += ` Продление срока еще на неделю - ${this.loans.graceProlongAmount} р.`;
        }
        this.bodyText = '';
        break;
      case LoanNotificationCode.EXTENDABLE_GRACE_LOAN_OVERDUE_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = this.infoDto.model.graceActive
          ? `<a href="${
              this.infoDto.model.campaignLink
            }" target="_blank">Акция</a> будет отменена. Закройте заём или продлите его до ${this.getDate(
              this.infoDto.model.techExpirationEndDay
            )}, иначе комиссия за ${
              this.infoDto.model.gracePeriod
            } дней беспроцентного периода будет пересчитана под ${roundingForHundredths(
              this.loans.stake
            )}% в день`
          : `<a href="${
              this.infoDto.model.campaignLink
            }" target="_blank">Акция</a> отменена. Закройте заём или продлите его до ${this.getDate(
              this.infoDto.model.techExpirationEndDay
            )}, тогда штрафы за просрочку не будут начислены`;
        this.bodyText = this.getBodyText();
        break;
      case LoanNotificationCode.EXTENDABLE_GRACE_PERIOD_OVERDUE:
        this.countTitle = 'До погашения';
        this.setCounter(this.infoDto.model.paymentDate);
        this.title = `<a href="${this.infoDto.model.campaignLink}" target="_blank">Акция</a> отменена.`;
        this.bodyText = `Заём не был погашен в беспроцентный период. Проценты за пользование займом пересчитаны по ставке ${roundingForHundredths(
          this.loans.stake
        )}% в день.`;
        break;
      case LoanNotificationCode.EXTENDABLE_GRACE_LOAN_PROLONG:
        this.countTitle = 'До погашения';
        this.getDaysHours();
        this.title = this.infoDto.model.graceActive
          ? `<a href="${this.infoDto.model.campaignLink}" target="_blank">Акция</a> активна. Чтобы сохранить скидку, не допускайте просрочек`
          : '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.EXTENDABLE_GRACE_LOAN_OVERDUE_NO_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        break;
      case LoanNotificationCode.ANNUITY_NO_OVERDUE:
        this.countTitle = 'До оплаты за период';
        this.setCounter(this.infoDto.model.periodEndDay);
        this.title = '';
        this.bodyText = '';
        this.setAmountToPay(this.infoDto.model.amountToPay);
        break;
      case LoanNotificationCode.ANNUITY_OVERDUE:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.annuityOverdueDays;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        this.setAmountToPay(this.infoDto.model.amountToPay);
        break;
      case LoanNotificationCode.OTHER_ISSUE_PERIOD:
        this.countTitle = 'До погашения';
        this.setCounter(this.infoDto.model.paymentDate);
        this.title = '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.OTHER_LOAN_PROLONG:
        this.countTitle = 'До погашения';
        this.getDaysHours();
        this.title = '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.OTHER_LOAN_OVERDUE_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        break;
      case LoanNotificationCode.OTHER_LOAN_OVERDUE_NO_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        break;
      case LoanNotificationCode.PROLONG_RESTRICTION:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.getDaysDiff(this.loans.returnDate);
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = `По вашему займу достигнута предельная сумма начислений, компания не может принимать оплату продления займа согласно требованиям законодательства. Чтобы сохранить кредитную историю, закройте заём.`;
        break;
      case LoanNotificationCode.NONE:
        break;
      case LoanNotificationCode.GRACE_PERIOD:
        this.countTitle = 'До погашения без процентов';
        if (this.loans.loanPeriod === 15) {
          // костыль определения продукта PDL15
          this.countTitle = 'До погашения';
          this.getDaysHours();
          break;
        }
        console.log(this.infoDto.model);
        this.getDaysHours();
        this.title = '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.AFTER_GRACE_PERIOD:
        this.countTitle = 'До погашения';
        this.setCounter(this.infoDto.model.paymentDate);
        this.title = '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.GRACE_LOAN_PROLONG:
        this.countTitle = 'До погашения';
        this.getDaysHours();
        this.title = '';
        this.bodyText = '';
        break;
      case LoanNotificationCode.GRACE_LOAN_OVERDUE_NO_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        break;
      case LoanNotificationCode.GRACE_LOAN_OVERDUE_TECH_PERIOD:
        this.countTitle = 'Заём просрочен на';
        this.countValue = this.infoDto.model.unpaidOverdueDaysLeft;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
        this.title = '';
        this.bodyText = this.getBodyText();
        break;
      default:
        break;
    }
  }

  /**
   * Setting amountToPay value
   */
  private setAmountToPay(value): void {
    if (!isNaN(value)) {
      if (this.loans.isLastPayment) {
        this.amountToPayTitle = 'Сумма для полного закрытия';
      } else {
        this.amountToPayTitle = 'Сумма платежа по графику';
      }
      this.amountToPayValue = value;
      this.amountToPaySign = 'руб';
    }
  }

  /**
   * Setting counter value
   */
  private setCounter(date): void {
    const days = this.getDaysDiff(date);
    const hours = diffDateNow(date, 'hours')
    if (!isNaN(days)) {
      if (days > 0) {
        this.countValue = days;
        this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
      } else {
        this.countValue = diffDateNow(date, 'hours')
        this.countSign = declOfNum(this.countValue, ['час', 'часа', 'часов']);
        if(hours <= 0) {
          this.countValue = null
          this.countSign = ''
          this.lessHours = 'менее часа'
        }
      }
    }
  }

  private getDaysHours() {
    if(this.infoDto.model.dayBeforeDeadline > 0) {
      this.countValue = this.infoDto.model.dayBeforeDeadline
      this.countSign = declOfNum(this.countValue, ['день', 'дня', 'дней']);
    } else {
      this.countValue = this.infoDto.model.hourBeforeDeadline
      this.countSign = declOfNum(this.infoDto.model.hourBeforeDeadline, ['час', 'часа', 'часов']);
      if(this.infoDto.model.hourBeforeDeadline <= 0) {
        this.countValue = null
        this.countSign = ''
        this.lessHours = 'менее часа'
      }
    }
  }

  /**
   * Return "days left"
   * @param {maxDate: string}
   * @returns {daysLeft: number}
   */
  private getDaysDiff(date): number {
    const time = moment(date)
      .utc()
      .format('DD.MM.YYYY')
      .split('.')
      .map((i) => parseInt(i));
    const lastDate = new Date(
      Date.UTC(time[2], time[1] - 1, time[0], 20, 59, 59)
    );
    try {
      const diff = Math.abs(
        Math.floor((lastDate.valueOf() - new Date().valueOf()) / 3600000)
      );
      return diff > 23 ? Math.floor(diff / 24) : 0;
    } catch {
      return null;
    }
  }

  /**
   * Setting body text
   */
  private getBodyText(): string {
    let phonesSMSFINANCE = [];
    let phonesVIVUS = [];
    if (this.loans.legalEntity === 'FOUR_FINANCE') {
      phonesSMSFINANCE = ['8 (800) 700-27-57', '8 (800) 700-10-25'];
      phonesVIVUS = ['8 (800) 770-04-93', '8 (800) 700-59-05'];
    }
    if (this.loans.legalEntity === 'JMM') {
      phonesSMSFINANCE = ['8 (800) 600-92-23', '8 (800) 600-97-95'];
      phonesVIVUS = ['8 (800) 700-30-52', '8 (800) 770-76-50'];
    }
    if (this.client.collectorId) {
      return ``;
    }
    if (
      this.infoDto.model.unpaidOverdueDaysLeft >= 1 &&
      this.infoDto.model.unpaidOverdueDaysLeft <= 30
    ) {
      if (this.brand == 'SMSFINANCE') {
        return `В случае объективных причин задержки осуществления
                             платежа позвоните нам по телефону <span class="number">${phonesSMSFINANCE[0]}</span>.
                             Наши специалисты подберут для вас оптимальный способ урегулирования задолженности.`;
      } else if (this.brand == 'VIVUS') {
        return `Позвоните нам по телефону <span class="number">${phonesVIVUS[0]}</span>.
                             Наши специалисты подберут для вас оптимальный способ урегулирования задолженности.`;
      }
    } else if (this.infoDto.model.unpaidOverdueDaysLeft >= 31) {
      if (this.brand == 'SMSFINANCE') {
        return `В случае объективных причин задержки осуществления
                             платежа позвоните нам по телефону <span class="number">${phonesSMSFINANCE[1]}</span>.
                             Наши специалисты подберут для вас оптимальный способ урегулирования задолженности.`;
      } else if (this.brand == 'VIVUS') {
        return `Позвоните нам по телефону <span class="number">${phonesVIVUS[1]}</span>.
                             Наши специалисты подберут для вас оптимальный способ урегулирования задолженности.`;
      }
    }
  }

  /**
   * The method return date in string format
   * @param {date: number}
   * @return {date: string}
   */
  getDate(date: number): string {
    return moment(date).utc().format('DD.MM.YYYY');
  }
}
