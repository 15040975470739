import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CookieModule } from 'ngx-cookie';

import { routing } from './routing.module';

import {
  AnalyticsEventsService,
  GoogleAnalytics,
  MailAnalytics,
} from './services/analytic.service';
import { ClientActionService } from './services/client-action.service';
import { StorageService } from './services/storage.service';
import { ApiService } from './api/api.service';
import { ActionsService } from './api/actions.service';
import { PaymentService } from './services/payment.service';
import { ScoringService } from './services/scoring.service';
import { CreditCalculatorService } from './services/credit-calculator.service';
import { ClientInfoService } from './services/client-info.service';
import { PermissionService } from './services/permission.service';
import { CabinetService } from './services/cabinet.service';

import { AppComponent } from './app.component';
import { CurrentPageGuard } from './components/shared/guards/current-page.guard';
import { CheckRegistrationFormGuard } from './components/shared/guards/check-registration-form.guard';
import { ClientStatusGuard } from './components/shared/guards/client-status.guard';
import { PreventRouting } from './components/shared/guards/prevent-routing.guard';
import { ConsultantComponent } from './components/shared/components/consultant/consultant.component';
import { UserPhotoComponent } from './components/shared/components/user-photo/user-photo.component';
import { CabinetComponent } from './containers-cabinet/cabinet/cabinet.component';
import { YandexMapService } from './services/yandex-map.service';
import { CommonGtmContainerComponent } from './funnel/common-gtm-container.component';

import { WindowRef } from './classes/global-ref';
import { AuthService } from './services/auth.service';
import { IssuePointsService } from './services/issue-points.service';
import { PopupService } from './services/popup.service';
import { HeaderComponent } from './components/shared/components/header/header.component';
import { FooterComponent } from './components/shared/components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { DuplicationService } from './services/duplication.service';
import { DividerModule } from 'primeng/divider';
import {AuthenticatedGuard} from "./components/shared/guards/authenticated.guard";
import {RegistrationService} from "./services/registration.service";
import {HistoryLoanModule} from "./components/history-loan/history-loan.module";
import {DialogModule} from "primeng/dialog";

registerLocaleData(localeRu, 'ru');

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        routing,
        CookieModule.forRoot(),
        HttpClientModule,
        FormsModule,
        RecaptchaModule,
        ButtonModule,
        RippleModule,
        LoadingBarRouterModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        DividerModule,
        HistoryLoanModule,
        DialogModule,
    ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ConsultantComponent,
    CabinetComponent,
    UserPhotoComponent,
    CommonGtmContainerComponent,
  ],
  providers: [
    CurrentPageGuard,
    AuthenticatedGuard,
    GoogleAnalytics,
    MailAnalytics,
    CheckRegistrationFormGuard,
    PreventRouting,
    ClientActionService,
    StorageService,
    ApiService,
    ActionsService,
    AnalyticsEventsService,
    PaymentService,
    ScoringService,
    CreditCalculatorService,
    WindowRef,
    YandexMapService,
    ClientInfoService,
    PermissionService,
    ClientStatusGuard,
    IssuePointsService,
    PopupService,
    ConfirmationService,
    DuplicationService,
    CabinetService,
    RegistrationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [CommonGtmContainerComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
