export enum LoanNotificationCode {
  // FIRST_LOAN_GRACE_PERIOD = 'FIRST_LOAN_GRACE_PERIOD', // Non-prolongation grace period
  // FIRST_LOAN_AFTER_GRACE_PERIOD = 'FIRST_LOAN_AFTER_GRACE_PERIOD', // Main loan period without grace
  // FIRST_LOAN_OVERDUE_TECH_PERIOD = 'FIRST_LOAN_OVERDUE_TECH_PERIOD', // Overdue with tech period
  // FIRST_LOAN_OVERDUE_NO_TECH_PERIOD = 'FIRST_LOAN_OVERDUE_NO_TECH_PERIOD', // Overdue without tech period
  // FIRST_LOAN_PROLONG = 'FIRST_LOAN_PROLONG', // Prolongation loan period
  // FOR_REPEATERS_GRACE_PERIOD = 'FOR_REPEATERS_GRACE_PERIOD', // Prolongation grace period
  // FOR_REPEATERS_GRACE_PERIOD_OVERDUE = 'FOR_REPEATERS_GRACE_PERIOD_OVERDUE', // Main loan period without grace
  // FOR_REPEATERS_OVERDUE_TECH_PERIOD = 'FOR_REPEATERS_OVERDUE_TECH_PERIOD', // Overdue with tech period
  // FOR_REPEATERS_OVERDUE_NO_TECH_PERIOD = 'FOR_REPEATERS_OVERDUE_NO_TECH_PERIOD', // Overdue without tech period
  // FOR_REPEATERS_PROLONG = 'FOR_REPEATERS_PROLONG', // Prolongation loan period
  // OTHER_ISSUE_PERIOD = 'OTHER_ISSUE_PERIOD', // Main loan period without grace
  // OTHER_PROLONG = 'OTHER_PROLONG', // Prolongation loan period
  // OTHER_OVERDUE_TECH_PERIOD = 'OTHER_OVERDUE_TECH_PERIOD', // Overdue with tech period
  // OTHER_OVERDUE_NO_TECH_PERIOD = 'OTHER_OVERDUE_NO_TECH_PERIOD', // Overdue without tech period
  // PROLONG_RESTRICTION = 'PROLONG_RESTRICTION',
  // ANNUITY_NO_OVERDUE = 'ANNUITY_NO_OVERDUE', // Main annuity loan period
  // ANNUITY_OVERDUE = 'ANNUITY_OVERDUE', // Overdue annuity loan
  // NONE = 'NONE' // Info tabs closed

  ANNUITY_NO_OVERDUE = 'ANNUITY_NO_OVERDUE', // Аннуитет (МИГ с ежедвухнедельными платежами)	Займ активен, платёж не просрочен
  ANNUITY_OVERDUE = 'ANNUITY_OVERDUE', // Аннуитет (МИГ с ежедвухнедельными платежами)	Займ активен, платёж просрочен
  GRACE_PERIOD = 'GRACE_PERIOD', // Заем с непродляемым грейсом	Грейс активен
  AFTER_GRACE_PERIOD = 'AFTER_GRACE_PERIOD', // Заем с непродляемым грейсом	Займ активен, срок не вышел, грейс не активен
  GRACE_LOAN_PROLONG = 'GRACE_LOAN_PROLONG', // Заем с непродляемым грейсом	Займ продлён
  GRACE_LOAN_OVERDUE_TECH_PERIOD = 'GRACE_LOAN_OVERDUE_TECH_PERIOD', // Заем с непродляемым грейсом	Займ просрочен, ПТП активен
  GRACE_LOAN_OVERDUE_NO_TECH_PERIOD = 'GRACE_LOAN_OVERDUE_NO_TECH_PERIOD', // Заем с непродляемым грейсом	Займ просрочен, ПТП не активен
  EXTENDABLE_GRACE_PERIOD = 'EXTENDABLE_GRACE_PERIOD', // PDL-займ с пролонгируемым грейсом	Грейс активен
  EXTENDABLE_GRACE_PERIOD_OVERDUE = 'EXTENDABLE_GRACE_PERIOD_OVERDUE', // PDL-займ с пролонгируемым грейсом	Займ активен, срок не вышел, грейс не активен
  EXTENDABLE_GRACE_LOAN_PROLONG = 'EXTENDABLE_GRACE_LOAN_PROLONG', // PDL-займ с пролонгируемым грейсом	Займ продлён (не грейс, а именно займ)
  EXTENDABLE_GRACE_LOAN_OVERDUE_TECH_PERIOD = 'EXTENDABLE_GRACE_LOAN_OVERDUE_TECH_PERIOD', // PDL-займ с пролонгируемым грейсом	Займ просрочен, ПТП активен
  EXTENDABLE_GRACE_LOAN_OVERDUE_NO_TECH_PERIOD = 'EXTENDABLE_GRACE_LOAN_OVERDUE_NO_TECH_PERIOD', // PDL-займ с пролонгируемым грейсом	Займ просрочен, ПТП не активен
  OTHER_ISSUE_PERIOD = 'OTHER_ISSUE_PERIOD', // PDL-займ без грейса	Займ активен, срок не вышел
  OTHER_LOAN_PROLONG = 'OTHER_LOAN_PROLONG', // PDL-займ без грейса	Займ продлён
  OTHER_LOAN_OVERDUE_TECH_PERIOD = 'OTHER_LOAN_OVERDUE_TECH_PERIOD', // PDL-займ без грейса	Займ просрочен, ПТП активен
  OTHER_LOAN_OVERDUE_NO_TECH_PERIOD = 'OTHER_LOAN_OVERDUE_NO_TECH_PERIOD', // PDL-займ без грейса	Займ просрочен, ПТП не активен
  PROLONG_RESTRICTION = 'PROLONG_RESTRICTION', // Любой PDL-займ	Займ достиг ограничения по ОД и находится на просрочке
  NONE = 'NONE', // Не подходит не под один статус выше
}

interface FirstLoanGracePeriod {
  graceLastDate: number; // Last day of grace period
  campaingnLink: string; // Information about action
}
interface FirstLoanAfterGracePeriod {
  paymentDate: number; // Last day of main loan period
  campaingnLink: string; // Information about action
}
interface FirstLoanOverdueTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
  techExpirationEndDay: number; // The last day of the technical delay period for the loan
}
interface FirstLoanOverdueNoTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
}
interface FirstLoanProlong {
  autoProlongDaysLeft: number; // The number of days for which the prolongation amount is enough for the client’s balance
  amount: number; // Own funds
  autoProlongEndDate: number; // Date for prolongation to which there is enough amount on the balance sheet
}
interface ForRepeatersGracePeriod {
  amountToKeepGraceDiscount: number; // Additional amount to save interest free period
  amountFor1DayProlongGrace: number; // Commission amount for 1 days of prolongation
  enoughMoneyOrGraceDiscountUnavailable: boolean; // Last grace period or not
  gracePlusGraceProlongEndDate: number; // The end date of the current grace period plus the days of the grace period / periods
  campaingnLink: string; // Information about action
}
interface ForRepeatersGracePeriodOverdue {
  paymentDate: number; // Number of days before the expiration date of the main loan period
  prevGraceLastDate: number; // The last day of the previous paid grace period, after which the extension was not received and the loan went on delay
  campaignLink: string;
}
interface ForRepeatersOverdueTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
  techExpirationEndDay: number; // The last day of the technical delay period for the loan
}
interface ForRepeatersOverdueNoTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
}
interface ForRepeatersProlong {
  autoProlongDaysLeft: number; // The number of days for which the prolongation amount is enough for the client’s balance
  amount: number; // Own funds
  autoProlongEndDate: number; // Date for prolongation to which there is enough amount on the balance sheet
}
interface OtherIssuePeriod {
  paymentDate: number; // Number of days before the expiration date of the main loan period
}
interface OtherProlong {
  autoProlongDaysLeft: number; // The number of days for which the prolongation amount is enough for the client’s balance
  amount: number; // Own funds
  autoProlongEndDate: number; // Date for prolongation to which there is enough amount on the balance sheet
}
interface OtherOverdueTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
  techExpirationEndDay: number; // The last day of the technical delay period for the loan
}
interface OtherOverdueNoTechPeriod {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
}
interface AnnuityNoOverdue {
  periodEndDay: number; // Number of days until the last date of the current period
}
interface AnnuityOverdue {
  unpaidOverdueDaysLeft: number; // The number of days for which the overdue fee has been charged but not paid
}
interface ProlongRestriction {}

export interface InfoModel {
  graceLastDate?: number; // Last day of grace period
  paymentDate?: number; // Last day of main loan period
  unpaidOverdueDaysLeft?: number; // The number of days for which the overdue fee has been charged but not paid
  techExpirationEndDay?: number; // The last day of the technical delay period for the loan
  autoProlongDaysLeft?: number; // The number of days for which the prolongation amount is enough for the client’s balance
  amount?: number; // Own funds
  autoProlongEndDate?: number; // Date for prolongation to which there is enough amount on the balance sheet
  amountToKeepGraceDiscount?: number; // Additional amount to save interest free period
  amountFor1DayProlongGrace?: number; // Commission amount for 1 days of prolongation
  enoughMoneyOrGraceDiscountUnavailable?: boolean; // Last grace period or not
  gracePlusGraceProlongEndDate?: number; // The end date of the current grace period plus the days of the grace period / periods
  prevGraceLastDate?: number; // The last day of the previous paid grace period, after which the extension was not received and the loan went on delay
  periodEndDay?: number; // Number of days until the last date of the current period
  campaignLink?: string; // Information about action
  graceActive?: boolean; // если true, то грейс сохранился и проценты не пересчитались
  gracePeriod?: number; // количество дней грейса
  annuityOverdueDays?: number; // количество дней просрочки для аннуитетного займа
  amountToPay?: number; // сумма к оплате
  dayBeforeDeadline: number; // сколько дней осталось до погашения, шаблоны: GRACE_PERIOD, EXTENDABLE_GRACE_LOAN_PROLONG, GRACE_LOAN_PROLONG, OTHER_LOAN_PROLONG
  hourBeforeDeadline: number; // сколько часов осталось до погашения, шаблоны: GRACE_PERIOD, EXTENDABLE_GRACE_LOAN_PROLONG, GRACE_LOAN_PROLONG, OTHER_LOAN_PROLONG
}

// Information tabs dto
export interface InformationTabs {
  code: LoanNotificationCode; // Loan notification code
  model: InfoModel; // Additional info model
}

export const prolongationLink = {
  SMSFINANCE: 'https://www.smsfinance.ru/news/2017/03082017.html',
  VIVUS:
    'https://www.vivus.ru/wicket/bookmarkable/com.improveit.sitevivus.ui.news.NewsViewPage?id=82',
};
export const graceLink = {
  SMSFINANCE: 'https://www.smsfinance.ru/news/2018/20181002.html',
  VIVUS:
    'https://www.vivus.ru/wicket/bookmarkable/com.improveit.sitevivus.ui.news.NewsViewPage?id=81',
};
