<div class="field-wrap" [ngClass]="brand === 'VIVUS' ? 'vivus-style' : ''">
  <ng-container *ngFor="let date of dateList">
    <h5 class="operations-date">{{date | ruLocatePipe}}</h5>
    <div id="operation-body">
      <ng-container *ngFor="let operation of operationsByDate[date]">
        <div class="operation-container">
          <div>{{operation.description}}</div>
          <div class="amount">{{operation.formattedAmount}} &#8381;</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="bar"></div>
</div>
