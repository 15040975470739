import Routes from './routes.const';
import routesConst from './routes.const';

const availableToAll = [
  Routes.AGREEMENTS_PAGE,
  Routes.RULES_PAGE,
  // Routes.CABINET_PAGE,
  Routes.WAITING_PAGE,
  Routes.CABINET_DOCUMENT_PAGE,
  Routes.USER_PROFILE_PAGE,
  Routes.COMMISSION_REFUND,
];

/**
 * Scoring status
 * @type {{REJECTED: string; EXPIRED: string; ACCEPTED: string;}}
 */
export const ScoringStatusList = {
  EXPIRED: '1',
  ACCEPTED: '2',
};

/**
 * Credit status
 * @type {{NOLOAN: string; CONFIRMATION: string; WAITING: string; ISSUED: string;}}
 */
export const CreditStatusList = {
  REJECTED: '0',
  EMPTY: '1',
  WAITING_CONFIRMATION: '2',
  WAITING_LOAN_ISSUED: '3',
  ACCEPTED: '4',
  ISSUED: '5',
};

/**
 * Loan status
 * @type {{NOLOAN: string; CONFIRMATION: string; WAITING: string; ISSUED: string;}}
 */
export const LoanStatusList = {
  ABSENCE: 0,
  OVERDUE: 1,
  ACTIVE: 2,
};

/**
 * @argument {X--: authorization}
 * @argument {-X-: scoring status}
 * @argument {--X: loan status}
 */
export const PermissionCodeList = {
  0: 'NON_AUTHORIZED',
  10: 'SCORING_FAILED',
  11: 'SCORING_EXPIRED',
  1200: 'NO_LOAN_NO_REQUEST',
  1201: 'NO_LOAN_NO_REQUEST',
  1202: 'NO_LOAN_REQUEST_CONFIRMATION',
  1203: 'NO_LOAN_REQUEST_WAITING',
  1204: 'LOAN_ISSUED',
  121: 'ACTIVE_LOAN',
  122: 'ACTIVE_LOAN',
};

/**
 * @param {XXXX: PermissionCodeList}
 * @return {availablePage: string[]}
 */
export const PermissionList = {
  NON_AUTHORIZED: [
    Routes.LOGIN_PAGE,
    Routes.FIRST_REG_STEP,
    Routes.SECOND_REG_STEP,
    Routes.THIRD_REG_STEP,
    Routes.AGREEMENTS_PAGE,
    Routes.RULES_PAGE,
    Routes.USER_PROFILE_PAGE,
    Routes.PASSWORD_RECOVERY_PAGE,
    Routes.CODE_RECOVERY_PAGE,
    Routes.NEW_PASSWORD_RECOVERY_PAGE,
  ],
  SCORING_FAILED: [
    Routes.SCORING_RESULT_FAILED,
    Routes.SCORING_RESULT_FAILED_DESCRIPTION,
    ...availableToAll,
  ],
  SCORING_EXPIRED: [Routes.RESCORING, ...availableToAll],
  NO_LOAN_NO_REQUEST: [
    Routes.SCORING_RESULT_SUCCESSFUL,
    Routes.REGISTRATION_CARD_SUCCESSFUL,
    ...availableToAll,
  ],
  NO_LOAN_REQUEST_CONFIRMATION: [
    Routes.CONFIRMATION_EMAIL,
    Routes.CONFIRMATION_SMS,
    Routes.CONTRACT_PAGE,
    ...availableToAll,
  ],
  NO_LOAN_REQUEST_WAITING: [
    Routes.CONTRACT_PAGE,
    Routes.LOAN_ISSUED,
    ...availableToAll,
  ],
  LOAN_ISSUED: [
    Routes.LOAN_ISSUED,
    Routes.ACTIVE_LOAN,
    Routes.THANK_YOU_PAGE,
    Routes.CONTRACT_PAGE,
    ...availableToAll,
  ],
  ACTIVE_LOAN: [Routes.ACTIVE_LOAN, ...availableToAll],
};

export const PermissionRouteList = {
  NON_AUTHORIZED: routesConst.LOGIN_PAGE,
  SCORING_EXPIRED: routesConst.RESCORING,
  SCORING_FAILED: routesConst.SCORING_RESULT_FAILED,
  NO_LOAN_NO_REQUEST: routesConst.SCORING_RESULT_SUCCESSFUL,
  NO_LOAN_REQUEST_CONFIRMATION: routesConst.CONFIRMATION_SMS,
  NO_LOAN_REQUEST_WAITING: routesConst.WAITING_PAGE,
  LOAN_ISSUED: routesConst.LOAN_ISSUED,
  ACTIVE_LOAN: routesConst.ACTIVE_LOAN,
};
