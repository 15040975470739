import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCity',
  pure: true,
})
export class SearchCityPipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any {
    if (!items) {
      return [];
    }
    if (searchTerm === undefined || null) {
      return items;
    }
    const terms = searchTerm
      .toLowerCase()
      .split(' ')
      .map((i) => i.trim())
      .filter((i) => i);

    return items.filter((i) => terms.every((term) => this.testItem(i, term)));
  }

  testItem(value: any, term: string) {
    return this.testString(value, term);
  }

  testString(value: string, term: string) {
    if (!!value) {
      return value.toString().toLowerCase().includes(term);
    }
    return false;
  }
}
