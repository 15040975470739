import { Component, HostListener, OnInit } from '@angular/core';
import { ActionsService } from '../../../../api/actions.service';
import * as moment from 'moment-mini-ts';
import { ServerDateFormat } from '../../../../constants/global.constant';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-operation-history',
  templateUrl: `operation-history.components.html`,
  styleUrls: [`operation-history.components.sass`],
})
export class OperationHistoryComponent implements OnInit {
  public brand: string;
  public operations: any[];
  public operationsByDate: any = {};
  public dateList: string[] = [];

  private _page = 0;
  private _pageSize = 6;
  private _scrollLoading = false;

  constructor(private _actionService: ActionsService) {}

  ngOnInit(): void {
    this._actionService
      .getOperationHistory(this._page, this._pageSize)
      .then((res) => {
        const data = res.data;
        this.setOperations(data.history);
      });
    this.brand = environment.brand;
  }

  /**
   * infinity scroll
   */
  @HostListener('window:scroll', ['$event']) scrollEvent(event): void {
    const doc = window.document.documentElement;
    if (document.getElementById('operation-body')) {
      const scroll = doc.scrollHeight - (doc.scrollTop + doc.clientHeight);
      if (scroll < 250 && !this._scrollLoading) {
        this._scrollLoading = true;
        this._page += 1;
        this._actionService
          .getOperationHistory(this._page, this._pageSize)
          .then((res) => {
            this._scrollLoading = false;
            const data = res.data;
            if (data && data.history) {
              this.setOperations(data.history);
            }
          })
          .catch((error) => {
            this._scrollLoading = false;
          });
      }
    }
  }

  /**
   * Parse and process operations list
   * @param {data: any[]}
   */
  private setOperations(data: any[]): void {
    if (data) {
      data.forEach((item) => {
        const date = moment(item.date).format(ServerDateFormat);
        if (this.dateList.includes(date)) {
          this.operationsByDate[date].push(item);
        } else {
          this.dateList.push(date);
          this.operationsByDate[date] = [];
          this.operationsByDate[date].push(item);
        }
      });
    }
  }
}
