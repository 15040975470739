import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  Client,
  CreditRequestState,
  LoanState,
  OtherBrandClientDto,
} from '../classes/client.class';
import { Loans } from '../classes/loans.class';

@Injectable()
export class ClientInfoService {
  private initialValueClient = {
    activeLoanState: LoanState.ABSENCE,
    creditRequestStateDto: { state: CreditRequestState.EMPTY },
    otherBrandClientDto: {
      registered: null,
      positivePdlLimit: null,
      active: null,
      activeRequest: null,
      twoMonthsPassedAfterNegativeScoring: null,
    },
    state: '',
    dob: null,
    email: '',
    firstName: '',
    gender: '',
    id: null,
    lastName: '',
    limit: null,
    middleName: '',
    mobile: null,
    paymentType: '',
    requestedAmount: null,
    sex: '',
    agentTransferCode: null,
    transferCodeDate: null,
    transferConsentDate: null,
    additionalSurvey: {
      hasPet: null,
    },
  };
  private initialValueLoans = {
    amount: null,
    amountRemain: null,
    amountToReturn: null,
    creationDate: null,
    returnDate: null,
    gracePeriod: null,
    id: null,
    loanPeriod: null,
    stake: null,
    penalty: null,
    fee: null,
    dayFee: null,
    dayPenalty: null,
    mainDeptRemain: null,
    ownMoneyRemain: null,
    transferCommission: null,
    psySubscriptionActive: false,
    activeCreditRequestId: 0,
    insuranceInfo: {
      availability: false,
      detailsUrl: '',
      insuranceIncluded: false,
      showInsurance: false,
      instantPayment: true,
      type: null,
    },
    loanConfirmationInfo: {
      msLeft: null,
    },
    loanPaymentTypeInfo: {
      loanPaymentType: '',
      additionalFields: {
        code: '',
        transactionId: '',
      },
    },
    productType: 'PDL',
    recurringState: null,
    campaignInformation: null,
    graceProlongAmount: null,
    restrictionDate: null,
    astrologerConsultingIncluded: null,
    psychologistConsultingIncluded: null,
    isLastPayment: true,
    commissionRemain: null,
    overdueCommissionRemain: null,
    additionalProductsAvailable: null,
    lawyerConsultingInfo: {
      instantPayment: false,
      purchaseAmount: 0,
    },
    vetPetConsultingInfo: {
      instantPayment: false,
      purchaseAmount: 0,
    },
    dietPlanInfoDto: {
      type: '',
      instantPayment: false,
      purchaseAmount: 0,
    },
    dietConsultingInfoDto: {
      type: '',
      instantPayment: false,
      purchaseAmount: 0,
    },
    psychologistConsultingInfo: {
      instantPayment: false,
      purchaseAmount: 0,
    },
    astrologerConsultingInfo: {
      instantPayment: false,
      purchaseAmount: 0,
    },
    elderInfoDto: {
      instantPayment: false,
      purchaseAmount: 0,
    },
    dateConclusionLastAdditionalAgreementOnProlong: null,
    currentLoanOverdue: null,
    countProlong: null,
    dateEndAgreementOnProlong: null,
    subscriptionAvailable: null,
    dietInfoSubscriptionActive: false,
  };

  private client: BehaviorSubject<Client> = new BehaviorSubject(
    this.initialValueClient
  );

  private loans: BehaviorSubject<Loans> = new BehaviorSubject(
    this.initialValueLoans
  );

  /**
   * Get client info
   * @return {client: Client}
   */
  public getClient(): any {
    return this.client;
  }
  /**
   * Get loans info
   * @return {loans: Loans}
   */
  public getLoans(): any {
    return this.loans;
  }
  /**
   * Set client info
   */
  public setClient(data: Client): void {
    this.client.next(data);
  }
  /**
   * Set loans info
   */
  public setLoans(data: Loans): void {
    this.loans.next(data);
  }
  /**
   * Clear client info, set initialInfo
   */
  public clearClient(): void {
    this.client.next(this.initialValueClient);
  }
  /**
   * Clear loans info, set initialInfo
   */
  public clearLoans(): void {
    this.loans.next(this.initialValueLoans);
  }
}
