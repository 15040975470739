import { timer as rxTimer } from 'rxjs';

/**
 * @param {number} delay - first value delay
 * @param {number} interval - interval between values
 * @returns {Observable<any>}
 */
const timer = (delay: number, interval: number) => {
  return rxTimer(delay, interval);
};

export default timer;
