import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import Routes from '../../../constants/routes.const';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CheckRegistrationFormGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.brand !== 'VIVUS') {
      this.router.navigate([Routes.FIRST_REG_STEP], {
        queryParamsHandling: 'merge',
      });
    }
    return true;
  }
}
