/**
 * The method is increase date without hour and minutes
 * @param {date: Date}
 * @returns {date: Date}
 */
import * as moment from "moment-mini-ts";

export const increaseDate = (date: Date, increase: number): any => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + increase
  );
};

/**
 * The method is convert string to date
 * @param {date: string}
 * @returns {date: Date}
 */
export const convertStringtoDate = (date: string): Date => {
  const dateValue = date.split('.').map((item) => Number(item));
  return new Date(dateValue[2], dateValue[1] - 1, dateValue[0]);
};

/**
 * Return number of days left not considering the time
 * @param {return: number}
 * @returns {daysLeft: number}
 */
export const getDaysLeft = (date): number => {
  const now = new Date();
  const nowWithoutTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  ).valueOf();
  const returnDate = new Date(date);
  const returnWithoutTime = new Date(
    returnDate.getFullYear(),
    returnDate.getMonth(),
    returnDate.getDate()
  ).valueOf();
  return Math.floor((returnWithoutTime - nowWithoutTime) / 86400000);
};

export const diffDateNow = (date: number, typeResult): number => {
  return moment(date).utcOffset('+03:00')
      .diff(moment()
        .utcOffset('+03:00'), typeResult)
}
