import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AnalyticsEventsService } from '../../../../services/analytic.service';
@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
})
export class ConsultantComponent implements AfterViewInit {
  public consultantOpen = false;
  public consultantLoaded = false;

  constructor(public analyticService: AnalyticsEventsService) {}

  ngAfterViewInit() {
    for (const currentScriptSrc of environment.scripts) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.charset = 'utf-8';
      script.src = currentScriptSrc;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        this.consultantLoaded = true;
      };
    }
  }

  public showConsultant() {
    if (!this.consultantOpen) {
      this.analyticService.consultantAnalytic();
      this.consultantOpen = true;
    }
  }
}
