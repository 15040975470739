import { Injectable, OnDestroy } from '@angular/core';
import { ActionsService } from '../api/actions.service';
import {
  CreditRequestStatuses,
  LoanType,
  paymentPurposeList,
  PaymentSystemType,
  ProductType,
  RepaymentSystemType,
  TimeoutPeriod,
} from '../constants/global.constant';
import Routes from '../constants/routes.const';
import { Router } from '@angular/router';
import { CreditCalculatorService } from './credit-calculator.service';
import { BehaviorSubject, scan, Subject, Subscription, takeWhile } from 'rxjs';
import { AnalyticsEventsService } from './analytic.service';
import { ClientInfoService } from './client-info.service';
import { Client, CreditRequestState } from '../classes/client.class';
import { Loans } from '../classes/loans.class';
import { environment } from '../../environments/environment';
import { CalcData } from 'src/app/components/credit-calculator/credit-calculator.class';
import { take } from 'rxjs/operators';
import { PopupService } from './popup.service';
import { WindowRef } from 'src/app/classes/global-ref';
import { StorageService } from './storage.service';
import { CrmErrorsConst } from '../constants/crm-errors.const';
import timer from '../utils/timer';

@Injectable()
export class PaymentService implements OnDestroy {
  public paymentSystemConfig: BehaviorSubject<any> = new BehaviorSubject([]);
  public emptyRepaymentAmount: Subject<boolean> = new Subject();

  public currentPaymentType: string;

  /** Передает ошибку при оплате type - тип платежа, например киви, error - полная ошибка как пришла от бэка */
  public sendPaymentError$: Subject<{ type: string; error: string }> =
    new Subject();

  private getRequestLoanStatus: any;
  private setPaymentType: any;
  private brand = environment.brand;
  private _client: Client;
  private _loans: Loans;
  private _sub: Subscription = new Subscription();
  private _repaymentAmount: number;
  private _newSmsConfirmationFlag: boolean = true;
  private _formV2Flag: boolean;

  public dynamicComponentData$ = new Subject();

  public progressRedirectSmsTimeout;

  public timesLeft: number;
  private _timerSub: any;
  public textForRedirect: boolean = false;
  public paymentProgress: boolean = false;
  public paymentType;

  private _activePaymentType: BehaviorSubject<string> = new BehaviorSubject(
    PaymentSystemType.CARD
  );
  private _showMap: Subject<boolean> = new Subject();

  public loadingReceivingMoney = false;

  constructor(
    private actionsService: ActionsService,
    private router: Router,
    private cs: CreditCalculatorService,
    private as: AnalyticsEventsService,
    private _clientInfoService: ClientInfoService,
    private _popupService: PopupService,
    private _winRef: WindowRef,
    private _storageService: StorageService
  ) {
    this._sub.add(
      _clientInfoService.getClient().subscribe((response) => {
        this._client = response;
      })
    );
    this._sub.add(
      _clientInfoService.getLoans().subscribe((response) => {
        this._loans = response;
      })
    );
  }

  ngOnDestroy() {
    this.getRequestLoanStatus.unsubscribe();
    this.setPaymentType.unsubscribe();
    this._sub.unsubscribe();
  }

  setComponentData(data) {
    this.dynamicComponentData$.next(data);
  }

  /**
   * Method for obtaining information on the status of the credit request
   * @returns {Subscription}
   */
  requestLoanStatusResult(type) {
    const { ACCEPTED, REJECTED, WAITING_CONFIRMATION, WAITING_LOAN_ISSUED } =
      CreditRequestStatuses;

    const {
      SCORING_RESULT_FAILED,
      CONFIRMATION_SMS,
      LOAN_ISSUED,
      ACTIVE_LOAN,
    } = Routes;

    const { CARD, QIWI, YANDEX, CONTACT, BANK } = PaymentSystemType;

    const { LOAN_ISSUED_TIME } = TimeoutPeriod;

    const paymentType = type;
    return (this.getRequestLoanStatus = this.actionsService
      .getRequestLoanStatus()
      .subscribe(
        (res) => {
          const state = res.data.state;
          switch (state) {
            case ACCEPTED:
              this._clientInfoService.setClient({
                ...this._client,
                creditRequestStateDto: {
                  state: CreditRequestState.ISSUED,
                },
              });
              if (
                paymentType === CARD ||
                paymentType === QIWI ||
                paymentType === YANDEX
              ) {
                return this.router.navigate([ACTIVE_LOAN], {
                  queryParams: {
                    issued: true,
                  },
                  queryParamsHandling: 'merge',
                });
              } else {
                return this.router.navigate([LOAN_ISSUED], {
                  queryParamsHandling: 'merge',
                });
              }
            case REJECTED:
              this.loadingReceivingMoney = false;
              this.textForRedirect = false;
              this.paymentProgress = false;
              this._clientInfoService.setClient({
                ...this._client,
                creditRequestStateDto: {
                  state: CreditRequestState.REJECTED,
                },
              });
              return this.router.navigate([SCORING_RESULT_FAILED], {
                queryParamsHandling: 'merge',
              });
            case WAITING_CONFIRMATION:
              setTimeout(() => {
                this.requestLoanStatusResult(paymentType);
              }, LOAN_ISSUED_TIME);
              return;
            case WAITING_LOAN_ISSUED:
              if (paymentType === CONTACT || paymentType === BANK) {
                this._clientInfoService.setClient({
                  ...this._client,
                  creditRequestStateDto: {
                    state: CreditRequestState.WAITING_LOAN_ISSUED,
                  },
                });
                return this.router.navigate([LOAN_ISSUED], {
                  queryParamsHandling: 'merge',
                });
              } else {
                setTimeout(() => {
                  this.requestLoanStatusResult(paymentType);
                }, LOAN_ISSUED_TIME);
                return;
              }
            default:
              return;
          }
        },
        (err) => {
          console.log('getRequestLoanStatus:', err);
        }
      ));
  }

  loanRequest(inputSum, inputDate, product): void {
    const storageIncome = this._storageService.get('income');
    const income = storageIncome ? Number(storageIncome) : null;
    console.log(income);
    this.actionsService
      .postRequestForLoan({
        amount: inputSum.toString(),
        duration: inputDate.toString(),
        income: income || 280000,
        productType:
          product === ProductType.PDL ? LoanType.NON_RECURRING2 : LoanType.LONG,
      })
      .subscribe((v) => {
        if (this.loadingReceivingMoney === true) {
          this.progressRedirectSmsTimeout = setTimeout(() => {
            this.router.navigate([Routes.CONFIRMATION_SMS]).then(() => {
              this._storageService.remove('confirmationCodeForm2Sended');
              this._storageService.remove('reset');
            });
          }, 15000);
        }
      });
  }

  /**
   * Sending a loan request
   * @param {string} paymentType - Possible type: CONTACT, CARD, BANK, QIWI, YANDEX
   * Default payment system on the server - CONTACT
   */
  sendPaymentRequest(paymentType: string, product: string): void {
    this.loadingReceivingMoney = true;
    this.paymentType = paymentType;
    this.initTimer();
    this.cs['getCalcData' + product]()
      .pipe(take(1))
      .subscribe((res: CalcData) => {
        const { inputDate, inputSum } = res;
        // Устанавливаем платежную систему
        this.actionsService
          .setPaymentType(paymentType)
          .pipe(take(1))
          .subscribe({
            next: () => {
              // Проверяем доступность сервиса полной идентификации
              this.actionsService
                .checkEnabledFullIdentification(this.brand)
                .then((config) => {
                  const { enable, optional } = config.data;
                  // Если сервис доступен и отключена опциональная возможность активации, а также клиент не является повторником
                  if (enable && !this._client.hasPaidLoans) {
                    this._clientInfoService.setClient({
                      ...this._client,
                      creditRequestStateDto: {
                        state: CreditRequestState.WAITING_CONFIRMATION,
                      },
                    });
                    this._popupService.openTooltipEvent(
                      'showFullIdentification',
                      { show: true, strategy: product }
                    );
                  } else {
                    //перезаписываем способ получения
                    this._clientInfoService.setClient({
                      ...this._client,
                      paymentType: paymentType,
                    });
                    // Осуществляем отправку кредитного запроса
                    this.loanRequest(inputSum, inputDate, product);
                  }
                });
            },
            error: (e) => {
              const { description } = e.error.error.payload;
              console.log(e);
              console.log(e.error.error.payload);
              if (description.includes(CrmErrorsConst.ACTIVE_CREDIT_REQUEST)) {
                this.router.navigate([Routes.CONFIRMATION_SMS]).then();
              }
            },
          });
      });
    // Sending analytics data
    this.as.clickTakeMoney(paymentType);
  }

  /**
   * Отправляет запрос на оплату
   * @param type - PaymentSystemType (например QIWI)
   * @param options
   */
  sendRepaymentRequest(type: string, options?: any): void {
    if (this._repaymentAmount) {
      switch (type) {
        case RepaymentSystemType.CARD:
          this.actionsService
            .getRepaymentUriForCard(
              this._repaymentAmount,
              paymentPurposeList.PAY
            )
            .pipe(take(1))
            .subscribe({
              next: ({ data }) => {
                const { url, orderId } = data;
                // Записывает айди платежа, чтобы дальше по нему отслеживать, когда он пройдет
                this._storageService.set('PAYMENT_ID', orderId);
                this._winRef.nativeWindow.location.replace(url);
              },
              error: (error) => {
                this.sendPaymentError$.next({ type: type, error: error });
              },
            });
          break;
        case RepaymentSystemType.YANDEX:
          this.actionsService
            .getRepaymentUri(this._repaymentAmount, 'PC', options)
            .then((res) => {
              const { actionUrl } = res.data;
              this._winRef.nativeWindow.location.replace(actionUrl);
            })
            .catch((reason) => {
              try {
                const error = reason.error.error.payload.description;
                this.sendPaymentError$.next({ type: type, error: error });
              } catch {
                this.sendPaymentError$.next({
                  type: type,
                  error:
                    'Такого кошелька не существует. Пожалуйста, уточните номер',
                });
              }
            });
          break;
        case RepaymentSystemType.QIWI:
          this.actionsService
            .sendQiwiBill(this._repaymentAmount)
            .then((res) => {
              if (res.data.result) {
                this._popupService.openTooltipEvent('showQiwiSendBill', true);
              }
            })
            .catch((reason) => {
              this.sendPaymentError$.next({ type: type, error: reason });
            });
          break;
        default:
          break;
      }
    } else {
      this.emptyRepaymentAmount.next(true);
    }
  }

  public setRepaymentAmount(val: number): void {
    this._repaymentAmount = val;
  }

  /**
   * Return max amount to return
   * @param {amount: Number}
   */
  public getAmountToReturn(amount: number): number {
    const amountRemain = this._loans.amountRemain;
    return amount > amountRemain ? amountRemain : amount;
  }

  public setActivePayment(type: string): any {
    this._activePaymentType.next(type);
  }

  public getActivePayment(type?: string): any {
    return this._activePaymentType;
  }

  public emitShowMap(): any {
    this._showMap.next(true);
  }

  public getShowMap(): any {
    return this._showMap;
  }

  initTimer() {
    this.timesLeft = 25;
    this.paymentProgress = true;
    this._timerSub = timer(0, 1000)
      .pipe(
        scan((acc) => --acc, this.timesLeft),
        takeWhile((x) => x >= 0)
      )
      .subscribe((res) => {
        this.timesLeft = res;
        if (res <= 0) {
          this.timesLeft = 0;
          this.textForRedirect = true;
          this._timerSub.unsubscribe();
        }
      });
  }
  pad(num: number) {
    return ('0' + num).slice(-2);
  }

  convertSecondsToMinutes(secs: number) {
    const minutes = Math.floor(secs / 60);
    secs = secs % 60;
    return this.pad(minutes) + ':' + this.pad(secs);
  }
}
