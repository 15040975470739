import { ErrorsMessage } from '../models/errors-message.interface';

export const FORM_ERRORS_MESSAGE: ErrorsMessage = {
  REFUSED:
    'Отказано в регистрации, пожалуйста, свяжитесь с оператором для разблокировки аккаунта',
  PHONE_DUPLICATION:
    'Номер телефона уже использовался для регистрации в нашем сервисе. Попробуйте войти в Личный кабинет по ранее ' +
    'заведенному в системе номеру (указывать без 8) или зарегистрировать аккаунт с использованием другого номера.',
  DEACTIVATED:
    'Аккаунт с указанными данными уже был зарегистрирован и после этого деактивирован. Для того чтобы активировать аккаунт,' +
    ' позвоните нам по телефону горячей линии.',
  PASSPORT_DUPLICATION:
    'К сожалению, дальнейшая регистрация невозможна по причине неуникальности данных анкеты.' +
    ' Пожалуйста, позвоните нам по телефону горячей линии ',
  VIVUS_HOT_LINE:
    '8-800-505-6493',
  SMSFINANCE_HOT_LINE:
    '8-800-333-2757',
  EMAIL_DUPLICATION:
    'Этот адрес электронной почты указан другим пользователем. Пожалуйста, свяжитесь с администратором, ' +
    'либо введите другой адрес электронной почты',
  ERROR: 'Неизвестная ошибка, пожалуйста, свяжитесь с администратором',
  FIRST_STEP: 'Ошибка при отправке формы ',
  SECOND_STEP: 'Ошибка при отправке формы ',
  THIRD_STEP: 'Ошибка при отправке формы ',
  FOURTH_STEP: 'Ошибка при отправке формы ',
  BANK_RESULT_WRONG:
    'При проверке реквизитов была обнаружена ошибка или счёт с таким же номером уже зарегистрирован для другого пользователя. Пожалуйста, внимательно проверьте указанные данные или воспользуйтесь другим способом для получения денег.',
};
