export class PhoneUtil {
  static changePhoneFormat(str: string) {
    if (str) {
      const reg = str.match(/[+()\-_ ]+/g);
      if (reg) {
        return str.slice(2).replace(/[+()\-_ ]+/g, '');
      } else {
        return str.replace(/[+()\-_ ]+/g, '');
      }
    }
  }
}
