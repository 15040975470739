import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { ClientActionService } from './services/client-action.service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
  RoutesRecognized,
} from '@angular/router';
import { AnalyticsEventsService } from './services/analytic.service';
import { DOCUMENT, Location } from '@angular/common';
import { WindowRef } from './classes/global-ref';
import { ActionsService } from './api/actions.service';
import { StorageService } from './services/storage.service';
import { UserAgentList } from './constants/global.constant';
import { disable } from './components/shared/components/consultant/disable';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment-mini-ts';
import { CalendarConstants } from './constants/calendar.constants';
import { Subscription } from 'rxjs';

declare var ga: any;
declare var timer: any;

const CONSULTANT_HIDE_CLASSNAME = 'consultant-invisible';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.sass',
    '../assets/fonts/data-ttf.css',
    '../assets/fonts/data-woff.css',
    '../assets/fonts/data-woff2.css',
  ],
  providers: [ClientActionService],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  public brand;
  openCabinetUrlInNewContext: boolean = environment.openCabinetUrlInNewContext;
  private prevPath;
  private disableConsultant: boolean = false;
  public gtmId: string;
  public queryParams: object;
  public pageLoading = true;

  public _sub: Subscription = new Subscription();

  constructor(
    private clientActionService: ClientActionService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticService: AnalyticsEventsService,
    private location: Location,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private winRef: WindowRef,
    private as: ActionsService,
    public storageService: StorageService,
    private primengConfig: PrimeNGConfig
  ) {
    this.gtmId = environment.gtmId;
    this.brand = environment.brand;
    this.clientActionService.addUserAgentClass(UserAgentList);

    console.log(this.brand);

    this.primengConfig.ripple = true;
    moment.locale('ru');
    this.primengConfig.setTranslation(CalendarConstants);
    // try {
    //   clearTimeout(timer);
    // } catch (err) {
    //   console.log('loader:', err)
    // }

    this._sub.add(
      this.route.queryParams.subscribe((params) => {
        this.queryParams = params;
        this.createRegisterCoockie();
      })
    );
  }

  ngOnInit() {
    this.analyticService.google.create();
    this.analyticService.mail.create();
    let prevPath = this.location.path();

    const regId = this.storageService.get('REGISTRATION');

    this._sub.add(
      this.router.events.subscribe((e: RouterEvent) => {
        this.navigationInterceptor(e);
      })
    );

    this._sub.add(
      this.router.events.subscribe((event) => {
        if (event instanceof RoutesRecognized) {
          let route = event.state.root.firstChild;
          this.disableConsultant = disable(route, this.brand);
          this.showConsultant();
        }

        if (event instanceof NavigationEnd) {
          const newPath = this.location.path();
          try {
            this.winRef.nativeWindow['ym'](
              environment.yandexMetrikaId,
              'hit',
              this.winRef.nativeWindow.location.origin + newPath,
              { referer: this.winRef.nativeWindow.location.origin + prevPath }
            );
            this.winRef.nativeWindow['ym'](
              environment.yandexMetrikaIdI,
              'hit',
              this.winRef.nativeWindow.location.origin + newPath,
              { referer: this.winRef.nativeWindow.location.origin + prevPath }
            );
          } catch (e) {
            this.as.sendingAnalyticsLog(
              `yandexCounter, registrationId: ${regId}`,
              e
            );
          }
          prevPath = newPath;
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      })
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.pageLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.pageLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.pageLoading = false;
    }
    if (event instanceof NavigationError) {
      this.pageLoading = false;
    }
  }

  showConsultant() {
    let show = !this.disableConsultant;
    this.updateConsultantVisibilityClassName(show);
    return show;
  }

  createRegisterCoockie() {
    const neededParams = {
      pre_sn: '',
      pre_fn: '',
      pre_mn: '',
      pre_bd: '',
      pre_em: '',
      pre_ph: '',
      pre_se: '',
      pre_no: '',
      pre_dv: '',
      pre_kp: '',
      pre_kv: '',
      pre_mr: '',
    };

    const paramsArr = Object.keys(this.queryParams).map((param) => {
      return {
        item: param,
        value: this.queryParams[param],
      };
    });

    const existedCookie = this.storageService.get(
      'registerParams',
      'cookie_item'
    );

    if (Object.keys(this.queryParams).length) {
      var expiresDate = new Date();
      expiresDate.setDate(expiresDate.getDate() + 7);

      if (!Object.keys(existedCookie).length) {
        const keys = Object.keys(neededParams);

        paramsArr.forEach((param) => {
          if (param.value) {
            if (keys.includes(param.item)) {
              neededParams[param.item] = param.value;
            }
          }
        });

        this.storageService.set('registerParams', neededParams, 'cookie_item', {
          expires: expiresDate,
        });
      } else {
        const keys = Object.keys(neededParams);

        Object.keys(existedCookie).forEach((item) => {
          if (existedCookie[item]) {
            if (keys.includes(item)) {
              neededParams[item] = existedCookie[item];
            }
          }
        });

        paramsArr.forEach((param) => {
          if (param.value) {
            if (keys.includes(param.item)) {
              neededParams[param.item] = param.value;
            }
          }
        });

        this.storageService.set('registerParams', neededParams, 'cookie_item', {
          expires: expiresDate,
        });
      }
    }
  }

  updateConsultantVisibilityClassName(show) {
    let body = document.querySelector('body');
    if (show) {
      body.classList.remove(CONSULTANT_HIDE_CLASSNAME);
    } else {
      body.classList.add(CONSULTANT_HIDE_CLASSNAME);
    }
  }
}
