import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  CreditStatusList,
  LoanStatusList,
  PermissionCodeList,
  PermissionList,
  PermissionRouteList,
} from '../constants/permission-route.const';
import { PaymentSystemType, WaitingType } from '../constants/global.constant';
import routesConst from '../constants/routes.const';
import { Client } from '../classes/client.class';
import { ActionsService } from '../api/actions.service';
import { AnalyticsEventsService } from './analytic.service';

@Injectable()
export class PermissionService {
  constructor(
    private _router: Router,
    private _as: ActionsService,
    private _ans: AnalyticsEventsService
  ) {}

  /**
   * Check permission for route
   * @param route
   * @param client
   */
  public checkRoute = (
    route: string,
    client: boolean | Client
  ): boolean => {
    let permission = false;
    const currentStatus = this.getStatusCode(client);

    if (route.indexOf('?') > 0) {
      route = /(.*?)(?=\?)/.exec(route)[0];
    }
    route = route.replace(/[\(\)]/g, '');
    const split = route.split('//');
    if (split.length > 1) {
      route = split[0];
    }
    permission = PermissionList[currentStatus].includes(route.slice(1));
    if (!permission) {
      this.redirectTo(route, currentStatus, client as Client);
      return false;
    } else {
      this._ans.sendPageVisitedEventForFunnel({
        clientId: (client as Client).id.toString(),
      });
      return true;
    }
  };

  /**
   * Redirected if permission false
   * @param route
   * @param status
   * @param client
   */
  private redirectTo = (
    route: string,
    status: string,
    client: Client
  ): void => {
    const redirect = PermissionRouteList[status];
    if (status === PermissionCodeList['1203']) {
      if (
        client.paymentType === PaymentSystemType.BANK ||
        client.paymentType === PaymentSystemType.CONTACT
      ) {
        this._router.navigate([routesConst.LOAN_ISSUED], {
          queryParamsHandling: 'merge',
        });
      } else {
        this._router.navigate([redirect], {
          queryParams: {
            source: WaitingType.LOAN_PROCESSING,
          },
          queryParamsHandling: 'merge',
        });
      }
    } else {
      this._router.navigate([redirect], {
        queryParamsHandling: 'merge',
      });
    }
  };

  /**
   * Return premission status code
   * @param client
   */
  public getStatusCode(
    client: boolean | Client,
  ): string {
    const auth = client ? '1' : '0';
    if (typeof client === 'object') {
      const loan = LoanStatusList[client.activeLoanState];
      // const scoring = this.client.limit >= 0 && this.client.limit < 3000 ? 1 :
      //                 this.client.limit > -1 ? 2 : 0;
      // eslint-disable-next-line radix
      const clientLimit = parseInt(String(client.limit));
      console.log(clientLimit);
      const scoring =
        clientLimit === -1 && loan === 0
          ? 0
          : loan > 0 || clientLimit >= 1000
          ? 2
          : 1;

      const credit = CreditStatusList[client.creditRequestStateDto.state];
      if (scoring < 2) {
        return PermissionCodeList[`${auth}${scoring}`];
      } else {
        if (loan > 0) {
          return PermissionCodeList[`${auth}${scoring}${loan}`];
        } else {
          const code = `${auth}${scoring}${loan}${credit}`;
          return PermissionCodeList[code];
        }
      }
    } else {
      return PermissionCodeList['0'];
    }
  }
}
