<div class="popup-wrapper">
  <div class="popup-innerwrapper">
    <a class="close" (click)="hide()">X</a>
    <div class="popup-message">
      <div class="field-wrap" style="width: 100%">
        <p>{{ message }}</p>
      </div>
      <div class="field-wrap" style="width: 50%">
        <a class="popup-link" (click)="openHomePage()">{{ yes }}</a>
      </div>
      <div class="field-wrap" style="width: 50%">
        <a class="popup-link" (click)="hide()">{{ no }}</a>
      </div>
    </div>
  </div>
</div>
