import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SocialList } from 'src/app/constants/other-links.const';
import { Client } from '../../../../classes/client.class';
import { ClientInfoService } from '../../../../services/client-info.service';
import { ActionsService } from '../../../../api/actions.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {
  public brand = environment.brand;
  public socialList = SocialList;
  private _client: Client;
  private _commissionRefundLinkStatus: boolean;
  public currentYear = new Date().getFullYear();

  constructor(
    private _clientInfoService: ClientInfoService,
    private _actionService: ActionsService
  ) {
    this._actionService.getCommissionRefundLinkStatus().subscribe({
      next: (res) => {
        this._commissionRefundLinkStatus = res.data;
      },
      error: (_) => {},
    });
    this._clientInfoService.getClient().subscribe((res) => {
      this._client = res;
    });
  }

  /**
   * Метод для отображения или скрытия ссылки в футере
   */
  isLinkAppears(): boolean {
    return !!this._client.id && this._commissionRefundLinkStatus;
  }
}
