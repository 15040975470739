import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  defineRequestOprions(requestOptions) {
    let reqOptions;
    if (requestOptions) {
      reqOptions = requestOptions || { params: new HttpParams() };
    } else {
      reqOptions = { params: new HttpParams(), withCredentials: true };
    }
    reqOptions.headers = reqOptions.headers || new HttpHeaders();
    reqOptions.headers = reqOptions.headers.append('X-Request-Source', 'SITE');
    const rid = String(Math.random().toString(16).split('.')[1]);
    reqOptions.headers = reqOptions.headers
      .append('Fin-Request-Id', rid)
      .append('Fin-Component-Uid', 'new-lk-front');
    return reqOptions;
  }

  head(type: string, url: string, requestOptions?): Observable<any> {
    return this.http
      .head(
        `${environment[type]}/${url}`,
        this.defineRequestOprions(requestOptions)
      )
      .pipe(catchError((error) => this.errorCatcher(error)));
  }

  get(type: string, url: string, requestOptions?): Observable<any> {
    return this.http
      .get(
        `${environment[type]}/${url}`,
        this.defineRequestOprions(requestOptions)
      )
      .pipe(catchError((error) => this.errorCatcher(error)));
  }

  post(
    type: string,
    url: string,
    body: any = null,
    requestOptions?
  ): Observable<any> {
    return this.http
      .post(
        `${environment[type]}/${url}`,
        body,
        this.defineRequestOprions(requestOptions)
      )
      .pipe(catchError((error) => this.errorCatcher(error)));
  }

  put(
    type: string,
    url: string,
    body: any = null,
    requestOptions?
  ): Observable<any> {
    return this.http
      .put(
        `${environment[type]}/${url}`,
        body,
        this.defineRequestOprions(requestOptions)
      )
      .pipe(catchError((error) => this.errorCatcher(error)));
  }

  delete(type: string, url: string, requestOptions?): Observable<any> {
    return this.http
      .delete(
        `${environment[type]}/${url}`,
        this.defineRequestOprions(requestOptions)
      )
      .pipe(catchError((error) => this.errorCatcher(error)));
  }

  private errorCatcher(response: Response) {
    return throwError(response);
  }
}
