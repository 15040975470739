<div class="footer__info">
  <p-divider></p-divider>
  <div class="bottom-block">
    <!--    <span *ngIf="brand === 'VIVUS'">© {{ currentYear }} Vivus.ru – срочные денежные займы онлайн через интернет. Тел.: 8 (800) 505-64-93.</span>-->
    <span
      >© {{ currentYear }}, ООО МКК «СМСФИНАНС» Регистрационный номер записи в
      государственном реестре МФО 3120177002032</span
    >

    <div class="bottom-block__link" *ngIf="isLinkAppears()">
      <a routerLink="/cabinet/commission_refund" target="_blank"
        >Информация о способах возврата комиссии по займам, полученным до
        01.03.2020</a
      >
    </div>
  </div>
</div>
