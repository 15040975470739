import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import detectMobile from 'src/app/utils/detect-mobile';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass'],
})
export class TabsComponent implements AfterContentInit {
  @Input() content = '';
  @Input() alwaysShowTitle: boolean = false;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  public contentClass: string;
  public isMobile = detectMobile();

  public prolongationOverlay = false;
  public brandMobile: string;
  public brandEmail: string;
  public brand = environment.brand;

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
    this.contentClass = this.content;

    if(this.brand === 'VIVUS') {
      this.brandMobile = '8-800-505-64-93';
      this.brandEmail = 'info@vivus.ru'
    } else {
      this.brandMobile = '8-800-333-27-57';
      this.brandEmail = 'info@smsfinance.ru'
    }
  }

  selectTab(tab: TabComponent) {
    if (tab.disabled) {
      return 1
    }
    // deactivate all tabs
    this.tabs.toArray().forEach((t) => {
      t.active = false;
    });
    // activate the tab the user has clicked on.
    tab.active = true;
    tab.selectTab();
  }

  public getTitle(title): any {
    const div = document.createElement('div');
    div.innerHTML = title;
    return div;
  }

  toggleOverlayForProlongation(toggler: boolean, tab) {
    if (tab.disabled) {
      this.prolongationOverlay = toggler
    }
  }
}
