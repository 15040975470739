import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscontTerms } from '../../../../constants/other-links.const';
import { environment } from '../../../../../environments/environment';
import {
  AppBrands,
  PaymentSystemType,
  ProductList,
  ServerDateFormat,
} from '../../../../constants/global.constant';
import * as moment from 'moment-mini-ts';
import declOfNum from '../../../../utils/decl-of-number';
import { LegalEntityList, Loans } from '../../../../classes/loans.class';
import { Client, LoanState } from '../../../../classes/client.class';
import { ClientInfoService } from '../../../../services/client-info.service';
import { Subscription } from 'rxjs';
import { LoanCardType } from '../../../../constants/loans.const';
import roundingForHundredths from '../../../../utils/rounding-for-hundredths';
import { getDaysLeft } from '../../../../utils/date-utils';
import detectMobile from '../../../../utils/detect-mobile';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loan-card',
  templateUrl: './loan-card.components.html',
  styleUrls: ['./loan-card.components.sass'],
})
export class LoanCardComponent implements OnInit {
  @Input() type: LoanCardType;
  @Output() opened: EventEmitter<string> = new EventEmitter();
  @Input() additionalAmount: number = 0;

  public discontTerms = DiscontTerms;
  public paymentTypeText: string;
  public brand: string;
  public YANDEX = PaymentSystemType.YANDEX;
  public CONTACT = PaymentSystemType.CONTACT;
  public BANK = PaymentSystemType.BANK;
  public declOfNumber = declOfNum;
  public isMobile = detectMobile();

  public client: Client;
  public loans: Loans;
  public loanCardType = LoanCardType;
  public fullDate: string;
  public creationDate: string;
  public daysLeft: number;
  public loanStates = LoanState;
  public showDetails = false;
  public productList = ProductList;
  public brandName = AppBrands;
  public url: string;
  public annuity: boolean;
  /** Текст информации об акции про безпроцентный период */
  public campaignInfoMessage = '';
  public contractLinkName = 'Договор';

  private _sub: Subscription = new Subscription();

  constructor(
    private _clientInfoService: ClientInfoService,
    private _route: ActivatedRoute
  ) {
    this.daysLeft = 0;
    this.url = environment.cabinetApiUrl;

    this._sub.add(
      this._clientInfoService.getClient().subscribe((res) => {
        this.client = res;
      })
    );

    this._sub.add(
      this._clientInfoService.getLoans().subscribe((res) => {
        this.updateLoans(res);
      })
    );
  }

  ngOnInit() {
    this.brand = environment.brand;

    const a = this._route.snapshot.queryParams.a;

    if (a && Number(a) > 0) {
      this.additionalAmount = Number(a);
    }
  }

  public get linkMoreByLegalEntity() {
    // return this.loans.legalEntity === LegalEntityList.FOUR_FINANCE
    //   ? 'https://www.vivus.ru/news/item/22/'
    //   : 'https://www.vivus.ru/news/item/21/';
    if(this.brand === 'VIVUS'){
      if(this.loans.legalEntity === LegalEntityList.FOUR_FINANCE) {
        return 'https://www.vivus.ru/news/item/22/'
      }else {
        return 'https://www.vivus.ru/news/item/21/'
      }
    } else {
      if(this.loans.legalEntity === LegalEntityList.FOUR_FINANCE) {
        return 'https://www.smsfinance.ru/news/item/22/'
      }else {
        return 'https://www.smsfinance.ru/news/item/21/'
      }
    }
  }

  public getMaxDate(): string {
    return this.loans.returnDate
      ? moment(this.loans.returnDate).format(ServerDateFormat)
      : null;
  }

  public getPaymentTypeText(): string {
    switch (this.client.paymentType) {
      case PaymentSystemType.CARD:
        return `На карту **** ${this.loans.lastDigits}`;
      case PaymentSystemType.YANDEX:
        return this.client.yandexWalletNumber.toString();
      case PaymentSystemType.QIWI:
        return `На QIWI-кошелёк ${this.client.mobile}`;
      case PaymentSystemType.BANK:
        return `На банковский счёт по реквизитам`;
      default:
        return `На ${this.client.paymentType}`;
    }
  }

  public getPaymentType(): string {
    switch (this.client.paymentType) {
      case PaymentSystemType.CARD:
        return `на карту`;
      case PaymentSystemType.YANDEX:
        return `на Яндекс.Деньги`;
      case PaymentSystemType.QIWI:
        return `на QIWI-кошелёк`;
      case PaymentSystemType.BANK:
        return `на банковский счёт`;
      case PaymentSystemType.CONTACT:
        return `по системе CONTACT`;
      default:
        return `на ${this.client.paymentType}`;
    }
  }

  /**
   * Return round number
   * @param {stake: number}
   */
  public roundStake(stake: number): number {
    return Math.floor(stake * 1000) / 10;
  }

  private updateLoans(res: any): void {
    this.loans = res;
    this.loans = {
      ...res,
      stake: roundingForHundredths(this.loans.stake),
    };

    this.annuity =
      this.loans.productType === ProductList.BIWEEKLY_ANNUITY ||
      this.loans.productType === ProductList.LONG;

    if (this.loans.creationDate && this.type === LoanCardType.ACTIVE) {
      this.fullDate = moment(this.loans.returnDate).format(ServerDateFormat);
      this.creationDate = moment(this.loans.creationDate).format(
        ServerDateFormat
      );
      this.daysLeft = getDaysLeft(this.loans.returnDate);
    }

    if (!this.annuity && this.loans.campaignInformation) {
      this.campaignInfoMessage = this.loans.campaignInformation.message;
    }
  }

  /**
   * Return module of number
   * @param {number: number}
   * @return {number: number}
   */
  public getModule(number: number = 0): number {
    return Math.abs(number);
  }

  public getDays(type): number {
    switch (type) {
      case 'fee':
        return Math.floor(this.loans.fee / this.loans.dayFee);
      case 'penalty':
        return Math.floor(this.loans.penalty / this.loans.dayPenalty);
      default:
        return 0;
    }
  }

  /**
   * The method return text for detail info
   * @param {type: string}
   * @return {text: string}
   */
  public getDetailText(type: string): string {
    switch (type) {
      case 'remain':
        switch (this.loans.productType) {
          case ProductList.NON_RECURRING2:
            return 'Остаток основного долга';
          case ProductList.BIWEEKLY_ANNUITY:
          case ProductList.LONG:
            return `Остаток долга за неоплаченные периоды`;
        }
        break;
      case 'fee':
        switch (this.loans.productType) {
          case ProductList.NON_RECURRING2:
            if (this.loans.fee === 0) {
              return `Проценты`;
            } else {
              return `Проценты за ${this.getDays('fee')} ${this.declOfNumber(
                this.getDays('fee'),
                ['день', 'дня', 'дней']
              )}`;
            }
          case ProductList.BIWEEKLY_ANNUITY:
          case ProductList.LONG:
            return `Комиссия за период`;
        }
        break;
    }
  }

  public openPopup(type): void {
    this.opened.emit(type);
  }

  public getFixedSum(sum): string {
    return Number(sum).toFixed(2);
  }

  public getLoansAmount() {
    return this.loans.amount + this.additionalAmount;
  }
}
