export enum CreditRequestState {
  REJECTED = 'REJECTED', // Credit request is cancelled
  EMPTY = 'EMPTY', // Credit request not created
  WAITING_CONFIRMATION = 'WAITING_CONFIRMATION', // Credit request created waiting for confirmation
  WAITING_LOAN_ISSUED = 'WAITING_LOAN_ISSUED', // Credit request confirmed waiting for issue
  ACCEPTED = 'ACCEPTED', // Loan issued
  ISSUED = 'ISSUED', // Loan issued (once after issuance)
}

export enum LoanState {
  ABSENCE = 'ABSENCE', // No Loan issued
  OVERDUE = 'OVERDUE', // Loan expired
  ACTIVE = 'ACTIVE', // Loan is active
}

export interface OtherBrandClientDto {
  registered: boolean; // зарегистрирован ли клиент в другом бренде
  positivePdlLimit: boolean; // положителньый или отрицательный лимит
  active: boolean; // активный ли заем в другом бренде
  activeRequest: boolean; // есть ли активная заявка
  twoMonthsPassedAfterNegativeScoring: boolean // прошло ли 2 мес. с момента последнего выставления негативного лимита
}

interface CreditRequestStateDto {
  state: CreditRequestState; // Current credit request state
}

export interface Client {
  activeLoanState: LoanState; // Current loan state
  creditRequestStateDto: CreditRequestStateDto; // Current credit request state
  otherBrandClientDto: OtherBrandClientDto; //данные о клиенте по противоположному бренду
  state: string; //
  dob: number; // Birthday
  email: string; // Client email
  firstName: string; // Client first name
  gender: string; // Client gender
  id: number; // Client id
  lastName: string; // Client last name
  limit: number; // Current approved loan limit
  middleName: string; // Client middle name
  mobile: number; // Client mobile phone
  paymentType: string; // Selected payment type for last loan
  requestedAmount: number; // Last requested amount
  sex: string; // Client sex
  yandexWalletNumber?: number; // If payment type = 'YANDEX' - yandex wallet number
  photo?: any; // User profile photo
  emailVerificated?: boolean; // Email verification information,
  limitDecisions?: any;
  hasPaidLoans?: boolean; //информация о НАЛИЧИИ оплаченных займов за всю историю клиента
  collectorId?: number;
  //crossScoring: boolean;
  agentTransferCode: number; //код уведомления для КА
  transferCodeDate: number; //дата отправки кода
  transferConsentDate: number; //дата подтверждения ознакомления с уведомлением для КА
  additionalSurvey: {
    hasPet: boolean;
  }
}
