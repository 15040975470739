<div
  class="main-container"
  [ngClass]="[brand === 'VIVUS' ? 'vivus-style' : '', isMobile ? 'mobile' : '']"
>
  <div class="inner-container" *ngIf="countTitle">
    <div class="count">
      <div class="count-title">{{ countTitle }}</div>
      <div class="count-value">
        <span>{{ countValue }}</span
        >&nbsp;<span>{{ countSign }}</span>
      </div>
      <div><span class="count__less-hours">{{ lessHours }}</span></div>
    </div>
    <div class="count" *ngIf="amountToPayValue">
      <div class="count-title">{{ amountToPayTitle }}</div>
      <div class="count-value">
        <span>{{ amountToPayValue }}</span
        >&nbsp;<span>{{ amountToPaySign }}</span>
      </div>
    </div>
    <div class="message" *ngIf="title || bodyText">
      <div class="message-title" *ngIf="title">
        <span [innerHTML]="title"></span>
      </div>
      <div class="message-body" *ngIf="bodyText">
        <span [innerHTML]="bodyText"></span>
      </div>
    </div>
  </div>
</div>
