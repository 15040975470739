import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPreventKeys]',
})
export class PreventKeysDirective {
  @HostListener('keydown', ['$event']) onKeydown($event) {
    this.onKeyUp($event);
  }

  @Input() appPreventKeys;

  onKeyUp($event) {
    if (this.appPreventKeys && this.appPreventKeys.includes($event.keyCode)) {
      $event.preventDefault();
    }
  }
}
