import { ElementRef, Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AnalyticsEventsService } from './analytic.service';

@Injectable()
export class ClientActionService {
  public startDate: any;
  private stopDate;

  constructor(
    private elRef: ElementRef,
    public storageService: StorageService,
    public analyticService: AnalyticsEventsService
  ) {}

  onPaste(e: any) {
    this.storageService.set('COPY_PASTE', true);
  }

  /**
   * Scrolls to the first invalid field
   * @param {string} el - first invalid field
   */
  scrollTo(el: string): void {
    setTimeout(() => {
      const element =
        this.elRef.nativeElement.querySelectorAll('input.error')[0];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  }

  /**
   * The method is executed when you go to the next registration page
   */
  scrollToTop(): void {
    const element = this.elRef.nativeElement.firstElementChild;
    if (element !== null) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  }

  scrollToTopImmediately(): void {
    const element = this.elRef.nativeElement.firstElementChild;
    if (element !== null) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 0);
    }
  }

  /**
   * Scroll to last element
   */
  scrollToFooter(): void {
    const element = this.elRef.nativeElement.lastElementChild;
    if (element !== null) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  }

  addUserAgentClass(keywords) {
    for (let i = 0; i < keywords.length; i++) {
      if (navigator.userAgent.indexOf(keywords[i]) !== -1) {
        document
          .getElementsByTagName('BODY')[0]
          .classList.add(keywords[i].toLowerCase());
        return;
      }
    }
  }

  changePhoneFormat(str: string) {
    if (str) {
      const reg = str.match(/[+()\-_ ]+/g);
      if (reg) {
        return str.slice(2).replace(/[+()\-_ ]+/g, '');
      } else {
        return str.replace(/[+()\-_ ]+/g, '');
      }
    }
  }

  cursorPosition(elem) {
    const nativeElement = this.elRef.nativeElement;
    const el = nativeElement.querySelector('#' + elem.target.id);
    if (this.changePhoneFormat(el.value) === '' || el.selectionStart === 0) {
      el.value = '+  ';
      el.setSelectionRange(3, 3);
    }
  }

  changeAmountFormat(str) {
    if (str !== '' && typeof str === 'string') {
      return +str.replace(/[^-0-9]/gim, '');
    } else {
      return str;
    }
  }
}
