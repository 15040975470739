import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskBankCardPipe',
})
export class maskBankCardPipe implements PipeTransform {
  transform(value: string) {
    return `**** **** **** ${value}`;
  }
}
