import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService implements HttpInterceptor {
  constructor(private _storageService: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.includes('login') &&
      !req.url.includes('dadata') &&
      this._storageService.check('ACCESS_TOKEN', 'cookie')
    ) {
      const type = this._storageService.get('TOKEN_TYPE', 'cookie');
      const access_token = this._storageService.get('ACCESS_TOKEN', 'cookie');
      const authReq = req.clone({
        headers: req.headers.set('Authorization', type + ' ' + access_token),
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
