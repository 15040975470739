<!-- <cabinet-menu></cabinet-menu> -->
<div
  class="return"
  [ngClass]="brand === 'VIVUS' ? 'vivus-style' : ''"
  *ngIf="showReturn"
>
  <div class="return-container">
    <a (click)="closeChildPage()">
      <svg
        height="24px"
        width="30px"
        version="1.1"
        viewBox="0 4 32 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M31.106,15H3.278l8.325-8.293  c0.391-0.391,0.391-1.024,0-1.414c-0.391-0.391-1.024-0.391-1.414,0l-9.9,9.899c-0.385,0.385-0.385,1.029,0,1.414l9.9,9.9  c0.391,0.391,1.024,0.391,1.414,0c0.391-0.391,0.391-1.024,0-1.414L3.278,17h27.828c0.552,0,1-0.448,1-1  C32.106,15.448,31.658,15,31.106,15z"
          id="Arrow_Back"
        />
      </svg>
      {{ returnMessage }}
    </a>
  </div>
</div>
<div
  [ngClass]="[
    'inner-container',
    brand === 'VIVUS' ? 'vivus-style' : '',
    isMobile ? 'mobile' : ''
  ]"
>
  <router-outlet></router-outlet>
</div>
<!-- <app-cabinet-document *ngIf="showDocument"></app-cabinet-document> -->
<!-- <app-user-profile *ngIf="showUserProfile"></app-user-profile> -->
