import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.sass'],
})

/**
 * ToggleComponent - Component opening content when clicked
 */
export class ToggleComponent {
  @Input() title: string;
  @Input() isActive: boolean;
  @Input() isShow: boolean;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle() {
    this.isActive = !this.isActive;
    this.toggled.emit(this.isActive);
  }
}
