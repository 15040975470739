import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import detectMobile from '../../../../utils/detect-mobile';

@Component({
  selector: 'app-border-wrapper',
  templateUrl: './border-wrapper.component.html',
  styleUrls: ['./border-wrapper.component.sass'],
})

/**
 * BorderWrapperComponent  - Stateless component. Required to display payment system.
 */
export class BorderWrapperComponent {
  public brand = environment.brand;
  public isMobile = detectMobile();
}
