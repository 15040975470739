import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalcData } from 'src/app/components/credit-calculator/credit-calculator.class';

@Injectable()
export class CreditCalculatorService {
  public prolongationCalcData: BehaviorSubject<any> = new BehaviorSubject({});
  public productCalcInfo: BehaviorSubject<any> = new BehaviorSubject({});
  public productILList: BehaviorSubject<any> = new BehaviorSubject({});
  /** Хранит информацию о том, загружены ли платежы для IL калькулятора */
  public calcILIsFullyInit$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private initialValue: CalcData = {
    inputSum: null,
    inputDate: null,
    product: '',
  };

  private calcDataPDL: BehaviorSubject<CalcData> = new BehaviorSubject(
    this.initialValue
  );
  private calcDataIL: BehaviorSubject<CalcData> = new BehaviorSubject(
    this.initialValue
  );

  /**
   * Get PDL calculator info
   * @return {client: Client}
   */
  public getCalcDataPDL(): any {
    return this.calcDataPDL;
  }

  /**
   * Set  PDL calculator info
   */
  public setCalcDataPDL(data: CalcData): void {
    this.calcDataPDL.next(data);
  }

  /**
   * Get IL calculator info
   * @return {client: Client}
   */
  public getCalcDataIL(): any {
    return this.calcDataIL;
  }

  /**
   * Set  IL calculator info
   */
  public setCalcDataIL(data: CalcData): void {
    this.calcDataIL.next(data);
  }

  public setProductILList(list): void {
    this.productILList.next(list);
  }

  public getProductILList(): any {
    return this.productILList;
  }
}
