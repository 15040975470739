import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.sass'],
})

/**
 * ToggleComponent - Component opening content when clicked
 */
export class CommonPopupComponent implements OnDestroy {
  @Input() maxWidth: number;

  private sub: Subscription = new Subscription();

  public isShow = false;

  constructor(private _popupService: PopupService) {
    this.sub.add(
      _popupService.getOpenTooltip().subscribe((res) => {
        this.openModal();
      })
    );
    this.sub.add(
      _popupService.getCloseTooltip().subscribe((res) => {
        this.closeModal();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public emitClose(): void {
    this._popupService.closeTooltipEvent();
  }

  private openModal(): void {
    this.isShow = true;
  }

  private closeModal(): void {
    this.isShow = false;
  }
}
