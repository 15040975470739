import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  DoCheck
} from '@angular/core';
import routesConst from '../../../../constants/routes.const';
import { ClientInfoService } from '../../../../services/client-info.service';
import { Client } from '../../../../classes/client.class';
import { CabinetService } from 'src/app/services/cabinet.service';
import detectMobile from 'src/app/utils/detect-mobile';
import { Router } from '@angular/router';
import { ActionsService } from 'src/app/api/actions.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from '../../../../../environments/environment';
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('mobileMenu') mobileMenu: ElementRef;
  @Input() openCabinetUrlInNewContext = false;

  public client: Client;
  public isLoaded = false;
  public brand: string;

  public Routes = routesConst;
  public isMobile = false;
  public showMobile = false;

  private sub: any;

  public loginPage: boolean

  public isOpen: boolean

  constructor(
    private _clientInfoService: ClientInfoService,
    private _cabinetService: CabinetService,
    private _actionService: ActionsService,
    private _storageService: StorageService,
    private _router: Router,
    private _renderer: Renderer2,
    private modalService: ModalService
  ) {
    this.brand = environment.brand;
    this.sub = this._clientInfoService.getClient().subscribe((res) => {
      this.client = res;
    });
    this.isMobile = detectMobile();

    this.modalService.isOpen$.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit() {
    this.isLoaded = true;
  }

  ngDoCheck() {
    this.loginPage = window.location.href.indexOf('login') > -1
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  openModal() {
    this.modalService.openModal();
  }

  public select(route, type): void {
    switch (type) {
      case 'route':
        this._router.navigate([route], {
          queryParamsHandling: 'merge',
        });
        break;
      case 'menu':
        this._cabinetService.selectMenu(route);
        break;
    }
    this._renderer.removeClass(this.mobileMenu.nativeElement, 'open');
    this._renderer.addClass(this.mobileMenu.nativeElement, 'close');
    this.showMobile = false;
  }

  public login(): void {
    this._router.navigate([routesConst.LOGIN_PAGE], {
      queryParamsHandling: 'merge',
    });
  }

  public payment(): void {
    window.open('/pay/')
  }

  public logout(): void {
    this._clientInfoService.clearLoans();
    this._actionService.logout().then((res) => {
      this._storageService.clearAll();
      this._clientInfoService.clearClient();
      this._router.navigate([routesConst.LOGIN_PAGE], {
        queryParamsHandling: 'merge',
      });
    });
  }
}
