<section class="nav nav-tabs" [ngClass]="[contentClass, isMobile ? 'mobile' : '']" *ngIf="tabs.length > 1">
  <div *ngFor="let tab of tabs; let i = index" style="display: block" class="container-tabs"
       (mouseenter)="toggleOverlayForProlongation(true, tab)"
       (mouseleave)="toggleOverlayForProlongation(false, tab)"
  >
    <button class="nav-item"
            (click)="selectTab(tab)"
            [class.active]="tab.active"
            [class.disabled]="tab.disabled"
    >
      <div class="nav-item_title" [innerHtml]="tab.title"></div>
      <div class="active-line"></div>
    </button>
  </div>
  <div class="prolongation-overlay" *ngIf="prolongationOverlay">
    <p>
      Продление доступно с 15 дня пользования займом. Если вы хотите оформить продление займа раньше,
      то после внесения платежа свяжитесь с нами по телефону
      <span class="prolongation-overlay-info">{{brandMobile}}</span> или напишите на почту
      <span class="prolongation-overlay-info">{{brandEmail}}</span>
    </p>
  </div>
</section>
<ng-content></ng-content>
