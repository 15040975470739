import { Component, Input, OnDestroy } from '@angular/core';
import { ActionsService } from '../../../../api/actions.service';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.sass'],
})
export class DocumentLinkComponent implements OnDestroy {
  @Input() name: string;
  @Input() style;
  @Input() documentType: string = 'INDIVIDUAL_CONTRACT';
  @Input() documentApi: string = 'getContractPdf'

  private _subscription: Subscription = new Subscription();

  constructor(private actionsService: ActionsService) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  /**
   * Открывает индивидуальный договор pdf в отдельной вкладке
   */
  public openContract() {
    this._subscription.add(
      this.actionsService.getContractPdf(this.documentType).subscribe(
        (doc) => {
          const blob = new Blob([doc], { type: 'application/pdf' });
          saveAs(blob, this.documentType.toLowerCase() + '.pdf');
        },
        (err) => {
          const windowReference = window.open();
          windowReference.document.write(
            '<h1>Ошибка при загрузке документа. Попробуйте позже</h1>'
          );
        }
      )
    );
  }

  public openConsentReport() {
    this._subscription.add(
      this.actionsService.getConsentReportPdf(this.documentType).subscribe(
        (doc) => {
          const blob = new Blob([doc], { type: 'application/pdf' });
          saveAs(blob, this.documentType.toLowerCase() + '.pdf');
        },
        (err) => {
          const windowReference = window.open();
          windowReference.document.write(
            '<h1>Ошибка при загрузке документа. Попробуйте позже</h1>'
          );
        }
      )
    );
  }
}
