import { NgModule } from '@angular/core';
import {HistoryLoanComponent} from "./history-loan.component";
import {SharedModule} from "../shared/shared.module";
import {DialogModule} from "primeng/dialog";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    BrowserModule
  ],
  declarations: [HistoryLoanComponent],
  providers: [],
  exports: [HistoryLoanComponent],
})
export class HistoryLoanModule {}
