<div
  class="photo-container"
  id="photo-container"
  [ngClass]="brand === 'VIVUS' ? 'vivus-style' : ''"
>
  <div class="image-container">
    <img width="60" height="60" id="user-photo" />
  </div>
  <div class="popup">
    <div class="popup-item" (click)="select('profile')">
      Профиль и настройка
    </div>
    <div class="popup-item" (click)="select('logout')">Выход</div>
  </div>
</div>
