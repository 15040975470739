import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment-mini-ts';
import { Loans, PaymentSchedule } from '../../../../classes/loans.class';
import { ActionsService } from 'src/app/api/actions.service';
import detectMobile from 'src/app/utils/detect-mobile';

@Component({
  selector: 'app-payment-schedules',
  templateUrl: `payment-schedules.component.html`,
  styleUrls: [`payment-schedules.component.sass`],
})
export class PaymentSchedulesComponent implements OnInit, OnDestroy {
  public loans: Loans;
  public paymentSchedules: PaymentSchedule[];
  public isMobile = detectMobile();

  private _sub: Subscription = new Subscription();

  constructor(private _actionsService: ActionsService) {}

  ngOnInit(): void {
    this._actionsService.getPaymentSchedules().then((res) => {
      this.paymentSchedules = res.data.map((item) => {
        item['debt'] = parseFloat(item['debt']);
        item['fee'] = parseFloat(item['fee']);
        item['remain'] = parseFloat(item['remain']);
        return item;
      });
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public getPeriod(index: number, type: string = 'descktop'): string {
    if (type === 'mobile') {
      return moment(this.paymentSchedules[index].paymentDate)
        .utc()
        .format('DD.MM.YYYY');
    } else {
      if (index === 1) {
        return `${moment(this.paymentSchedules[index - 1].paymentDate)
          .utc()
          .format('DD.MM.YYYY')} — ${moment(
          this.paymentSchedules[index].paymentDate
        )
          .utc()
          .format('DD.MM.YYYY')}`;
      } else {
        if (moment(this.paymentSchedules[index - 1].paymentDate).utc().format('DD.MM.YYYY') ===
          moment(this.paymentSchedules[index].paymentDate).utc().format('DD.MM.YYYY')) {
          return `${moment(this.paymentSchedules[index].paymentDate).utc().format('DD.MM.YYYY')} —
          ${moment(
            this.paymentSchedules[index].paymentDate
          ).utc().format('DD.MM.YYYY')}`;
        } else {
          return `${moment(this.paymentSchedules[index - 1].paymentDate)
            .utc()
            .add(1, 'days')
            .format('DD.MM.YYYY')} — ${moment(
            this.paymentSchedules[index].paymentDate
          )
            .utc()
            .format('DD.MM.YYYY')}`;
        }
      }
    }
  }
}
