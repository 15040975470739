<div *ngIf="isShow" class="popup-wrapper">
  <div class="popup-innerwrapper">
    <div class="popup-message">
      <div>
        <div class="popup-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
