import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import Routes from '../../constants/routes.const';
import { Subscription } from 'rxjs';
import { ClientInfoService } from '../../services/client-info.service';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';
import { CabinetService } from '../../services/cabinet.service';
import { PermissionService } from '../../services/permission.service';
import {
  childPageList,
  returnMessageList,
} from '../../constants/cabinet.const';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Client } from '../../classes/client.class';
import detectMobile from 'src/app/utils/detect-mobile';

@Component({
  selector: 'app-cabinet',
  templateUrl: './cabinet.component.html',
  styleUrls: ['./cabinet.component.sass'],
})
export class CabinetComponent implements OnInit, OnDestroy {
  public brand: string;
  public client: Client;
  public routes = Routes;
  public showReturn = false;
  public showDocument = false;
  public showUserProfile = false;
  public returnMessage: string;
  public isMobile = detectMobile();

  private _subscription: Subscription = new Subscription();

  constructor(
    private _clientInfoService: ClientInfoService,
    private _cabinetService: CabinetService,
    private _storageService: StorageService,
    private _premissionService: PermissionService,
    private _router: Router,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.brand = environment.brand;

    this._cabinetService.menuSelected.subscribe((res) => {
      this.redirectTo(res);
    });

    this._subscription.add(
      this._clientInfoService.getClient().subscribe((res) => {
        this.client = res;
      })
    );
    this.checkReturnButton();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  @HostListener('window:popstate', ['$event']) checkPopstate(event) {
    if (
      this._storageService.get('SHOW_RETURN') &&
      !childPageList.includes(this._location.path().slice(1))
    ) {
      this.closeChildPage();
    }
  }

  /**
   * Convert to number
   * @param {any: any}
   */
  public getNumber(any: any): number {
    return Number.parseInt(any);
  }

  /**
   * Open additonal pages
   */
  public redirectTo(type: string): void {
    if (!this.showReturn) {
      this.showReturn = true;
      this.returnMessage = this.getReturnMessage();
      this._storageService.set('SHOW_RETURN', 'true');
      this._storageService.set('CURRENT_PATH', window.location.pathname);
      this._storageService.set('CURRENT_SEARCH', window.location.search);
    }
    switch (type) {
      case 'documents':
        this._router.navigate([this.routes.CABINET_DOCUMENT_PAGE], {
          queryParamsHandling: 'merge',
        });
        break;
      case 'userProfile':
        this._router.navigate([this.routes.USER_PROFILE_PAGE], {
          queryParamsHandling: 'merge',
        });
    }
  }

  /**
   * Close additonal pages
   */
  public closeChildPage(): void {
    this.showReturn = false;
    this._storageService.set('SHOW_RETURN', 'false');
    const path = this._storageService.get('CURRENT_PATH');
    const search = this._storageService.get('CURRENT_SEARCH');
    if (search) {
      const value = search.slice(1).split('=');
      const query = {};
      query[value[0]] = value[1];
      this._router.navigate([path], {
        queryParams: query,
        queryParamsHandling: 'merge',
      });
    } else {
      this._router.navigate([path], {
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Check initial state cabinet page (additional pages open or not)
   */
  private checkReturnButton(): void {
    if (this._storageService.get('SHOW_RETURN') === 'true') {
      this.showReturn = true;
      this.returnMessage = this.getReturnMessage();
    } else {
      this.showReturn = false;
    }
  }

  /**
   * Text for return button
   */
  private getReturnMessage(): string {
    const statusCode = this._premissionService.getStatusCode(true);
    return returnMessageList[statusCode];
  }
}
