<div class="popup-wrapper" #popup>
  <div class="popup-innerwrapper">
    <a class="close" (click)="hide()">X</a>
    <div class="popup-message">
      <div class="field-wrap" style="width: 100%">
        <p>{{ message }}</p>
      </div>
      <div class="field-wrap" style="width: 100%">
        <a class="popup-link" id="redirect" (click)="redirectToLoginPage()"
          >Перейти на страницу входа</a
        >
      </div>
    </div>
  </div>
</div>
