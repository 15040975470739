import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-gtm-container',
  template: '',
})
export class CommonGtmContainerComponent implements OnInit {
  @Input() gtmId: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.gtmId) {
      this.createGTScript(this.gtmId);
      this.createGTFrame(this.gtmId);
    }
  }

  createGTScript(gt: string) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});" +
      "var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';" +
      "j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})" +
      "(window,document,'script','dataLayer','" +
      gt +
      "')";
    document.head.appendChild(s);
  }

  createGTFrame(gt: string) {
    const n = document.createElement('noscript');
    const f = document.createElement('iframe');
    f.src = 'https://www.googletagmanager.com/ns.html?id=' + gt;
    f.height = '0';
    f.width = '0';
    f.setAttribute('style', 'display:none;visibility:hidden');
    n.appendChild(f);
    this.elementRef.nativeElement.appendChild(n);
  }
}
