import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {Observable, of, tap} from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import Routes from '../../../constants/routes.const';
import { WaitingType } from '../../../constants/global.constant';
import {ActionsService} from "../../../api/actions.service";
import {RegistrationService} from "../../../services/registration.service";
import {catchError} from "rxjs/operators";
import {CookieService} from "ngx-cookie";

@Injectable()
export class CurrentPageGuard implements CanActivate {
  constructor(
    private _actions: ActionsService,
    private _registrationService: RegistrationService,
    private _storageService: StorageService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { regInfoId, error } = next.queryParams;
    const routingPath: number = +next.url[0].path;

    return this._actions.getRegistrationData(regInfoId)
      .pipe(tap(res => {
        this._registrationService.setRegistrationInfo(res)

        const { currentStage } = res.data.form

        if (!currentStage && routingPath === 1) {
          return true;
        }

        if (currentStage === 0 && routingPath === 1) {
          return true;
        } else if (currentStage === routingPath) {
          return true;
        } else if (currentStage !== routingPath && currentStage === 0) {
          this.router.navigate([Routes.FIRST_REG_STEP], {
            queryParamsHandling: 'merge',
          });
        } else if (currentStage !== routingPath && currentStage === 4) {
          this.router.navigate([Routes.WAITING_PAGE], {
            queryParams: {
              source: WaitingType.SCORING,
            },
            queryParamsHandling: 'merge',
          });
        } else if (currentStage !== routingPath) {
          this.router.navigate(['/' + currentStage], {
            queryParamsHandling: 'merge',
          });
        }

        return true
      }), catchError(_ => {
        if (routingPath === 1)
          return of(true)
        else
          this.router.navigate([Routes.FIRST_REG_STEP], {
            queryParamsHandling: 'merge',
          });
      }))
  }
}
