/**
 * Constant for routing paths
 */

export default {
  FIRST_REG_STEP: '1',
  SECOND_REG_STEP: '2',
  THIRD_REG_STEP: '3',
  IL_REG_STEP: '3/additional',
  REFINANCING_FIRST_REG_STEP: 'refinancing/1/:param',
  REFINANCING_SECOND_REG_STEP: 'refinancing/2',
  REFINANCING_THIRD_REG_STEP: 'refinancing/3',
  REFINANCING_FOURTH_REG_STEP: 'refinancing/4',
  AGREEMENTS_PAGE: 'agreements',
  CONTRACT_PAGE: 'contract',
  RULES_PAGE: 'rules',
  PASSWORD_RECOVERY_PAGE: 'recovery',
  LOGIN_PAGE: 'login',
  CABINET_PAGE: 'cabinet',
  THANK_YOU_PAGE: 'cabinet/spasibo',
  WAITING_PAGE: 'cabinet/waiting',
  SCORING_RESULT_SUCCESSFUL: 'cabinet/scoring/successful',
  SCORING_RESULT_FAILED: 'cabinet/scoring/failed',
  SCORING_RESULT_FAILED_DESCRIPTION: 'cabinet/scoring/failed/description',
  CONFIRMATION_EMAIL: 'cabinet/confirmation/email',
  CONFIRMATION_SMS: `cabinet/confirmation/sms`,
  CONFIRMATION_NEW_SMS: `cabinet/confirmation/new`,
  LOAN_ISSUED: 'cabinet/issued',
  REGISTRATION_CARD_SUCCESSFUL: 'cabinet/registration/card/successful',
  ACTIVE_LOAN: 'cabinet/active',
  RESCORING: 'cabinet/rescoring',
  CABINET_DOCUMENT_PAGE: 'cabinet/documents',
  USER_PROFILE_PAGE: 'cabinet/profile',
  CODE_RECOVERY_PAGE: 'recovery/code',
  NEW_PASSWORD_RECOVERY_PAGE: 'recovery/password',
  COMMISSION_REFUND: 'cabinet/commission_refund',
};
