import { Component, OnDestroy } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass'],
})
export class TooltipComponent implements OnDestroy {
  private sub: any;

  public isShow = false;

  constructor(private _popupService: PopupService) {
    this.sub = _popupService.getOpenTooltip().subscribe((res) => {
      this.openModal();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public openModal(): void {
    this.isShow = true;
  }

  public closeModal(): void {
    this.isShow = false;
  }
}
