<div
  [ngClass]="[
    brand === 'VIVUS' ? 'vivus-style' : '',
    !position ? 'left' : position
  ]"
>
  <button
    [ngClass]="[
      !size ? 'large' : size,
      class ? class : '',
      disabled ? 'disabled' : ''
    ]"
    class="btn filled app-button"
    (click)="pressButton()"
  >
    <span *ngIf="!disabled"><ng-content></ng-content></span>
    <div *ngIf="disabled" class="spiner_wrap">
      <div class="spiner"></div>
    </div>
  </button>
</div>
