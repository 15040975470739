import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.sass'],
})
export class BannerComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() img: string;
  @Input() color = '';

  constructor() {}
}
