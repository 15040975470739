import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../classes/client.class';
import { Loans } from '../classes/loans.class';
import { IuDtoInterface } from '../models/iu-dto.interface';
import { JTIDto } from '../classes/jti.interface';
import AuthType from '../constants/auth-type.constant';
import {
  IAdditionalProductsResponse,
  IPredefinedProductsResponse,
} from '../interfaces/additional-products.interface';
import { RepaymentHoliday } from '../interfaces/repayment-holiday.interface';
import {
  TidAddress,
  TidClient,
  TidPassport,
  TidToken,
} from '../containers/tid/tid.interface';
import {
  JuicyProcessType,
  JuicyRequestParams,
  JuicySourceType,
} from '../interfaces/juicy.interface';

@Injectable()
export class ActionsService {
  constructor(public apiService: ApiService, private _http: HttpClient) {}

  /**
   * API формы регистраций ------------------------------------------------------------------------------------
   */
  // получаем регистрационные данные по regitrationId
  getRegistrationData(regInfoId?: string): Observable<any> {
    return this.apiService.get(
      'apiUrl',
      `stage/v2${regInfoId ? '/' + regInfoId : ''}`
    );
  }

  // отправка формы с указанием шага
  sendRegistrationForm(body, stage, capToken): Observable<any> {
    const requestOptions = {
      params: new HttpParams().set('currentStage', stage),
      headers: new HttpHeaders().append('capToken', capToken),
      withCredentials: true,
      xhrFields: {
        withCredentials: true,
      },
    };
    return this.apiService.post('apiUrl', 'stage/v2', body, requestOptions);
  }

  /**
   * Method is required to send the last step of registration
   * @param {number} registrationInfoId
   * @returns {Observable<any>}
   */
  finishRegistration(registrationInfoId: string): Observable<any> {
    const requestOptions = {
      params: new HttpParams().set('infoId', registrationInfoId),
      withCredentials: true,
      xhrFields: {
        withCredentials: true,
      },
    };
    return this.apiService.post(
      'apiUrl',
      'register/v2/site',
      null,
      requestOptions
    );
  }

  // получаем регистрационные данные по regitrationId
  getInn(regitrationId: string): Observable<any> {
    return this.apiService.get('apiUrl', 'inn/' + regitrationId);
  }

  /**
   * Sending Analytics data
   * @param {string} type
   * @param {string} log
   * @returns {Promise<any>}
   */
  sendingAnalyticsLog(type: string, log: string) {
    return this.apiService
      .get('apiUrl', `log?title=${type}&message=${log}`)
      .toPromise();
  }

  // /**
  //  * Get registration data
  //  * @param {string} regId
  //  * @returns {Promise<any>}
  //  */
  // getRegInfo(regId: string | number): Observable<any> {
  //   return this.apiService.get('apiUrl', `register/site/${regId}`);
  // }

  /**
   * The method returns the availability status of the esia
   * @param {string} brand
   * @returns {Promise<any>}
   */
  getEsiaAvailabilityStatus(brand: string): Promise<any> {
    return this.apiService
      .get('apiUrl', `esia/enabled?brand=${brand}`)
      .toPromise();
  }

  /**
   * Check duplication
   * @param {string} type
   * @param {string} brand
   * @param {string} value
   */
  checkDuplicateEmail(brand: string, value: string): Observable<any> {
    return this.apiService.get(
      'apiUrl',
      `duplication/check-email?brand=${brand}&email=${value}&source=SITE`
    );
  }

  /**
   * Обновление куки джуси в момент ее получения
   *
   * @param registrationId идентификатор информации о регистрации
   * @param juicyCookie кука джуси
   */
  updateJuicyCookie(
    registrationId: string,
    juicyCookie: string
  ): Observable<any> {
    const requestOptions = {
      params: new HttpParams()
        .set('regId', registrationId)
        .set('cookie', juicyCookie),
      withCredentials: true,
    };
    return this.apiService.post(
      'apiUrl',
      `register/site/update/cookie`,
      null,
      requestOptions
    );
  }

  /**
   * Cabinet API --------------------------------------------------------------------------------------------------------------------------
   */
  // проверка, что пользователь еще не регистрировался
  public checkRegistration(): Observable<any> {
    return this.apiService.head('cabinetApiUrl', 'auth/check');
  }

  /**
   * mock method for future implementation of the scoring result
   * @returns {Observable<any>}
   */
  getScoringResult(strategy?: string): Observable<any> {
    return this.apiService.get(
      'cabinetApiUrl',
      `scoring?strategyGroup=${strategy ? strategy : ''}`
    );
  }

  /**
   * mock method for future implementation of the getting payment system
   * @returns {Observable<any>}
   */
  getAvailablePaymentSystems(
    productType: string,
    amount: number
  ): Observable<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `payments/payment-types?productType=${productType}&amount=${amount}`
      )
      .pipe(map((e) => e.data.filter((item) => item.availability)));
  }

  /**
   * mock method required to send a loan
   * @param body
   * @returns {Observable<any>}
   */
  postRequestForLoan(body): Observable<any> {
    return this.apiService.post('cabinetApiUrl', 'loans', body);
  }

  /**
   * Даты не успешных платежей
   */
  getFailedRecurringDates() {
    return this.apiService.get('cabinetApiUrl', 'loans/failed-recurring');
  }

  /**
   * mock method obtaining status on credit requet
   * @returns {Observable<any>}
   */
  getRequestLoanStatus(): Observable<any> {
    return this.apiService.get('cabinetApiUrl', `loans/states`);
  }

  /**
   * Getting all registered user cards
   * @returns {Observable<any>}
   */
  getCardList(): Observable<any> {
    return this.apiService.get('cabinetApiUrl', `cards`);
  }

  /**
   * User card activation
   * @param {number} cardId
   * @returns {Observable<any>}
   */
  activateUserCard(cardId: number): Observable<any> {
    return this.apiService.put('cabinetApiUrl', `cards/activate/${cardId}`);
  }

  /**
   * Registration of the user's Bank card
   * @returns {Observable<any>}
   */
  registrationUserCard(strategy: string): Observable<any> {
    const additionalParameters = {
      redirectOk:
        window.location.href +
        `?redirect=OK${strategy ? '&strategy=' + strategy : ''}`,
      redirectNoOk: window.location.href,
      autoVerify: true,
    };
    return this.apiService.post(
      'cabinetApiUrl',
      `cards/register`,
      additionalParameters
    );
  }

  /**
   * Check user Yandex Wallet
   * @returns {Observable<any>}
   */
  public checkYandexWallet(wallet): Observable<any> {
    return this.apiService.put(
      'cabinetApiUrl',
      `yandex/register-wallet?walletNumber=${wallet}`
    );
  }

  /**
   * Check user BAnk Account
   * @returns {Observable<any>}
   */
  public checkBankAccount(account): Observable<any> {
    return this.apiService.put(
      'cabinetApiUrl',
      `bank/processBankRequest`,
      account
    );
  }

  /**
   * Method of sending loan confirmation code
   * @param {string} code
   * @returns {Observable<any>}
   */
  sendConfirmationCode(code: string): Observable<any> {
    return this.apiService.get(
      'cabinetApiUrl',
      `loans/confirm/check?code=${code}`
    );
  }

  /**
   * Потверждение кредитной заявки
   *
   * @param code                               - код подтверждения из смс, введенный на форме
   * @param insuranceIncluded                  - согласие на получение страховки
   * @param consultationIncluded               - согласие на консультацию юриста
   * @param vetPetIncluded                     - согласие на консультацию ветеринара
   * @param dietPlanIncluded
   * @param astrologerConsultingIncluded       - согласие на консультацию астролога
   * @param psychologistConsultingIncluded     - согласие на консультацию психолога
   * @param dietConsIncluded
   * @param elderIncluded
   * @param firstChangeOnRightFrame            - флаг переключения на второй фрейм
   * @param loanTransferConsent
   * @param lawsuitDefendant
   */
  sendConfirm(
    code: string,
    insuranceIncluded?: boolean,
    consultationIncluded?: boolean,
    vetPetIncluded?: boolean,
    dietPlanIncluded?: boolean,
    astrologerConsultingIncluded?: boolean,
    psychologistConsultingIncluded?: boolean,
    dietConsIncluded?: boolean,
    elderIncluded?: boolean,
    firstChangeOnRightFrame?: boolean,
    loanTransferConsent: boolean = true,
    lawsuitDefendant: boolean = false
  ): Promise<any> {
    return this.apiService
      .post(
        'cabinetApiUrl',
        `loans/confirm/?code=${code}&insuranceIncluded=${insuranceIncluded}&consultationIncluded=${consultationIncluded}&vetPetIncluded=${vetPetIncluded}&dietPlanIncluded=${dietPlanIncluded}&dietConsultingIncluded=${dietConsIncluded}&astrologerConsultingIncluded=${astrologerConsultingIncluded}&psychologistConsultingIncluded=${psychologistConsultingIncluded}&elderIncluded=${elderIncluded}&firstChangeOnRightFrame=${firstChangeOnRightFrame}&loanTransferConsent=${loanTransferConsent}&lawsuitDefendant=${lawsuitDefendant}`
      )
      .toPromise();
  }

  /**
   * Потверждение кредитной заявки
   *
   * @param code                               - код подтверждения из смс, введенный на форме
   * @param insuranceIncluded                  - согласие на получение страховки
   * @param consultationIncluded               - согласие на консультацию юриста
   * @param vetPetIncluded                     - согласие на консультацию ветеринара
   * @param dietPlanIncluded
   * @param astrologerConsultingIncluded       - согласие на консультацию астролога
   * @param psychologistConsultingIncluded     - согласие на консультацию психолога
   * @param recurringConsent                   - согласие на реккурентное автосписание для займа
   * @param dietConsIncluded
   * @param elderIncluded
   * @param firstChangeOnRightFrame            - флаг переключения на второй фрейм
   * @param loanTransferConsent
   * @param lawsuitDefendant
   */
  sendConfirmWithRecurring(
    code: string,
    recurringConsent?: boolean,
    insuranceIncluded?: boolean,
    consultationIncluded?: boolean,
    vetPetIncluded?: boolean,
    dietPlanIncluded?: boolean,
    astrologerConsultingIncluded?: boolean,
    psychologistConsultingIncluded?: boolean,
    dietConsIncluded?: boolean,
    elderIncluded?: boolean,
    firstChangeOnRightFrame?: boolean,
    loanTransferConsent: boolean = true,
    lawsuitDefendant: boolean = false
  ): Promise<any> {
    return this.apiService
      .post(
        'cabinetApiUrl',
        `loans/confirm/?code=${code}&insuranceIncluded=${insuranceIncluded}&consultationIncluded=${consultationIncluded}&vetPetIncluded=${vetPetIncluded}&dietPlanIncluded=${dietPlanIncluded}&dietConsultingIncluded=${dietConsIncluded}&astrologerConsultingIncluded=${astrologerConsultingIncluded}&psychologistConsultingIncluded=${psychologistConsultingIncluded}&elderIncluded=${elderIncluded}&recurringConsent=${recurringConsent}&firstChangeOnRightFrame=${firstChangeOnRightFrame}&loanTransferConsent=${loanTransferConsent}&lawsuitDefendant=${lawsuitDefendant}`
      )
      .toPromise();
  }

  /**
   * Choosing an active payment system
   * @param {string} paymentType
   * @returns {Observable<any>}
   */
  setPaymentType(paymentType: string): Observable<any> {
    return this.apiService.put(
      'cabinetApiUrl',
      `clients/payment-type?paymentType=${paymentType}`
    );
  }

  /**
   * Obtaining individual conditions for the user
   * @param body
   * @returns {Observable<any>}
   */
  getDocument(documentName): Observable<any> {
    const requsetOpt = {
      params: new HttpParams(),
      responseType: 'blob',
      withCredentials: true,
      // headers: new HttpHeaders().set(
      //   'Content-Type',
      //   'application/pdf; charset=utf-8'
      // ),
    };
    return this.apiService.get(
      'cabinetApiUrl',
      `documents?format=PDF&type=${documentName}`,
      requsetOpt
    );
  }

  /**
   * Получает индивидуальный контракт
   */
  getContractPdf(type): Observable<any> {
    const requsetOpt = {
      params: new HttpParams(),
      responseType: 'arraybuffer',
      withCredentials: true,
      headers: new HttpHeaders().set('Content-Type', 'application/pdf'),
    };
    return this.apiService.get(
      'cabinetApiUrl',
      `documents?format=PDF&type=${type}`,
      requsetOpt
    );
  }

  /**
   * Получает Согласие на получение кредитного отчета
   */
  getConsentReportPdf(type): Observable<any> {
    const requsetOpt = {
      params: new HttpParams(),
      responseType: 'arraybuffer',
      withCredentials: true,
      headers: new HttpHeaders().set('Content-Type', 'application/pdf'),
    };
    return this.apiService.get(
      'cabinetApiUrl',
      `documents/consent-report?documentTypeName=${type}`,
      requsetOpt
    );
  }

  /**
   * Получает предварительный индивидуальный контракт
   */
  getIUPreviewPdf(dto: IuDtoInterface): Observable<any> {
    const requsetOpt = {
      params: new HttpParams(),
      responseType: 'arraybuffer',
      withCredentials: true,
      // headers: new HttpHeaders().set('Content-Type', 'application/pdf')
    };
    return this.apiService.post(
      'cabinetApiUrl',
      `documents/individual-contract`,
      dto,
      requsetOpt
    );
  }

  /**
   * Договор
   * @param dto
   */
  getContractPreviewPdf(dto: IuDtoInterface): Observable<any> {
    const requsetOpt = {
      params: new HttpParams(),
      responseType: 'arraybuffer',
      withCredentials: true,
      // headers: new HttpHeaders().set('Content-Type', 'application/pdf')
    };
    return this.apiService.post(
      'cabinetApiUrl',
      `documents/loan-application`,
      dto,
      requsetOpt
    );
  }

  /**
   * Получает индивидуальный контракт для истории займов
   */
  postContractPdfDocument(issueDate): Promise<any> {
    return this.apiService
      .post(
        'cabinetApiUrl',
        `documents`,
        {
          documentFormat: 'PDF',
          type: 'INDIVIDUAL_CONTRACT',
          dateOfRelevanceDocument: issueDate,
        },
        { responseType: 'blob' }
      )
      .toPromise();
  }

  /**
   * Resending the confirmation loan code
   * @returns {Observable<any>}
   */
  sendConfirmationLoanCode(): Observable<any> {
    return this.apiService.put(
      'cabinetApiUrl',
      `loans/request-confirmation-code`
    );
  }

  /**
   * Receiving data on a loan
   * @param {number} clientId
   * @returns {Observable<Loans>}
   */
  getLoanInformation(): Promise<Loans> {
    return this.apiService
      .get('cabinetApiUrl', `loans`)
      .pipe(map((res) => res.data))
      .toPromise();
  }

  getLoanInformationObs(): Observable<Loans> {
    return this.apiService
      .get('cabinetApiUrl', `loans`)
      .pipe(map((res) => res.data));
  }

  getLoanCurrentInformation(): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `loans/cession-loan`)
      .toPromise();
  }

  /**
   * Receiving data on a client
   * @returns {Observable<Client>}
   */
  getClientInformation(): Observable<Client> {
    return this.apiService
      .get('cabinetApiUrl', `clients`)
      .pipe(map((res) => res.data));
  }

  validateRescore(passportForm: any) {
    return this.apiService.post(
      'cabinetApiUrl',
      'scoring/validate-rescore',
      passportForm
    );
  }

  confirmRescoringCode(form) {
    return this.apiService.post(
      'cabinetApiUrl',
      `scoring/validate-client-code?code=${form.confirmationCode}`
    );
  }

  startClientRescoringProcess() {
    return this.apiService.post('cabinetApiUrl', 'scoring/rescore-client');
  }

  resendRescoringCode() {
    return this.apiService.get('cabinetApiUrl', 'scoring/resend-rescore-code');
  }

  /**
   * To initialize the data for the calculator
   * @returns {Observable<any>}
   */
  getInitializeCalcData(): Observable<any> {
    return this.apiService.get('cabinetApiUrl', 'payments/product-calc-info');
  }

  /**
   * To initialize the data for the prolongation calculator
   * @returns {Observable<any>}
   */
  getInitializeProlongCalcData(): Observable<any> {
    return this.apiService.get(
      'cabinetApiUrl',
      'payments/prolongation-calc-info'
    );
  }

  /**
   * Method for obtaining data in dynamics
   * @param {string} product
   * @param {number} amount
   * @param {number} duration
   * @returns {Observable<any>}
   */
  getDynamicCalcData(
    amount: number,
    duration: number,
    product: string
  ): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `payments/products/?amount=${amount}&duration=${duration}&productType=${product}`
      )
      .toPromise();
  }

  /**
   * Get bank name
   * @param {number} bik
   * @returns {Promise<any>}
   */
  getBankName(bik: number): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `bank/name?bic=${bik}`)
      .toPromise();
  }

  getCardStatus(cardId: string): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `cards/status?paymentId=${cardId}`)
      .toPromise();
  }

  /**
   * The method revert registration card process
   * @param {paymentId: string}
   * @return {Promise<any>}
   */
  revertCard(paymentId: any): Promise<any> {
    return this.apiService
      .post('cabinetApiUrl', `cards/revert?paymentId=${paymentId}`)
      .toPromise();
  }

  /**
   * Authorization, method for authenticate users
   * @param {string} authType - one of AuthType constant
   * @param {string} username
   * @param {string} password
   * @param {string} brand
   */
  authorization(
    authType: string,
    username: string,
    password: string,
    capRes: string,
    brand?: string
  ) {
    let reqOptions;
    reqOptions = { headers: new HttpHeaders().append('capToken', capRes) };
    const pass =
      authType === AuthType.PASSWORD ? encodeURIComponent(password) : password;
    return this.apiService.post(
      'cabinetApiUrl',
      `login?username=${username}&${authType}=${pass}&brand=${brand}`,
      null,
      reqOptions
    );
  }

  /**
   * Logout
   */
  logout(): Promise<any> {
    return this.apiService.get('cabinetApiUrl', 'logout').toPromise();
  }

  /**
   * Get QIWI BILL
   */

  getBill(): Promise<any> {
    return this.apiService
      .post('cabinetApiUrl', 'qiwi/send-qiwi-bill')
      .toPromise();
  }

  /**
   * Send custom qiwi bill
   * @param {amount: number}
   */
  sendQiwiBill(amount: number): Promise<any> {
    return this.apiService
      .post(
        'cabinetApiUrl',
        `qiwi/send-custom-qiwi-bill?amount=${amount}&paymentPurpose=LOAN_PAY`
      )
      .toPromise();
  }

  /**
   * Get repayment URI for Yandex, Alfa, Sber
   * @param {amount: number}
   * @param {paymentType: string}
   * @param {yandexWalletNumber: number}
   * @return {Promise<any>}
   */
  getRepaymentUri(
    amount: number,
    paymentType: string,
    yandexWalletNumber?: number
  ): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `yandex?amount=${amount}&paymentType=${paymentType}&redirectOk=${
          window.location.href + '?repayment=OK'
        }&redirectNoOk=${window.location.href}${
          yandexWalletNumber ? '&walletNumber=' + yandexWalletNumber : ''
        }`
      )
      .toPromise();
  }

  /**
   * Get repayment URI for Card
   * @return {Promise<any>}
   * @param amount
   * @param paymentPurpose
   */
  getRepaymentUriForCard(
    amount: number,
    paymentPurpose: string
  ): Observable<any> {
    const additionalParameters = {
      redirectOk: window.location.href + '?repayment=OK',
      redirectNoOk: window.location.href,
      autoVerify: true,
    };
    return this.apiService.post(
      'cabinetApiUrl',
      `cards/sale?amount=${amount}&purpose=${paymentPurpose}`,
      additionalParameters
    );
  }

  /**
   * Return history operations list
   * @param {page: Number, pageSize: Number }
   */
  getOperationHistory(page: Number = 0, pageSize: Number = 20): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `payments/transfer-history?page=${page}&pageSize=${pageSize}`
      )
      .toPromise();
  }

  /**
   * Return payment schedules for annuity loan
   */
  getPaymentSchedules(): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `payments/payment-schedule`)
      .toPromise();
  }

  /**
   * Return inforamtion block
   */
  getInfoBlock(): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `information/loan`)
      .pipe(map((res) => res.data))
      .toPromise();
  }

  /**
   * Send prolongation confirmation code
   * @param {code: string}
   * @return {Observable<any>}
   */
  sendProlongationCode({
    psySubscriptionIncluded,
    confirmationCode,
    subscriptionGroup,
  }): Observable<any> {
    const queryParams = {
      code: confirmationCode,
      subscriptionGroup: subscriptionGroup,
    };

    if (!psySubscriptionIncluded || !subscriptionGroup) {
      delete queryParams.subscriptionGroup;
    }

    return this.apiService.post(
      'cabinetApiUrl',
      `loans/v2/prolongation/confirm`,
      {},
      {
        params: queryParams,
      }
    );
  }

  /**
   * Resending the prolongation confirmation code
   * @returns {Observable<any>}
   */
  resendProlongationCode(): Observable<any> {
    return this.apiService.put('cabinetApiUrl', `loans/prolongation/resend`);
  }

  /**
   * The method return thie city list
   * @param {issuePointType: string}
   * @returns {Promise<any>}
   */
  getCityList(issuePointType: string): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `issue-points/cities?issuePointType=${issuePointType}`
      )
      .toPromise();
  }

  /**
   * The method returns the jetmoney offices list in the city
   * @param {issuePointType: string, city: string}
   */
  getOfficesList(issuePointType: string, city?: string): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `issue-points?issuePointType=${issuePointType}${
          city ? '&city=' + encodeURIComponent(city) : ''
        }`
      )
      .toPromise();
  }

  /**
   * The method select issue point
   */
  selectIssuePoint(issuePointType: string, point: string): Promise<any> {
    return this.apiService
      .post(
        'cabinetApiUrl',
        `issue-points/select?issuePointType=${issuePointType}&issuePointId=${point}`
      )
      .toPromise();
  }

  /** full identification */

  /**
   * Method for sending photos
   * @param photos;
   */
  sendClientPhotos(photos: any): Observable<any> {
    return this.apiService.post(
      'cabinetApiUrl',
      'full-identification/photo/upload',
      photos
    );
  }

  checkClientDocuments(): Observable<any> {
    return this.apiService.head(
      'cabinetApiUrl',
      'full-identification/photo/check'
    );
  }

  /**
   * Method to check availability full identification
   * @param brand
   */
  checkEnabledFullIdentification(brand: string): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', `full-identification/config/?brand=${brand}`)
      .toPromise();
  }

  /**
   * Method return list of product for current IL limit
   */

  /*getILProductList(limit?: number): Observable<any> {
    // return this.apiService.get(`bla-bla`);
    return of(getProductlist(limit));
  }*/

  /**
   * The method return missing fields for scoring
   */
  getMissingFields(strategyGroup: string): Promise<any> {
    return this.apiService
      .get(
        'cabinetApiUrl',
        `scoring/missing-fields?strategyGroup=${strategyGroup}`
      )
      .toPromise();
  }

  updateClientInfo(form): Promise<any> {
    return this.apiService
      .post('cabinetApiUrl', `clients/update`, form)
      .toPromise();
  }

  /**
   * The method run scoring
   */
  runScoring(strategy: string[]): Promise<any> {
    return this.apiService
      .post('cabinetApiUrl', `scoring/score`, strategy)
      .toPromise();
  }

  restorePassword(phone: string, brand: string, capRes: string): Promise<any> {
    let reqOptions;
    reqOptions = {
      headers: new HttpHeaders().append('capToken', capRes),
      params: {
        phone: phone,
        brand: brand,
      },
    };
    return this.apiService
      .post('cabinetApiUrl', `clients/restore-password`, null, reqOptions)
      .toPromise();
  }

  passwordRecoverySendCode(
    phone: string,
    brand: string,
    capRes: string
  ): Promise<any> {
    let reqOptions;
    reqOptions = {
      headers: new HttpHeaders().append('capToken', capRes),
      params: {
        phone: phone,
        brand: brand,
      },
    };
    return this.apiService
      .post('cabinetApiUrl', `clients/send-confirmation-code`, null, reqOptions)
      .toPromise();
  }

  passwordRecoveryCheckCode(
    phone: string,
    brand: string,
    code: string,
    capRes: string
  ): Promise<any> {
    const reqOptions = {
      headers: new HttpHeaders().append('capToken', capRes),
      params: {
        phone: phone,
        brand: brand,
        code: code,
      },
    };
    return this.apiService
      .post(
        'cabinetApiUrl',
        `clients/check-confirmation-code`,
        null,
        reqOptions
      )
      .toPromise();
  }

  passwordRecoveryChange(
    phone: string,
    brand: string,
    code: string,
    newPassword: string
  ): Promise<any> {
    const reqOptions = {
      params: {
        phone: phone,
        brand: brand,
        code: code,
        newPassword: newPassword,
      },
    };
    return this.apiService
      .post('cabinetApiUrl', `clients/change-password`, null, reqOptions)
      .toPromise();
  }

  /**
   * Получить код отказа из РЕО
   */
  obtainRejectionCode(): Promise<any> {
    return this.apiService
      .post('cabinetApiUrl', `scoring/rejection-code`)
      .toPromise();
  }

  /**
   * Получить дату рассмотрения повторной кредитной заявки
   */
  getReconsiderationDate(): Promise<any> {
    return this.apiService
      .get('cabinetApiUrl', 'scoring/reconsideration-date')
      .toPromise();
  }

  needNps(): Promise<any> {
    return this.apiService.get('cabinetApiUrl', `nps/need`).toPromise();
  }

  createNps(dto): Promise<any> {
    return this.apiService.post('cabinetApiUrl', `nps/create`, dto).toPromise();
  }

  /**
   * Получение информации об отображении ссылки в футере
   */
  getCommissionRefundLinkStatus(): Observable<any> {
    return this.apiService.get(
      'cabinetApiUrl',
      `additionalInfo/commissionRefund`
    );
  }

  /**
   * Обновление ввода кода подтверждения ознакомления с уведомлением о переходе КА
   */
  sendUpdateConsentDate(): Observable<any> {
    return this.apiService.get(
      'cabinetApiUrl',
      `transferToAgent/updateConsentDate`
    );
  }

  /**
   * Рубильник для новой формы получения денег
   */
  getConfirmationFormV2Flag(): Observable<any> {
    return this.apiService
      .get('cabinetApiUrl', 'loans/confirmationFormV2')
      .pipe(
        map((v) => {
          return v.data;
        })
      );
  }

  /**
   * Новый метод отправки смс сообщения с кодом
   */
  sendConfirmationCodeForFormV2(): Observable<any> {
    return this.apiService.put('cabinetApiUrl', 'loans/confirm/send');
  }

  /**
   * Информация о том идёт ли сейчас скоринг на выдаче
   */
  isCompleteLastScoringTrack(): Observable<boolean> {
    return this.apiService
      .get('cabinetApiUrl', 'scoring/isCompleteLastScoringTrack')
      .pipe(map((value) => value.data));
  }

  /**
   * Информация о том идёт ли рескоринг в данный момент или завершен
   */
  hasActiveRescoreTrack(): Observable<boolean> {
    return this.apiService
      .get('cabinetApiUrl', 'scoring/hasActiveRescoreTrack')
      .pipe(map((value) => value.data));
  }

  clientRescoringAvailability(): Observable<boolean> {
    return this.apiService
      .get('cabinetApiUrl', 'scoring/clientRescoringAvailability')
      .pipe(map((value) => value.data));
  }

  /**
   * Проверка рубильника на получение рекламы
   */
  agreementAdvCheck(brand: string): Observable<boolean> {
    return this.apiService
      .get('apiUrl', 'register/site/agreementAdvtCheckbox', {
        params: {
          brand,
        },
      })
      .pipe(map((value) => value.data));
  }

  /**
   * Отправка JTI
   * @param dto
   */
  sendJtiLead(dto: JTIDto): Observable<any> {
    return this.apiService.post('apiUrl', 'jti/create', dto);
  }

  /**
   * Проверка на доступность сервиса JTI
   */
  isJtiEnabled(): Observable<any> {
    return this.apiService.get('apiUrl', 'jti/enabled');
  }

  /**
   * Get params from config smsfinance/vivus.ru
   * @param {string} parameters
   * @returns {Observable<any>}
   */

  getApiConfig(...parameters: string[]): Observable<any> {
    let params = new HttpParams();

    parameters.forEach((v) => {
      params = params.append('param', v);
    });

    return this.apiService.get('apiUrlConfig', '', {
      params,
    });
  }

  /**
   * Получение статуса ПДН
   */
  getPdnStatus() {
    return this.apiService.get('cabinetApiUrl', 'loans/pdnFromTerms');
  }

  /**
   * Список купленных кроссов по текущему активному займу
   */
  getIssuedCrossProductsList(): Observable<IAdditionalProductsResponse> {
    return this.apiService.get('cabinetApiUrl', 'loans/additional-products');
  }

  /**
   * Получение массива данных по клиенту
   */
  getLoanCustomerData(): Promise<any> {
    return this.apiService.get('cabinetApiUrl', `loans/all`).toPromise();
  }

  getRepaymentHoliday(): Observable<RepaymentHoliday> {
    return this.apiService.get('cabinetApiUrl', 'loans/repayment-holiday');
  }

  /**
   * Расположение карусели с бэка
   */
  getPredefinedRotation(): Observable<any> {
    return this.apiService.get('cabinetApiUrl', 'crossproducts');
  }

  /**
   * Получение токена для сбора данных
   * @param code
   * @param clientGuid
   * @param brand
   */
  tidGetToken(
    code: string,
    clientGuid: string,
    brand: string
  ): Observable<TidToken> {
    return this.apiService.get('cabinetApiUrl', 'tid/token', {
      params: {
        clientGuid,
        code,
        brand,
      },
    });
  }

  /**
   * Данные по клиенту от сервиса TID
   * @param token
   * @param clientGuid
   */
  tidClientRegistrationData(
    token: string,
    clientGuid: string
  ): Observable<TidClient> {
    return this.apiService.get('cabinetApiUrl', 'tid/client', {
      params: {
        clientGuid,
        token,
      },
    });
  }

  tidPassportRegistrationData(
    token: string,
    clientGuid: string
  ): Observable<TidPassport> {
    return this.apiService.get('cabinetApiUrl', 'tid/passport', {
      params: {
        token,
        clientGuid,
      },
    });
  }

  tidAddressRegistrationData(
    token: string,
    clientGuid: string
  ): Observable<TidAddress[]> {
    return this.apiService.get('cabinetApiUrl', 'tid/addresses', {
      params: {
        token,
        clientGuid,
      },
    });
  }

  /**
   * Отправка Juicy запроса
   * @param juicyCookie
   * @param processType
   */
  sendJuicyRequest(
    juicyCookie: string,
    processType: JuicyProcessType
  ): Observable<any> {
    const params: JuicyRequestParams = {
      juicyCookie,
      processType,
      sourceType: JuicySourceType.SITE,
    };
    return this.apiService.post(
      'cabinetApiUrl',
      'juicy',
      {},
      {
        params,
      }
    );
  }
}
