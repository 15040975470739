<div
  *ngIf="isShow"
  class="popup-wrapper"
  [ngStyle]="{ 'max-width': maxWidth ? maxWidth + 'px' : '' }"
>
  <div class="popup-innerwrapper">
    <div class="popup-header">
      <a class="close" (click)="emitClose()">X</a>
    </div>
    <div class="popup-message">
      <div>
        <div class="popup-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="popup-back" *ngIf="isShow" (click)="closeModal()"></div>
