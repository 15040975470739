import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CurrentPageGuard } from './components/shared/guards/current-page.guard';
import { ClientStatusGuard } from './components/shared/guards/client-status.guard';
import { PreventRouting } from './components/shared/guards/prevent-routing.guard';
import { CabinetComponent } from './containers-cabinet/cabinet/cabinet.component';
import { AuthenticatedGuard } from './components/shared/guards/authenticated.guard';

/**
 * Routing configuration. The project uses lazy loading of modules to optimize page loading.
 * https://angular.io/guide/lazy-loading-ngmodules
 */

const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '1',
    pathMatch: 'full',
  },
  {
    path: '1',
    loadChildren: () =>
      import(
        './containers/registration-pages/first-registration-step/first-registration-step.module'
      ).then((m) => m.FirstRegistrationStepModule),
    canActivate: [CurrentPageGuard, ClientStatusGuard],
    data: { disableConsultant: true },
  },
  {
    path: '2',
    loadChildren: () =>
      import(
        './containers/registration-pages/second-registration-step/second-registration-step.module'
      ).then((m) => m.SecondRegistrationStepModule),
    canActivate: [ClientStatusGuard, CurrentPageGuard],
    data: { disableConsultant: true },
  },
  {
    path: '3',
    loadChildren: () =>
      import(
        './containers/registration-pages/third-registration-step/third-registration-step.module'
      ).then((m) => m.ThirdRegistrationStepModule),
    canActivate: [ClientStatusGuard, CurrentPageGuard],
    data: { disableConsultant: true },
  },
  {
    path: 'agreements',
    loadChildren: () =>
      import('./containers/commission-refund/commission-refund.module').then(
        (m) => m.CommissionRefundModule
      ),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'auth/tid',
    loadChildren: () =>
      import('./containers/tid/tid.module').then((m) => m.TidModule),
  },
  {
    path: 'contract',
    loadChildren: () =>
      import('./containers/contract-page/contract.module').then(
        (m) => m.ContractModule
      ),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./containers-cabinet/login/login.module').then(
        (m) => m.LoginModule
      ),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'recovery',
    loadChildren: () =>
      import('./containers/password-recovery/password-recovery.module').then(
        (m) => m.PasswordRecoveryModule
      ),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'recovery/code',
    loadChildren: () =>
      import(
        './containers/password-recovery/code-recovery/code-recovery.module'
      ).then((m) => m.CodeRecoveryModule),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'recovery/password',
    loadChildren: () =>
      import(
        './containers/password-recovery/new-password-recovery/new-password-recovery.module'
      ).then((m) => m.NewPasswordRecoveryModule),
    canActivate: [ClientStatusGuard],
  },
  {
    path: 'cabinet/waiting',
    loadChildren: () =>
      import('./containers/waiting-page/waiting-page.module').then(
        (m) => m.WaitingPageModule
      ),
    canActivate: [AuthenticatedGuard],
  },
  // {
  //   path: 'test/qwerty',
  //   loadChildren: () =>
  //     import('./containers/test/test.module').then(
  //       (m) => m.TestModule
  //     ),
  //   canActivate: [],
  // },
  {
    path: 'cabinet',
    component: CabinetComponent,
    canActivate: [ClientStatusGuard],
    children: [
      {
        path: 'spasibo',
        loadChildren: () =>
          import('./containers/spasibo/spasibo.module').then(
            (m) => m.SpasiboModule
          ),
      },
      {
        path: 'scoring/successful',
        loadChildren: () =>
          import(
            './containers/successful-scoring-result/successful-scoring-result.module'
          ).then((m) => m.SuccessfulScoringResultModule),
      },
      {
        path: 'scoring/failed',
        loadChildren: () =>
          import(
            './containers/failed-scoring-result/failed-scoring-result.module'
          ).then((m) => m.FailedScoringResultModule),
      },
      {
        path: 'scoring/failed/description',
        loadChildren: () =>
          import(
            './containers/failed-scoring-result-description/failed-scoring-result-description.module'
          ).then((m) => m.FailedScoringResultDescriptionModule),
      },
      {
        path: 'confirmation/email',
        loadChildren: () =>
          import(
            './containers/confirmation-email/confirmation-email.module'
          ).then((m) => m.ConfirmationEmailModule),
      },
      {
        path: 'confirmation/sms',
        loadChildren: () =>
          import(
            './containers/new-confirmation-loan-page/new-confirmation-loan-page.module'
          ).then((m) => m.NewConfirmationLoanPageModule),
      },
      {
        path: 'registration/card/successful',
        loadChildren: () =>
          import(
            './containers/successful-registration-card/successful-registration-card.module'
          ).then((m) => m.SuccessfulRegistrationCardModule),
      },
      {
        path: 'issued',
        loadChildren: () =>
          import('./containers/loan-issued/loan-issued.module').then(
            (m) => m.LoanIssuedModule
          ),
      },
      {
        path: 'active',
        loadChildren: () =>
          import('./containers-cabinet/active-loan/active-loan.module').then(
            (m) => m.ActiveLoanModule
          ),
      },
      {
        path: 'rescoring',
        loadChildren: () =>
          import('./containers-cabinet/rescoring/rescoring.module').then(
            (m) => m.RescoringModule
          ),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import(
            './containers-cabinet/cabinet-document/cabinet-document.module'
          ).then((m) => m.CabinetDocumentModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./containers-cabinet/user-profile/user-profile.module').then(
            (m) => m.UserProfileModule
          ),
      },
      {
        path: 'commission_refund',
        loadChildren: () =>
          import(
            './containers/commission-refund/commission-refund.module'
          ).then((m) => m.CommissionRefundModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '1',
  },
];

export const routing = RouterModule.forRoot(AppRoutes, {
  enableTracing: false,
  preloadingStrategy: PreloadAllModules,
});
