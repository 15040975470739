<div class="banner">
  <img [src]="img" alt="banner" class="banner-img" />
  <div class="banner-overlay">
    <header>
      <h3>{{ title }}</h3>
      <span>{{ subtitle }}</span>
    </header>
    <span><ng-content></ng-content></span>
  </div>
</div>
