<div class="jti">
  <div class="jti-title">
    <h4>Пока идет проверка, ответьте на 2 вопроса:</h4>
    <small class="jti-hint">*ответ, не повлияет на одобрение займа</small>
  </div>

  <div [ngSwitch]="step">

    <div class="jti-container" *ngSwitchCase="steps.FIRST">
      <div class="jti-container__title">
        Вы курите ?
      </div>
      <div class="jti-container__buttons">
        <button pButton pRipple class="p-button-outlined" (click)="setNextStepTo(steps.FOURTH)">Нет</button>
        <button pButton pRipple class="p-button-outlined" (click)="setNextStepTo(steps.SECOND)">Да</button>
      </div>
      <div class="jti-container__text">
        <p>Отвечая на вопрос о курении и сигаретах, я даю свое согласие
          на обработку моих персональных данных компаниями <a href="https://www.smsfinance.ru/links/pers_jti" target="_blank">ООО «Дж. Т.И. Россия»</a> и
          <a href="https://www.smsfinance.ru/links/pers_viled" target="_blank">ООО «ВИЛЭД»</a>,
          согласие на получение информационной рассылки от ООО «Дж. Т.И. Россия»,
          подтверждаю  ознакомление с <a href="https://www.smsfinance.ru/links/polityc_jti" target="_blank">Политикой конфиденциальности ООО «Дж. Т.И. Россия»</a> и
          <a href="https://www.smsfinance.ru/links/polityc_viled" target="_blank">Политикой конфиденциальности ООО «ВИЛЭД»</a>.</p>
      </div>
    </div>

    <div class="jti-container" *ngSwitchCase="steps.SECOND">
      <div class="jti-container__title">
        Какой бренд вы предпочитаете?
      </div>
      <div class="jti-container__brands">
        <div class="jti-container__brand"
             *ngFor="let brand of brands"
             (click)="selectBrand(brand)"
        >
          {{ brand.name }}
        </div>
      </div>
    </div>

    <div class="jti-container" *ngSwitchCase="steps.THIRD">
      <div class="jti-container__title">
        Укажите марку
      </div>
      <div class="jti-container__models">
          <button pButton class="p-button-outlined"
                  (click)="selectModel(model)"
               *ngFor="let model of selectedBrand.models"
          >
            {{ model.name }}
          </button>
      </div>
      <div class="jti-container__back">
        <p-button label="Назад" icon="pi pi-arrow-left"
                  styleClass="p-button-secondary p-button-outlined"
                  (click)="setNextStepTo(steps.SECOND)"
                  iconPos="left"></p-button>
      </div>
    </div>

    <div class="jti-container" *ngSwitchCase="steps.FOURTH">
      <div class="jti-container__close">
        <p-button icon="pi pi-times-circle"
                  styleClass="p-button-rounded p-button-secondary p-button-text"
                  (click)="setNextStepTo(steps.EMPTY)"
        ></p-button>
      </div>
      <div class="jti-container__thanks">
        <div class="jti-container__title">
          Спасибо за ваш ответ!
        </div>
      </div>

    </div>

  </div>
</div>
