import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() active: boolean;
  @Input() disabled = false;
  @Output() tabSelected = new EventEmitter<any>();

  public selectTab(): void {
    this.tabSelected.emit();
  }
}
