import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive()
@Injectable()
export class IssuePointsService {
  @Output() selectAnother: EventEmitter<boolean> = new EventEmitter();

  public maxAmountAnother: BehaviorSubject<number> = new BehaviorSubject(0);

  public selectAnotherEmit(): void {
    this.selectAnother.emit(true);
  }

  public getSelectAnother(): EventEmitter<boolean> {
    return this.selectAnother;
  }

  public getMaxAmountAnother(): BehaviorSubject<number> {
    return this.maxAmountAnother;
  }

  public setMaxAmountAnother(amount: number): void {
    this.maxAmountAnother.next(amount);
  }
}
