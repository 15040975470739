import {
  ElderInfoDto,
} from '../classes/loans.class';

export interface IAdditionalProductsResponse {
  data: IProductsData;
}

export interface IProductsData {
  loanIssued: number;
  cancelUntil: number;
  insuranceInfoDto?: InsuranceInfoDto;
  lawyerConsultingInfoDto?: LawyerConsultingInfoDto;
  vetPetConsultingInfoDto?: VetPetConsultingInfoDto;
  dietPlanInfoDto?: DietPlanInfoDto;
  dietConsultingInfoDto?: DietConsInfoDto;
  astrologerConsultingInfoDto?: AstrologerConsultingInfoDto;
  psychologistConsultingInfoDto?: PsychologistConsultingInfoDto;
  elderInfoDto?: ElderInfoDto;
}

export interface InsuranceInfoDto {
  availability: boolean;
  insuranceIncluded: boolean;
  instantPayment: boolean;
  insuranceCost: number;
  type: string;
  showInsurance: boolean;
  status: string;
}

export interface DietPlanInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
  type: string;
}

export interface DietConsInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
  type: string;
}

export interface LawyerConsultingInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
}

export interface VetPetConsultingInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
}

export interface AstrologerConsultingInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
}

export interface PsychologistConsultingInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
  status: string;
}

export enum IProductSlotName {
  INSURANCE = 'INSURANCE',
  LAWYER_CONSULTING = 'LAWYER_CONSULTING',
  VETPET_CONSULTING = 'VETPET_CONSULTING',
  TELEDOCTOR_CONSULTING = 'TELEDOCTOR_CONSULTING',
  ASTROLOGER_CONSULTING = 'ASTROLOGER_CONSULTING',
  PSYCHOLOGIST_CONSULTING = 'PSYCHOLOGIST_CONSULTING',
  DIET_PLAN_FAVOR = 'DIET_PLAN_FAVOR',
  DIET_CONSULTING = 'DIET_CONSULTING',
  ELDER = 'ELDER',
}

export interface IPredefinedProductsResponse {
  slot1: IProductSlotName;
  slot2: IProductSlotName;
  slot3: IProductSlotName;
  slot4: IProductSlotName;
  slot5: IProductSlotName;
  slot6: IProductSlotName;
}
