import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-info-popup',
  templateUrl: './card-information-popup.component.html',
  styleUrls: ['./card-information-popup.component.sass'],
})
export class CardInformationPopupComponent {
  @Input() title: string;
  @Input() type: string;
  public isShow = false;

  public openModal(): void {
    this.isShow = true;
  }

  public closeModal(): void {
    this.isShow = false;
  }
}
