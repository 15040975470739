import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class StorageService {
  constructor(private cookieService: CookieService) {}

  /**
   * Проверка и очистка storage в случае необходимости
   */
  init(): any {
    window.addEventListener('sessionready', function (v: any) {
      console.log('sessionready successful');
    });

    if (!this.check('SECONDS_LEFT')) {
      this.set('SECONDS_LEFT', '300000');
    }
  }

  get(item: any, type: string = 'ss'): any {
    const key = this.hash(item);
    if (type === 'ss') {
      return this.decrypt(sessionStorage[key]);
    } else if (type === 'ls') {
      return this.decrypt(localStorage[key]);
    } else if (type === 'cookie') {
      return this.decrypt(this.cookieService.get(key));
    } else if (type === 'cookie_item') {
      return this.cookieService.get(item)
        ? JSON.parse(this.cookieService.get(item))
        : {};
    }
    return null;
  }

  set(item: string, value: any, type: string = 'ss', options: any = {}): void {
    const key = this.hash(item);
    const cryptVal = this.encrypt(value.toString());
    if (type === 'ss') {
      sessionStorage.removeItem(key);
      sessionStorage.setItem(key, cryptVal);
    } else if (type === 'ls') {
      localStorage.removeItem(key);
      localStorage.setItem(key, cryptVal);
    } else if (type === 'cookie') {
      this.cookieService.put(key, cryptVal, options);
    } else if (type === 'cookie_item') {
      this.cookieService.put(item, JSON.stringify(value), options);
    }
  }

  remove(item: string, type: string = 'ss', crypto: boolean = true): void {
    let key: string;
    if (crypto) {
      key = this.hash(item);
    } else {
      key = item;
    }

    if (key) {
      if (type === 'ss') {
        sessionStorage.removeItem(key);
      } else if (type === 'ls') {
        localStorage.removeItem(key);
      } else if (type === 'cookie') {
        this.cookieService.remove(key);
      }
    }
  }

  check(item, type: string = 'ss'): boolean {
    const key = this.hash(item);
    if (type === 'ss') {
      return sessionStorage[key] !== undefined;
    } else if (type === 'ls') {
      return localStorage[key] !== undefined;
    } else if (type === 'cookie') {
      return this.cookieService.get(key) !== undefined;
    }
  }

  clear(type: string = 'ss') {
    if (type === 'ss') {
      sessionStorage.clear();
    } else if (type === 'ls') {
      localStorage.clear();
    } else if (type === 'cookie') {
      this.cookieService.removeAll();
    }
  }

  clearAll() {
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.removeAll();
  }

  private hash(item): string {
    const key = CryptoJS.SHA256(item, 'secret');
    return key.toString();
  }

  private encrypt(item): string {
    let result = '';
    result = CryptoJS.AES.encrypt(item, 'secret').toString();
    return result;
  }

  private decrypt(item): string {
    let result = '';
    if (item) {
      const bytes = CryptoJS.AES.decrypt(item, 'secret');
      result = bytes.toString(CryptoJS.enc.Utf8);
    }
    return result;
  }

  setJuicySession(sessionId: string) {
    this.set('juicy_session_id', sessionId);
  }

  getJuicySession(): string {
    return this.get('juicy_session_id');
  }
}
