import { Injectable } from '@angular/core';
import { ProcessHandlersType } from '../constants/global.constant';
import { StorageService } from './storage.service';
import { ConfirmationService } from 'primeng/api';
import { PhoneUtil } from '../utils/phone.util';
import { FORM_ERRORS_MESSAGE } from '../constants/form-errors.const';
import { Router } from '@angular/router';
import RoutesConst from '../constants/routes.const';
import {CrmErrorsConst} from "../constants/crm-errors.const";
import {AnalyticsEventsService} from "./analytic.service";
import {environment} from "../../environments/environment";

@Injectable()
export class DuplicationService {
  private ERRORS_MESSAGE = FORM_ERRORS_MESSAGE;

  public brand = environment.brand

  constructor(
    private storageService: StorageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public analyticService: AnalyticsEventsService,
  ) {}

  /**
   * Проверка успешной отправки кода подтверждения
   * @param mainPhone
   * @param handlers
   */
  public checkConfirmationCode(mainPhone, handlers) {
    const { CONFIRMATION_CODE } = ProcessHandlersType;
    const confirmation = handlers.find((v) => v.name === CONFIRMATION_CODE);

    if (confirmation && confirmation.status === 'SUCCESS') {
      const storageTimeouts = this.storageService.get('TIMEOUTS');
      const timeouts = storageTimeouts ? JSON.parse(storageTimeouts) : {};
      timeouts[PhoneUtil.changePhoneFormat(mainPhone)] = {
        start: Date.now(),
        msLeft: confirmation.data.msLeft,
      };
      this.storageService.set('TIMEOUTS', JSON.stringify(timeouts));
      return true;
    } else {
      return false;
    }
  }

  public checkExpiredPassport(showConformationDialog, handler) {
    if(handler.includes(CrmErrorsConst.EXPIRED_PASSPORT)) {
      return showConformationDialog
    }
  }

  public checkDuplications(handlers): boolean {
    const {
      MOBILE_DUPLICATION,
      EMAIL_DUPLICATION,
      PASSPORT_DUPLICATION,
      PASSPORT_SERIES_PLACEHOLDER,
    } = ProcessHandlersType;
    if (handlers) {
      for (let handler of handlers) {
        switch (handler.name) {
          case MOBILE_DUPLICATION:
            if (handler.data.hasDuplicates) {
              this.showDuplicationError(handler.data.statusCode);
              return false;
            }
            break;
          case EMAIL_DUPLICATION:
            if (handler.data.hasDuplicates) {
              this.showDuplicationError(handler.data.statusCode);
              return false;
            }
            break;
          case PASSPORT_DUPLICATION:
            if (handler.data.hasDuplicates) {
              this.showDuplicationError(handler.data.statusCode);
              return false;
            }
            break;
          case PASSPORT_SERIES_PLACEHOLDER:
            if (handler.data.hasDuplicates) {
              this.showDuplicationError(handler.data.statusCode);
              return false;
            }
            break;
        }
      }
    }

    return true;
  }

  /**
   * Очищаем данные по отправленной форме, так как обнаружен дубликат данных
   * @param errorCode
   * @private
   */
  private showDuplicationError(errorCode) {
    this.confirmationService.confirm({
      message: this.getDuplicationMessage(errorCode),
      icon: 'pi pi-warning',
      accept: () => {
        console.log('Accept', errorCode);
        this.redirect(errorCode);
      },
    });
  }

  /**
   * Редирект в зависимости от кода ошибки
   * @param errorCode
   * @private
   */
  private redirect(errorCode: number) {
    if (
      errorCode === 4 ||
      errorCode === 6 ||
      errorCode === 5 ||
      errorCode === 2
    )
      this.router.navigate([RoutesConst.LOGIN_PAGE]);
  }

  private getDuplicationMessage(errorCode: number): string {
    switch (errorCode) {
      case 0:
        return 'Неизвестная ошибка сервиса проверки дубликатов.';
      case 2:
        this.analyticService.eventRepeatPhone()
        return this.ERRORS_MESSAGE.PHONE_DUPLICATION;
      case 3:
        return this.ERRORS_MESSAGE.DEACTIVATED;
      case 4:
        this.analyticService.eventRepeatPhone()
        return this.ERRORS_MESSAGE.PHONE_DUPLICATION;
      case 5:
        this.analyticService.eventRepeatPassport()
        if(this.brand === 'VIVUS') {
          return this.ERRORS_MESSAGE.PASSPORT_DUPLICATION + this.ERRORS_MESSAGE.VIVUS_HOT_LINE;
        } else {
          return this.ERRORS_MESSAGE.PASSPORT_DUPLICATION + this.ERRORS_MESSAGE.SMSFINANCE_HOT_LINE;
        }
      case 6:
        this.analyticService.eventRepeatMail()
        return this.ERRORS_MESSAGE.EMAIL_DUPLICATION;
      case 7:
        return this.ERRORS_MESSAGE.ERROR;
      case 8:
        return 'Пожалуйста заполните данные паспорта еще раз';
      default:
        return this.ERRORS_MESSAGE.ERROR;
    }
  }
}
