import {Component, HostListener, Input, OnInit} from "@angular/core";
import detectMobile from "../../utils/detect-mobile";
import {environment} from "../../../environments/environment";
import {ActionsService} from "../../api/actions.service";
import {LoanCustomerData} from "../../classes/loans.class";
import * as moment from "moment-mini-ts";
import { saveAs } from 'file-saver';
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-history-loan',
  templateUrl: './history-loan.component.html',
  styleUrls: ['./history-loan.component.sass'],
})
export class HistoryLoanComponent implements OnInit {
  @Input() isOpen: boolean;

  public isMobile = detectMobile();
  public brand: string;

  public historyLoanData: LoanCustomerData[];

  constructor(
    private actionService: ActionsService,
    private modalService: ModalService
  ) {
    this.brand = environment.brand;
    this.modalService.isOpen$.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit() {
    this.actionService.getLoanCustomerData()
      .then(res => {
        this.historyLoanData = res.data
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].issueDateConvert = moment(res.data[i].issueDate).utcOffset('+06:00').format('DD.MM.YYYY')
          console.log('res res res', res.data)
        }
      })
  }

  public getDocumentIndividualContract(type, issueDate) {
    this.actionService.postContractPdfDocument(issueDate).then(doc => {
      const blob = new Blob([doc], {type: 'application/pdf'});
      saveAs(blob, type.toLowerCase() + '.pdf');
    }, err => {
      const windowReference = window.open();
      windowReference.document.write(`<h1>Ошибка при загрузке документа ${type.toLowerCase() + '.pdf'}. Попробуйте позже</h1>`)
      console.log('err', err)
    })
  }
}
