import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FORM_ERRORS_MESSAGE } from '../../../../constants/form-errors.const';
import { Router } from '@angular/router';
import routesConst from '../../../../constants/routes.const';

@Component({
  selector: 'app-popup-window',
  templateUrl: './popup-window.component.html',
  styleUrls: ['./popup-window.component.sass'],
  providers: [],
})
export class PopupWindowComponent implements OnInit {
  @ViewChild('popup') popup: ElementRef;
  @Input() status: number;
  @Input() brand: string;
  @Output() closed = new EventEmitter();

  public message: string;
  public ERRORS_MESSAGE;
  constructor(private _router: Router) {
    this.ERRORS_MESSAGE = FORM_ERRORS_MESSAGE;
  }

  ngOnInit() {
    switch (this.status) {
      case 0:
        console.log('refused flag when duplicates status is 0');
        break;
      case 1:
      case 2:
      case 4:
        this.message = this.ERRORS_MESSAGE.PHONE_DUPLICATION;
        sessionStorage.clear();
        break;
      case 3:
        this.message = this.ERRORS_MESSAGE.DEACTIVATED;
        break;
      case 5:
        this.message = this.ERRORS_MESSAGE.PASSPORT_DUPLICATION;
        break;
      case 6:
        this.message = this.ERRORS_MESSAGE.EMAIL_DUPLICATION;
        break;
      case 7:
        this.message = this.ERRORS_MESSAGE.ERROR;
        break;
      case 8:
        this.message = 'Пожалуйста заполните дынные паспорта еще раз';
        break;
      default:
        this.message = this.ERRORS_MESSAGE.ERROR;
        sessionStorage.clear();
        break;
    }
    const elem = this.popup.nativeElement;
  }

  redirectToLoginPage() {
    sessionStorage.clear();
    this._router.navigate([routesConst.LOGIN_PAGE], {
      queryParamsHandling: 'merge',
    });
  }

  hide() {
    this.closed.emit();
  }
}
