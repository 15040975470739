import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupWindowComponent } from './components/popup-window/popup-window.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppAnalyticsDirective } from './directives/analytic.directive';
import { PopupComponent } from './components/popup/popup.component';
import { TitleComponent } from './components/title/title.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { ruLocatePipe } from './pipes/ru-locate.pipe';
import { ButtonComponent } from './components/button/button.component';
import { BorderWrapperComponent } from './components/border-wrapper/border-wrapper.component';
import { IconComponent } from './components/icon/icon.components';
import { ToggleComponent } from './components/toggle/toggle.component';
import { abTestVersionDirective } from './directives/ab-test-version.directive';
import { LoanCardComponent } from './components/loan-card/loan-card.components';
import { maskBankCardPipe } from './pipes/mask-bank-card.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { CardInformationPopupComponent } from '../../containers/successful-scoring-result/card-information-popup/card-information-popup.component';
import { UiHelperComponent } from './components/ui-helper/ui-helper.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { OperationHistoryComponent } from './components/operation-history/operation-history.components';
import { InfoBlockComponent } from 'src/app/containers-cabinet/information-tabs/information-block.component';
import { PaymentSchedulesComponent } from './components/payment-schedules/payment-schedules.component';
import { SearchCityPipe } from './pipes/search-city.pipe';
import { DocumentLinkComponent } from './components/document-link/document-link.component';
import { ClickOutsideDirective } from './directives/click_outside.directive';
import { PreventKeysDirective } from './directives/prevent_keys.directive';
import { SkeletonModule } from 'primeng/skeleton';
import { DataMaskDirective } from './directives/data-mask.directive';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import {JtiComponent} from "./components/jti/jti.component";

@NgModule({
  imports: [
    CommonModule,
    SkeletonModule,
    CardModule,
    ButtonModule,
    RippleModule,
    DividerModule,
    DialogModule,
    SelectButtonModule
  ],
  declarations: [
    PopupWindowComponent,
    AppAnalyticsDirective,
    PopupComponent,
    TitleComponent,
    TabComponent,
    TabsComponent,
    InfoMessageComponent,
    ruLocatePipe,
    ButtonComponent,
    BorderWrapperComponent,
    IconComponent,
    ToggleComponent,
    abTestVersionDirective,
    LoanCardComponent,
    maskBankCardPipe,
    BannerComponent,
    CardInformationPopupComponent,
    OperationHistoryComponent,
    UiHelperComponent,
    TooltipComponent,
    CommonPopupComponent,
    InfoBlockComponent,
    PaymentSchedulesComponent,
    SearchCityPipe,
    DocumentLinkComponent,
    ClickOutsideDirective,
    PreventKeysDirective,
    DataMaskDirective,
    JtiComponent
  ],
  providers: [],
  exports: [
    PopupWindowComponent,
    ReactiveFormsModule,
    FormsModule,
    AppAnalyticsDirective,
    PopupComponent,
    TitleComponent,
    TabComponent,
    TabsComponent,
    InfoMessageComponent,
    ruLocatePipe,
    ButtonComponent,
    BorderWrapperComponent,
    IconComponent,
    ToggleComponent,
    abTestVersionDirective,
    LoanCardComponent,
    maskBankCardPipe,
    BannerComponent,
    CardInformationPopupComponent,
    UiHelperComponent,
    TooltipComponent,
    OperationHistoryComponent,
    CommonPopupComponent,
    PaymentSchedulesComponent,
    SearchCityPipe,
    DocumentLinkComponent,
    ClickOutsideDirective,
    PreventKeysDirective,
    DataMaskDirective,
    JtiComponent,
  ],
})
export class SharedModule {}
