import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class PopupService {
  private openTooltip: EventEmitter<any> = new EventEmitter();
  private closeTooltip: EventEmitter<any> = new EventEmitter();

  public openTooltipEvent(key: string, value?: any): void {
    this.openTooltip.emit({ key: key, value: value });
  }

  public getOpenTooltip(): any {
    return this.openTooltip;
  }

  public closeTooltipEvent(key?: string, value?: any): void {
    this.closeTooltip.emit({ key: key, value: value });
  }

  public getCloseTooltip(): any {
    return this.closeTooltip;
  }
}
