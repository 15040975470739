import { Component } from '@angular/core';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.sass'],
})
/**
 * InfoMessageComponent - Stateless component. Required to display important messages for the user.
 */
export class InfoMessageComponent {}
