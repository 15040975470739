/**
 * Application timeouts
 * @type {{LOAN_ISSUED_TIME: number}}
 */
export const TimeoutPeriod = {
  LOAN_ISSUED_TIME: 30000,
  SCORING_RESULT_TIME: 30000,
};

/**
 * Application brand interface
 */

interface Brands {
  SMSFINANCE: string;
  VIVUS: string;
}

/**
 * Brands used in the application.
 * Two brands are currently supported: Smsfinance and Vivus.
 */
export const AppBrands = <Brands>{
  SMSFINANCE: 'SMSFINANCE',
  VIVUS: 'VIVUS',
};

/**
 * Application source interface
 */
interface Sources {
  SITE: string;
}
/**
 * Sources indicates the type of application that has been configured.
 */
export const AppSources = <Sources>{
  SITE: 'SITE',
};

/**
 * Server date format
 * @type {string}
 */
export const ServerDateFormat = 'YYYY-MM-DD';
export const ServerDateFormatPart = 'DD.MM.YYYY';

/**
 * Client date format
 * @type {string}
 */
export const ClientDateFormat = 'dd.MM.yyyy';

/**
 * Adapted date format for the user
 * @type {string}
 */
export const UserDateFormat = 'd MMM y';

/**
 * Application credit calculator interface
 */
interface CreditCalculatorConfig {
  PDL: object;
  IL: object;
}

/**
 * The configuration is used for the credit calculator
 * @type {CreditCalculatorConfig}
 */
export const AppCreditCalculatorConfig = <CreditCalculatorConfig>{
  PDL: {
    MIN_SUM: 1000,
    MAX_SUM: 15000,
    DEFAULT_SUM: 5000,
    MIN_STAKE: 0,
    MAX_STAKE: 2,
    MIN_DAYS: 1,
    MAX_DAYS: 30,
    DEFAULT_DAYS: 21,
    GRACE_PERIOD: 14,
    STEP: 500,
  },
  IL: {
    MIN_SUM: 31000,
    MAX_SUM: 80000,
    DEFAULT_SUM: '',
    MIN_STAKE: 0.5,
    MAX_STAKE: 0.5,
    MIN_DAYS: 1,
    MAX_DAYS: 364,
    DEFAULT_DAYS: '',
    GRACE_PERIOD: 0,
    STEP: 500,
  },
};

/**
 * Scoring result interface
 */
interface ScoringTypeInterface {
  ACCEPTED: string;
  WAITING: string;
  REJECTED: string;
}

/**
 * Types of received scoring type from the server
 * @type {{ACCEPTED: string; WAITING: string; REJECTED: string}}
 */
export const ScoringType = <ScoringTypeInterface>{
  ACCEPTED: 'ACCEPTED',
  WAITING: 'WAITING',
  REJECTED: 'REJECTED',
};

/**
 * Credit request statuses interface
 */
interface CreditRequestStatusesInterface {
  ACCEPTED: string;
  REJECTED: string;
  WAITING_CONFIRMATION: string;
  WAITING_LOAN_ISSUED: string;
}

/**
 * Types of server response when sending a request for the issuance of loan
 * @type {CreditRequestStatusesInterface}
 */
export const CreditRequestStatuses = <CreditRequestStatusesInterface>{
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  WAITING_LOAN_ISSUED: 'WAITING_LOAN_ISSUED',
};

interface WaitingTypeInterface {
  SCORING: string;
  LOAN_PROCESSING: string;
  RESCORING: string;
}

/**
 * In the application at the moment there are 2 types of waiting,
 * this is the expectation of scoring and waiting for the loan.
 * @type {WaitingTypeInterface}
 */
export const WaitingType = <WaitingTypeInterface>{
  SCORING: 'scoring',
  LOAN_PROCESSING: 'loanProcessing',
  RESCORING: 'rescoring',
};

export const ScoringLoadingType = [
  'Проверяем паспортные данные',
  'Рассматриваем заявку на заём',
  'Создаём личный кабинет',
  'Изучаем кредитную историю',
  'Рассчитываем кредитный рейтинг',
  'Принимаем решение по заявке',
  'Устанавливаем кредитный лимит',
];

interface ProductTypeInterface {
  PDL: string;
  IL: string;
}

/**
 * Supported products by the app
 * @type {ProductTypeInterface}
 */
export const ProductType = <ProductTypeInterface>{
  PDL: 'PDL',
  IL: 'IL',
};

/**
 * Page titles
 * @type {{SMSFINANCE: {FIRST_REG_STEP: string; SECOND_REG_STEP: string; THIRD_REG_STEP: string}; VIVUS: {FIRST_REG_STEP: string; SECOND_REG_STEP: string; THIRD_REG_STEP: string}}}
 */

export const AppTitles = {
  SMSFINANCE: {
    FIRST_REG_STEP: '[смсфинанс} Регистрация. Контактная информация',
    SECOND_REG_STEP: '[смсфинанс} Регистрация. Паспорт и адрес',
    THIRD_REG_STEP: '[смсфинанс} Регистрация. Общие сведения о заёмщике ',
    FAILED_SCORING_RESULT: '[смсфинанс} В займе отказано',
    SUCCESSFUL_SCORING_RESULT: '[смсфинанс} Заявка на заём',
    CONFIRMATION_SMS: '[смсфинанс} Подтверждение заявки',
    SCORING: '[смсфинанс} Лимит определяется',
    LOAN_PROCESSING: '[смсфинанс} Получение займа',
    LOAN_ISSUED: '[смсфинанс} Заём выдан',
  },
  VIVUS: {
    FIRST_REG_STEP: 'VIVUS.RU Регистрация. Контактная информация',
    SECOND_REG_STEP: 'VIVUS.RU Регистрация. Паспорт и адрес',
    THIRD_REG_STEP: 'VIVUS.RU Регистрация. Общие сведения о заёмщике',
    FAILED_SCORING_RESULT: 'VIVUS.RU В займе отказано',
    SUCCESSFUL_SCORING_RESULT: 'VIVUS.RU Заявка на заём',
    CONFIRMATION_SMS: 'VIVUS.RU Подтверждение заявки',
    SCORING: 'VIVUS.RU Лимит определяется',
    LOAN_PROCESSING: 'VIVUS.RU Получение займа',
    LOAN_ISSUED: 'VIVUS.RU Заём выдан',
  },
};

/**
 * Constant is required to confirm registration or request loans
 * @type {{DURATION: number; INTERVAL: number}}
 */
export const Timer = {
  DURATION: 60,
  INTERVAL: 1000,
};

/**
 * Process handler interface
 */
interface ProcessHandlersTypeInterface {
  PASSPORT_DUPLICATION: string;
  CONFIRMATION_CODE: string;
  MOBILE_DUPLICATION: string;
  EMAIL_DUPLICATION: string;
  PASSPORT_SERIES_PLACEHOLDER: string;
}

/**
 * Types of handlers
 * @type {{PASSPORT_DUPLICATION: string; CONFIRMATION_CODE: string; MOBILE_DUPLICATION: string; EMAIL_DUPLICATION: string}}
 */
export const ProcessHandlersType = <ProcessHandlersTypeInterface>{
  PASSPORT_DUPLICATION: 'passportDuplicationHandler',
  CONFIRMATION_CODE: 'confirmationCodeSender',
  MOBILE_DUPLICATION: 'mobileDuplicationHandler',
  EMAIL_DUPLICATION: 'emailDuplicationHandler',
  PASSPORT_SERIES_PLACEHOLDER: 'passportSeriesValidator',
};

/**
 * Types of payment systems
 * @type {{CARD: string; CONTACT: string; YANDEX: string; BANK: string, QIWI: string}}
 */
export const PaymentSystemType = {
  CARD: 'CARD',
  CONTACT: 'CONTACT',
  YANDEX: 'YANDEX',
  BANK: 'BANK',
  QIWI: 'QIWI',
  OFFLINE_JETMONEY: 'OFFLINE_JETMONEY',
};

/**
 * Types of repayment systems
 * @type {{CARD: string; CONTACT: string; YANDEX: string; BANK: string, QIWI: string}}
 */
export const RepaymentSystemType = {
  CARD: 'CARD',
  QIWI: 'QIWI',
  YANDEX: 'YANDEX',
  SBERBANK: 'SBERBANK',
  ALFABANK: 'ALFABANK',
  BANK: 'BANK',
  EUROSET: 'EUROSET',
  CONTACT: 'CONTACT',
  ATM: 'ATM',
};

interface CalcProductTypeInterface {
  NON_RECURRING2: string;
  BIWEEKLY_ANNUITY: string;
  LONG: string;
}

/**
 * Supported products by the app
 * @type {CalcProductTypeInterface}
 */
export const CalcProductType = <CalcProductTypeInterface>{
  NON_RECURRING2: 'PDL',
  BIWEEKLY_ANNUITY: 'IL',
  LONG: 'IL',
};

/**
 * Supported products by the app
 */
export const ProductList = {
  NON_RECURRING2: 'NON_RECURRING2',
  BIWEEKLY_ANNUITY: 'BIWEEKLY_ANNUITY',
  LONG: 'LONG',
};

interface CalcProductStatusInterface {
  MISSING: string;
  AVAILABLE: string;
  UNAVAILABLE: string;
  REQUIRES_MORE_CLIENT_INFO: string;
  SCORING_REQUIRED: string;
}

/**
 * Types of product statuses
 * @type {CalcProductStatusInterface}
 */
export const CalcProductStatuses = <CalcProductStatusInterface>{
  MISSING: 'MISSING',
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  REQUIRES_MORE_CLIENT_INFO: 'REQUIRES_MORE_CLIENT_INFO',
  SCORING_REQUIRED: 'SCORING_REQUIRED',
};

interface LoanTypeInterface {
  NON_RECURRING2: string;
  BIWEEKLY_ANNUITY: string;
  LONG: string;
}

/**
 * Supported products by the app
 * @type {LoanTypeInterface}
 */
export const LoanType = <LoanTypeInterface>{
  NON_RECURRING2: 'NON_RECURRING2',
  BIWEEKLY_ANNUITY: 'BIWEEKLY_ANNUITY',
  LONG: 'LONG',
};

/**
 * Types of server fail response when sendirng request for a check bank account
 * @type {{COMMON: Array<string>; BIC: Array<string>; ACCOUNT: Array<string>;}}
 */
export const ErrorBankResponseType = {
  COMMON: [
    'WRONG_ACCOUNT_CONTROL_KEY',
    'ZERO_ACCOUNT_CONTROL_SUM',
    'OTHER_ERROR',
    'ACCOUNT_NUMBER_EXISTS',
    'CREDIT_REQUEST_EXISTS',
  ],
  BIC: ['WRONG_BIK', 'WRONG_BIK_LENGTH'],
  ACCOUNT: [
    'WRONG_ACCOUNT_NUMBER',
    'WRONG_ACCOUNT_PREFIX',
    'WRONG_ACCOUNT_NUMBER_LENGTH',
  ],
};

export const statusBankCard = {
  NEW: 'NEW',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

/**
 * Problem statuses from esia
 * @type {{VALIDATION_EXCEPTION: {type: string; message: string}; CLIENT_NOT_VERIFIED: {type: string; message: string}; ACCESS_DENIED_EXCEPTION: {type: string; message: string}; AUTHORIZATION_EXCEPTION: {type: string; message: string}; UNKNOWN_EXCEPTION: {type: string; message: string}}}
 */
export const EsiaStatuses = {
  VALIDATION_EXCEPTION: {
    type: 'VALIDATION_EXCEPTION',
    message:
      'Данные, полученные от сервиса Госуслуг, содержат недопустимые символы. Пожалуйста, заполните поля анкеты самостоятельно.',
  },
  CLIENT_NOT_VERIFIED: {
    type: 'CLIENT_NOT_VERIFIED',
    message:
      'Ваша учетная запись в сервисе Госуслуг не подтверждена. Пожалуйста, заполните поля анкеты самостоятельно.',
  },
  ACCESS_DENIED_EXCEPTION: {
    type: 'ACCESS_DENIED_EXCEPTION',
    message:
      'Для автозаполнения анкеты необходимо предоставить доступ к данным. Вы можете авторизоваться в сервисе Госуслуг повторно или заполнить поля анкеты самостоятельно.',
  },
  AUTHORIZATION_EXCEPTION: {
    type: 'AUTHORIZATION_EXCEPTION',
    message:
      'В настоящий момент нет ответа от сервиса Госуслуг. Вы можете авторизоваться в сервисе Госуслуг позднее или заполнить поля анкеты самостоятельно.',
  },
  UNKNOWN_EXCEPTION: {
    type: 'UNKNOWN_EXCEPTION',
    message:
      'В настоящий момент нет ответа от сервиса Госуслуг. Вы можете авторизоваться в сервисе Госуслуг позднее или заполнить поля анкеты самостоятельно.',
  },
};

/**
 * Types of payment systems
 * @type {{PAYMENT: string; REPAYMENT: string;}}
 */
export interface OperationTypeInt {
  [index: string]: string;
}
export const OperationType: OperationTypeInt = {
  PAYMENT: 'PAYMENT',
  REPAYMENT: 'REPAYMENT',
  PROLONGATION: 'PROLONGATION',
};

/**
 * Types of purpose for card repayment
 * @type {PAY: string: PROLONGATION: string}
 */
export const paymentPurposeList = {
  PAY: 'LOAN_PAY',
  PROLONGATION: 'LOAN_PROLONGATION',
};

/**
 * List of possible user agent
 * @type {Array<string>}
 */
export const UserAgentList = [
  'Chrome',
  'Firefox',
  'MSIE',
  'Safari',
  'Opera',
  'Mozilla',
  'Android',
  'iPhone',
  'iPad',
  'Linux',
  'Mac',
  'Windows',
];

/**
 * Номера телефона для страницы восстановления паролей
 */
export const RecoveryPhoneNumber = {
  SMSFINANCE: '8 800 333 27 57',
  VIVUS: '8 800 505 64 93',
};

/**
 * Телефон горячей линии
 */
export const HelpLinePhoneNumber = {
  SMSFINANCE: '8 800 333-27-57',
  VIVUS: '8 800 505-64-93',
};

/**
 * Код организации в системе контакт
 */
export const ContactOrganizationCode = {
  SMSFINANCE: {
    JMM: 'HXVG',
    FOUR_FINANCE: 'GEFP',
  },
  VIVUS: {
    JMM: 'KFBT',
    FOUR_FINANCE: 'FNCP',
  },
};
