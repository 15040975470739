import { Directive, EventEmitter, Injectable, Output } from '@angular/core';

@Directive()
@Injectable()
export class CabinetService {
  @Output() menuSelected: EventEmitter<string> = new EventEmitter();

  public selectMenu(type): void {
    this.menuSelected.emit(type);
  }
}
