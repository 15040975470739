/**
 * Available authorization types
 * Use this constant for {@link ActionsService#authorization} method
 * @type {{OTP: string; PASSWORD: string}}
 */
const AuthType = {
  OTP: 'otp',
  PASSWORD: 'password',
};

export default AuthType;
