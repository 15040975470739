<ngx-loading-bar
  [includeSpinner]="false"
  [color]="brand === 'VIVUS' ? '#a6ce39' : '#11add1'"
  height="4px"
></ngx-loading-bar>
<app-header
  [openCabinetUrlInNewContext]="openCabinetUrlInNewContext"
></app-header>
<div [ngClass]="brand === 'VIVUS' ? 'vivus-style' : ''" class="main-wrap">
  <p-confirmDialog #cd header="Действие" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="footer">
      <!--    <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>-->
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Ок"
        (click)="cd.accept()"
      ></button>
    </ng-template>
  </p-confirmDialog>
  <router-outlet *ngIf="!pageLoading; else pageLoader"></router-outlet>
  <ng-template #pageLoader>
    <div class="page-loading-progress">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-template>
</div>
<app-footer></app-footer>
<app-common-gtm-container [gtmId]="gtmId"></app-common-gtm-container>
