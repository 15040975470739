import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.sass'],
})
export class PopupComponent implements OnInit {
  @Input() brand: string;
  @Output() closed = new EventEmitter();
  public message;
  public yes;
  public no;
  public thisPage;
  public homePage;
  constructor() {}

  ngOnInit() {
    this.message =
      'Вы уверены, что хотите прервать регистрацию и вернуться на главную страницу?';
    this.yes = 'Прервать регистрацию';
    this.no = 'Остаться';
    this.thisPage = window.location.href;
    if (this.brand === 'SMSFINANCE') {
      this.homePage = 'https://www.smsfinance.ru/';
    } else {
      this.homePage = 'https://www.vivus.ru/';
    }
  }
  hide() {
    this.closed.emit();
  }
  public openHomePage(): void {
    sessionStorage.clear();
    window.open(this.homePage, '_self');
  }
}
