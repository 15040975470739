import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { mergeMap, Observable, of } from 'rxjs';
import { ActionsService } from '../../../api/actions.service';
import { PermissionService } from '../../../services/permission.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClientInfoService } from '../../../services/client-info.service';

@Injectable()
export class ClientStatusGuard implements CanActivate {

  constructor(
    private _actionsService: ActionsService,
    private _permission: PermissionService,
    private _clientInfoService: ClientInfoService
  ) {
  }

  /**
   * Check user authorization
   * @return {Observable<any>}
   */

  private getLogin(): Observable<any> {
    return this._actionsService.checkRegistration().pipe(
      switchMap(() => {
        return this._actionsService.getClientInformation();
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.getLogin()
      .pipe(
        map((client) => {
          if (typeof client === 'object') {
            this._clientInfoService.setClient(client)
          }
          this._permission.checkRoute(state.url, client);
          return true
        })
      )
      .pipe(
        catchError((error) => {
          return of(true);
        })
      );
  }
}
