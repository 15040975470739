<div *ngIf="isActive">
  <ng-content></ng-content>
</div>
<button
  pButton
  pRipple
  *ngIf="isShow && !isActive"
  (click)="toggle()"
  id="toggle-anchor"
  class="p-button-text"
  [label]="title"
></button>
