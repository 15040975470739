import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {Observable, of, tap} from 'rxjs';
import {ActionsService} from "../../../api/actions.service";
import {catchError, map, switchMap} from "rxjs/operators";
import RoutesConst from "../../../constants/routes.const";
import {ClientInfoService} from "../../../services/client-info.service";

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private _actionService: ActionsService,
    private _router: Router,
    private _clientInformationService: ClientInfoService
  ) {}

  /**
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._actionService.checkRegistration()
      .pipe(
        map(() => {
          this._actionService.getClientInformation().subscribe(
            client => this._clientInformationService.setClient(client)
          )

          return true
        }),
        catchError(_ =>
          this._router.navigate([RoutesConst.LOGIN_PAGE])))
  }
}
