import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { WindowRef } from '../classes/global-ref';
import { ActionsService } from '../api/actions.service';

declare var ga: any;
// window, there is a yaCounter reference
declare var window: any;
declare var _tmr: any;
declare var gtag: void;
declare var dataLayer: any;
declare var Ya: any;

@Injectable()
export class GoogleAnalytics {
  public create(): void {
    window.ga =
      window.ga ||
      function () {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();
    ga('create', environment.googleAnalyticsId, 'auto');
    const GTAG_script: any = document.createElement('SCRIPT');
    GTAG_script.async = 'true';
    GTAG_script.src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      environment.googleAnalyticsId;
    document.head.appendChild(GTAG_script);
    window.dataLayer = window.dataLayer || [];

    function gtag(name: any, param: any) {
      dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', environment.googleAnalyticsId);
  }

  public setEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue,
    });
  }

  public setUID(id: number = null): void {
    ga('set', 'userId', id);
    ga('send', 'pageview');
    console.log('ga sent UserId: ', id);
  }
}

@Injectable()
export class MailAnalytics {
  public _tmr: any;

  public create() {
    const Mail_img: any = document.createElement('IMG');
    const Mail_div = document.createElement('DIV');
    const Mail_noscript = document.createElement('NOSCRIPT');
    Mail_img.src =
      '//top-fwz1.mail.ru/counter?id=' + environment.mailRuId + ';js=na';
    Mail_img.style = 'border:0;position:absolute;left:-9999px;';
    Mail_div.appendChild(Mail_img);
    Mail_noscript.appendChild(Mail_div);
    document.body.appendChild(Mail_noscript);
    this._tmr = window._tmr || (window._tmr = []);
    this._tmr.push({
      id: environment.mailRuId,
      type: 'pageView',
      start: new Date().getTime(),
    });
    if (document.getElementById('topmailru-code')) {
      return;
    } else {
      const Mail_script: any = document.createElement('SCRIPT');
      Mail_script.type = 'text/javascript';
      Mail_script.async = true;
      Mail_script.id = 'topmailru-code';
      Mail_script.src =
        (document.location.protocol == 'https:' ? 'https:' : 'http:') +
        '//top-fwz1.mail.ru/js/code.js';
      document.body.appendChild(Mail_script);
    }
  }

   //setEvent(): void {
    // eventGoalName: string
    // this._tmr.push({
    //   type: 'reachGoal',
    //   id: environment.mailRuId,
    //   goal: eventGoalName,
    // });
  //}

}

@Injectable()
export class AnalyticsEventsService {
  constructor(
    public storageService: StorageService,
    public google: GoogleAnalytics,
    public mail: MailAnalytics,
    private winRef: WindowRef,
    private as: ActionsService
  ) {}

  eventRepeatMail(): void {
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        'povtorpochty'
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        'povtorpochty'
      );
    } catch (e) {
      console.error('povtorpochty', e);
    }
  }

  eventRepeatPassport(): void {
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        'povtorpasporta'
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        'povtorpasporta'
      );
    } catch (e) {
      console.error('povtorpasporta', e);
    }
  }

  eventRepeatPhone(): void {
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        'povtornomera'
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        'povtornomera'
      );
    } catch (e) {
      console.error('povtornomera', e);
    }
  }

  eventThankYou(goalName: string): void {
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        goalName
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        goalName
      );
    } catch (e) {
      console.error(goalName, e);
    }
  }

  eventVkAds(eventGoalName: string): void {
    try {
      this.winRef.nativeWindow['_tmr'].push({
        type: 'reachGoal',
        id: environment.mailRuId,
        goal: eventGoalName,
      });
    } catch (e) {
      console.error(eventGoalName, e);
    }
  }

  sendGTMDataLayerEvent(eventName: string, eventData?: any): void {
    if (!eventData) {
      eventData = {};
    }
    try {
      window.dataLayer.push({ event: eventName, ...eventData });
    } catch (e) {
      console.error('Unable to send GTM Data Layer Event');
      console.error(e);
    }
  }

  /**
   * Event when opening a popup when receiving a credit on a card
   */

  popUpCardStep(): void {
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        'zayompoluchendebet'
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        'zayompoluchendebet'
      );
    } catch (e) {
      console.error('zayompoluchendebet', e);
    }
  }

  clickPayCard(): void {
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        91416014,
        'reachGoal',
        'payment_personal_account'
      );
      this.winRef.nativeWindow['ym'](
        91997247,
        'reachGoal',
        'payment_personal_account'
      );
    } catch (e) {
      console.error('payment_personal_account', e);
    }
  }

  esiaClickFirstStep(): void {
    try {
      this.winRef.nativeWindow['ym'](91416014, 'reachGoal', 'clickESIA1');
      this.winRef.nativeWindow['ym'](91997247, 'reachGoal', 'clickESIA1');
    } catch (e) {
      console.error('clickESIA1', e);
    }
  }

  /**
   * Event on sending first registration page
   */
  firstStep(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_click_step1'
      );
      this.winRef.nativeWindow['ym'](91416014, 'reachGoal', 'clickshag2');
      this.winRef.nativeWindow['ym'](91997247, 'reachGoal', 'clickshag2');
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('n_click_step1', 'click', 'knopka');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Registration Initial Info');
  }

  sendDataClient(mobile: number): void {
    let ymMobile = '***' + `${mobile}`.substring(1);

    try {
      this.winRef.nativeWindow['ym'](91416014, 'userParams', {
        clientMobile: ymMobile,
      });
      this.winRef.nativeWindow['ym'](91997247, 'userParams', {
        clientMobile: ymMobile,
      });
      console.info('set ya.metrika.userParams:  clientMobile ', ymMobile);
    } catch (e) {
      console.error('clientMobile', e);
    }
  }

  /**
   * Event on sending second registration page
   */
  secondStep(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_click_step2'
      );
      this.winRef.nativeWindow['ym'](91416014, 'reachGoal', 'clickshag3');
      this.winRef.nativeWindow['ym'](91997247, 'reachGoal', 'clickshag3');
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('n_click_step2', 'click', 'knopka');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Personal Information');
  }

  /**
   * Event on sending third registration page
   */
  thirdStep(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_click_step3'
      );
      this.winRef.nativeWindow['ym'](91416014, 'reachGoal', 'clickshag4');
      this.winRef.nativeWindow['ym'](91997247, 'reachGoal', 'clickshag4');
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('n_click_step3', 'click', 'knopka');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Work Information');
  }

  fourthStep(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_click_step4'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('n_click_step4', 'click', 'knopka');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  /**
   * The event is initialized when the spasibo page is loaded
   */
  spasiboStep(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_spasibo'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('n_spasibo', 'click', 'knopka');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  // Отправка событий в Google analytics для отслеживания эффективности взаимодействия

  registrationError(category: string, action: string, label: string): void {
    this.google.setEvent(category, action, label);
  }

  consultantAnalytic(): void {
    this.google.setEvent(
      'registration',
      'click_onlne_consultant',
      this.storageService.get('CURRENT_STEP')
    );
  }

  readDoc(name: string): void {
    this.google.setEvent('registration_fields_ux', 'documentation_read', name);
  }

  sendTimeAnalytics(fieldName: string, time: string, value: number): void {
    this.google.setEvent(
      'registration_registration_fields_time',
      fieldName,
      time
    );
  }

  sendCalcAction(dataName: string, dataValue: string): void {
    this.google.setEvent('registration_calc', dataName, dataValue);
  }

  sendUX(dataName: string, dataValue: string = null): void {
    this.google.setEvent('registration_fields_ux', dataName, dataValue);
  }

  // matching yandex id and client id
  sendUserId(id: number): void {
    console.log('sendUserId/////////////', id);
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      let yaClientId;
      let ymClientId;
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'getClientID',
        (clientId) => {
          yaClientId = clientId;
        }
      );
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaIdI,
        'getClientID',
        (clientId) => {
          ymClientId = clientId;
        }
      );
      console.info('get ya.metrika.getClientID:', yaClientId);
      console.info('get ya.metrika.getClientID:', ymClientId);

      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'userParams',
        {
          crmClientId: id,
          crmUserIDonNavigationEnd_yaHit: id,
        }
      );
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaIdI,
        'userParams',
        {
          crmClientIdI: id,
          crmUserIDonNavigationEnd_yaHit: id,
        }
      );
      console.info('set ya.metrika.userParams:  crmClientId ', id);
      console.info('set ya.metrika.userParams:  crmClientIdI ', id);

      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'setUserID',
        '' + id
      );
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaIdI,
        'setUserID',
        '' + id
      );
      console.info('set ya.metrika.setUserID:  crmClientId ', id);
      console.info('set ya.metrika.setUserID:  crmClientIdI ', id);

      console.info('bond ya.ClientID[%s] with ya.UserID[%s]', yaClientId, id);
      console.info('bond ya.ClientID[%s] with ya.UserID[%s]', ymClientId, id);
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setUID(id);
      console.log('sent google.setUID:  crmClientId ', id);
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  //////////////////////////////////////////////////////////////////////////
  //                        Бесшовная регистрация                        //
  ////////////////////////////////////////////////////////////////////////

  /**
   * The load event of the page "limit is denied"
   */
  loadFailedScoringResultComponent(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Loan_Reject'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Loan_Reject');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Form Rejected');
  }

  /**
   * The event of loading the page "loan Application"
   */
  loadSuccessfulScoringResultComponent(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Loan_Request'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Loan_Request');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Form Pre Approved');
  }

  /**
   * The load event of the page "Processing application"
   */
  loadWaitingPageComponentsScoring(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Request_approve'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Request_approve');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Form submitted');
  }

  /**
   * The load event of the page "Confirmation"
   */
  loadConfirmationLoanPageComponent(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Loan_approve'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Loan_approve');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  /**
   * The load event of the page "Pending disbursement"
   */
  loadWaitingPageComponentsLoan(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Loan_wait'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Loan_wait');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  /**
   * The load event of the page "Loan"
   */
  loadLoanIssuedComponent(paymentType: string): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'Loan_issue'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('load_page', 'Loan_issue', paymentType);
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  matomoSuccess() {
    this.setMatomo('Successful loan signing');
  }

  /**
   * Click on "Add Card"
   */
  addCard(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'loan_request'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('loan_request', 'click', 'add_card');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  /**
   * Click on "Get money"
   */
  clickTakeMoney(paymentType: string): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'click_take_money'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('loan_request', 'click_take_money', paymentType);
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
    this.setMatomo('Final Approval');
  }

  /**
   * Click on "Submit approval request" (for IL)
   */
  clickIlRequest(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'click'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
    // google event
    try {
      this.google.setEvent('loan_request', 'click');
    } catch (e) {
      this.as.sendingAnalyticsLog(`googleEvent, registrationId: ${regId}`, e);
    }
  }

  /**
   * При показе pop-up о том, что клиент с таким номером есть (См. скриншот 001)
   */
  phoneIsExisted(): void {
    const regId = this.storageService.get('REGISTRATION');
    // yandex event
    try {
      this.winRef.nativeWindow['ym'](
        environment.yandexMetrikaId,
        'reachGoal',
        'n_double_by_phone'
      );
    } catch (e) {
      this.as.sendingAnalyticsLog(`yandexCounter, registrationId: ${regId}`, e);
    }
  }

  setMatomo(title: string): void {
    this.winRef.sendMatomoTitle(title);
  }

  pushMatomo(data) {
    this.winRef.pushMatomo(data);
    this.winRef.pushMatomo(['trackPageView']);
  }

  sendPageVisitedEventForFunnel(additionalObjectWithData) {
    this.winRef.sendPageVisitedEvent(additionalObjectWithData);
  }
}
