import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientInfoService } from '../../../../services/client-info.service';
import { Router } from '@angular/router';
import routesConst from '../../../../constants/routes.const';
import { CabinetService } from '../../../../services/cabinet.service';
import { ActionsService } from '../../../../api/actions.service';
import { StorageService } from '../../../../services/storage.service';
import { Female, Male } from '../../../../constants/user-photo.const';
import { environment } from '../../../../../environments/environment';
import { Client } from '../../../../classes/client.class';

@Component({
  selector: 'app-user-photo',
  templateUrl: `./user-photo.component.html`,
  styleUrls: [`./user-photo.component.sass`],
})
export class UserPhotoComponent implements OnInit {
  @ViewChild('photo-container')
  public client: Client;
  public brand: string;

  private sub: any;

  constructor(
    private _clientInfoService: ClientInfoService,
    private _cabinetService: CabinetService,
    private _actionService: ActionsService,
    private _router: Router,
    private _storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.brand = environment.brand;
    this.sub = this._clientInfoService.getClient().subscribe((res) => {
      this.client = res;
      if (this.client.firstName) {
        const elem = document.getElementById('user-photo');
        if (elem) {
          if (!this.client.photo) {
            if (this.client.gender === 'FEMALE') {
              elem.setAttribute('src', Female);
            } else {
              elem.setAttribute('src', Male);
            }
          } else {
            elem.setAttribute('src', this.client.photo);
          }
        }
      }
    });
  }

  /**
   * Go to user-profile page or logout
   * @param {type:string}
   */
  public select(type: string): void {
    switch (type) {
      case 'profile':
        this._cabinetService.selectMenu('userProfile');
        break;
      case 'logout':
        this._actionService.logout().then((res) => {
          this._storageService.clearAll();
          this._clientInfoService.clearClient();
          this._router.navigate([routesConst.LOGIN_PAGE], {
            queryParamsHandling: 'merge',
          });
        });
        break;
    }
  }
}
