<ng-container *ngIf="consultantLoaded && false">
  <div>
    <a
      class="button-consultant"
      href="javascript:window.ConsultSystems.windowShow();"
      (click)="showConsultant()"
    >
      <svg
        width="46"
        height="35"
        viewBox="0 0 46 35"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="Canvas" transform="translate(42 331)">
          <g
            id="&#208;&#145;&#208;&#181;&#208;&#183;&#209;&#139;&#208;&#188;&#209;&#143;&#208;&#189;&#208;&#189;&#209;&#139;&#208;&#185;-1 &#208;&#186;&#208;&#190;&#208;&#191;&#208;&#184;&#209;&#143;"
          >
            <g id="Vector">
              <use
                xlink:href="#path0_stroke"
                transform="translate(-27.0151 -330)"
                fill="#FFFFFF"
              />
            </g>
            <g id="Vector">
              <use
                xlink:href="#path1_stroke"
                transform="translate(-41 -318.777)"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
        <defs>
          <path
            id="path0_stroke"
            d="M 26.5427 16.6434L 27.2127 15.9011L 27.2077 15.8966L 26.5427 16.6434ZM 30.0151 19.7773L 29.3451 20.5197L 31.0151 22.0269L 31.0151 19.7773L 30.0151 19.7773ZM 1.14162 7.52282e-16L 1.14162 -1L 1.14162 7.52282e-16ZM -1 1.01639L -1 15.3306L 1 15.3306L 1 1.01639L -1 1.01639ZM -1 15.3306C -1 16.5395 0.083362 17.347 1.14162 17.347L 1.14162 15.347C 1.08219 15.347 1.0323 15.3237 1.00387 15.2984C 0.975299 15.2729 1 15.2784 1 15.3306L -1 15.3306ZM 1.14162 17.347L 25.7341 17.347L 25.7341 15.347L 1.14162 15.347L 1.14162 17.347ZM 25.7341 17.347C 25.7781 17.347 25.8082 17.3535 25.8272 17.3601C 25.8444 17.3661 25.8601 17.3746 25.8777 17.3903L 27.2077 15.8966C 26.7913 15.558 26.1632 15.3574 25.7341 15.347L 25.7341 17.347ZM 25.8727 17.3858L 29.3451 20.5197L 30.6851 19.035L 27.2127 15.9011L 25.8727 17.3858ZM 31.0151 19.7773L 31.0151 16.3893L 29.0151 16.3893L 29.0151 19.7773L 31.0151 19.7773ZM 31.0151 16.3893L 31.0151 1.01639L 29.0151 1.01639L 29.0151 16.3893L 31.0151 16.3893ZM 31.0151 1.01639C 31.0151 -0.192509 29.9318 -1 28.8735 -1L 28.8735 1C 28.9329 1 28.9828 1.02332 29.0113 1.04863C 29.0398 1.07407 29.0151 1.06858 29.0151 1.01639L 31.0151 1.01639ZM 28.8735 -1L 1.14162 -1L 1.14162 1L 28.8735 1L 28.8735 -1ZM 1.14162 -1C 0.0833609 -1 -1 -0.192508 -1 1.01639L 1 1.01639C 1 1.06858 0.975299 1.07407 1.00387 1.04862C 1.0323 1.02332 1.08219 1 1.14162 1L 1.14162 -1Z"
          />
          <path
            id="path1_stroke"
            d="M 31.0151 5.08197C 31.0151 4.52968 30.5674 4.08197 30.0151 4.08197C 29.4629 4.08197 29.0151 4.52968 29.0151 5.08197L 31.0151 5.08197ZM 3.47243 16.6434L 2.80746 15.8965L 2.80244 15.9011L 3.47243 16.6434ZM 9.07279e-08 19.7773L -1 19.7773L -1 22.0269L 0.66999 20.5197L 9.07279e-08 19.7773ZM 13.9849 1C 14.5372 1 14.9849 0.552285 14.9849 0C 14.9849 -0.552285 14.5372 -1 13.9849 -1L 13.9849 1ZM 29.0151 5.08197L 29.0151 15.3306L 31.0151 15.3306L 31.0151 5.08197L 29.0151 5.08197ZM 29.0151 15.3306C 29.0151 15.2784 29.0398 15.2729 29.0113 15.2984C 28.9828 15.3237 28.9329 15.347 28.8735 15.347L 28.8735 17.347C 29.9318 17.347 31.0151 16.5395 31.0151 15.3306L 29.0151 15.3306ZM 28.8735 15.347L 4.28108 15.347L 4.28108 17.347L 28.8735 17.347L 28.8735 15.347ZM 4.28108 15.347C 3.85193 15.3574 3.22387 15.558 2.80748 15.8966L 4.13739 17.3903C 4.15505 17.3746 4.17076 17.3661 4.18791 17.3601C 4.20695 17.3535 4.237 17.347 4.28108 17.347L 4.28108 15.347ZM 2.80244 15.9011L -0.66999 19.035L 0.66999 20.5197L 4.14242 17.3858L 2.80244 15.9011ZM 1 19.7773L 1 16.3893L -1 16.3893L -1 19.7773L 1 19.7773ZM 1 16.3893L 1 1.01639L -1 1.01639L -1 16.3893L 1 16.3893ZM 1 1.01639C 1 1.06858 0.975298 1.07407 1.00387 1.04862C 1.0323 1.02332 1.08219 1 1.14162 1L 1.14162 -1C 0.0833616 -1 -1 -0.192507 -1 1.01639L 1 1.01639ZM 1.14162 1L 13.9849 1L 13.9849 -1L 1.14162 -1L 1.14162 1Z"
          />
        </defs>
      </svg>
      <span>Онлайн-консультант</span>
    </a>
  </div>
</ng-container>
