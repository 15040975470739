<div *ngIf="loans.id; else loading">
  <div
    [ngClass]="['loan-card', 'loan-confirmation', (brand === 'VIVUS' ? 'vivus-style' : ''), (isMobile ? 'mobile' : '')]"
    *ngIf="type === loanCardType.CONFIRMATION"
  >
    <div class="loan-block">
      <div class="loan-row">
        <h4>Сумма займа</h4>
        <h4>{{getLoansAmount()}} &#8381;</h4>
      </div>
      <div class="loan-row">
        <p>Максимальный срок по договору</p>
        <p class="bold">{{getMaxDate() | ruLocatePipe}}</p>
      </div>
      <div class="loan-row" *ngIf="!(type === loanCardType.ISSUED)">
        <p [ngClass]="[client.paymentType === YANDEX ? 'yandex-payment' : '']">
          Заём будет перечислен{{client.paymentType === YANDEX ? ' На' : ''}}
          <strong
            >{{client.paymentType === YANDEX ? 'Яндекс.Кошелёк' : ''}}</strong
          >
        </p>
        <p class="bold">{{getPaymentTypeText()}}</p>
      </div>
      <div class="loan-row">
        <p>Процентная ставка</p>
        <p class="bold">{{loans.stake}}% в день</p>
      </div>

      <div class="loan-row" *ngIf="campaignInfoMessage">
        <span>
          Проценты не начисляются при погашении займа в 7 дневный срок.
          <a [href]="linkMoreByLegalEntity" target="_blank">Подробнее</a>
        </span>
      </div>
    </div>
  </div>
  <div
    class="field-wrap loan"
    *ngIf="type === loanCardType.ISSUED"
    [ngClass]="['loan-card', 'loan-issued', (brand === 'VIVUS' ? 'vivus-style' : ''), (isMobile ? 'mobile' : '')]"
  >
    <div class="left-block loan-block">
      <div class="loan-row">
        <p>Сумма</p>
        <p class="loan-value main-amount">{{loans.amount}} Р</p>
      </div>
      <div class="loan-row point" *ngIf="client.paymentType === CONTACT">
        <p>Приходите в ближайший пункт выдачи Contact. Возьмите паспорт.</p>
      </div>
      <div class="loan-row point" *ngIf="client.paymentType === CONTACT">
        <p>
          Для получения денег сообщите оператору код {{brandName[brand]}}:
          <strong>{{loans.loanPaymentTypeInfo.additionalFields.code}}</strong> и
          индивидуальный номер перевода:
          <strong
            >{{loans.loanPaymentTypeInfo.additionalFields.transactionId}}.</strong
          >
        </p>
      </div>
      <div class="loan-row" *ngIf="client.paymentType === BANK">
        <p>Точное время перечисления зависит от условий вашего банка.</p>
      </div>
    </div>
    <div class="right-block loan-block">
      <div class="info-block loan-row">
        <app-info-block></app-info-block>
      </div>
      <div class="loan-row">
        <p>Максимальный срок по договору</p>
        <p class="loan-value max-duration">{{getMaxDate() | ruLocatePipe}}</p>
      </div>
      <div class="loan-row">
        <p>Cтавка</p>
        <p class="loan-value">{{loans.stake}}% в день</p>
      </div>
      <div class="loan-row">
        <app-document-link [name]="contractLinkName"></app-document-link>
      </div>
      <div class="loan-row" *ngIf="annuity">
        <a (click)="openPopup('schedule')">График платежей</a>
      </div>
    </div>
  </div>
  <div
    class="field-wrap loan"
    *ngIf="type === loanCardType.ACTIVE"
    [ngClass]="['loan-card', 'loan-active', (brand === 'VIVUS' ? 'vivus-style' : ''), (isMobile ? 'mobile' : '')]"
  >
    <div class="left-block loan-block">
      <div class="loan-row loan-left-title desktop-view">
        <p>
          На сегодня <br />
          <span class="main-amount"
            >{{ this.getFixedSum(loans.amountRemain - loans.ownMoneyRemain) }}
            руб</span
          >
        </p>
        <p>
          <button
            pButton
            pRipple
            class="p-button-text"
            (click)="showDetails = !showDetails"
          >
            {{showDetails ? 'скрыть детали' : 'показать детали'}}
          </button>
        </p>
      </div>
      <div class="loan-row loan-left-title mobile-view">
        <p>На сегодня</p>
        <p class="loan-value">
          <span class="main-amount"
            >{{ this.getFixedSum(loans.amountRemain - loans.ownMoneyRemain) }}
            руб</span
          >
          <button
            pButton
            pRipple
            class="p-button-text"
            (click)="showDetails = !showDetails"
          >
            {{showDetails ? 'скрыть детали' : 'показать детали'}}
          </button>
        </p>
      </div>
      <p-card class="loan-details" *ngIf="showDetails">
        <div class="loan-row">
          <p>{{getDetailText('remain')}}</p>
          <p class="loan-value">
            {{loans.mainDeptRemain}}&nbsp;<span>руб</span>
          </p>
        </div>
        <p-divider></p-divider>
        <div class="loan-row">
          <p>{{getDetailText('fee')}}</p>
          <p class="loan-value">{{loans.fee}}&nbsp;<span>руб</span></p>
        </div>
        <p-divider></p-divider>
        <div class="loan-row" *ngIf="loans.penalty">
          <p>
            Штраф за {{getDays('penalty')}} {{declOfNumber(getDays('penalty'),
            ['день', 'дня', 'дней'])}} просрочки
          </p>
          <p class="loan-value">{{loans.penalty}}&nbsp;<span>руб</span></p>
        </div>
        <p-divider *ngIf="loans.penalty"></p-divider>
        <div
          class="loan-row branded-loan-row"
          *ngIf="loans.ownMoneyRemain"
          [ngClass]="[(brand === 'VIVUS' ? 'vivus-branded-loan-row' : '')]"
        >
          <p>Баланс абонентского счёта</p>
          <p class="loan-value">
            {{loans.ownMoneyRemain}}&nbsp;<span>руб</span>
          </p>
        </div>
      </p-card>
    </div>
    <div class="right-block loan-block">
      <div class="info-block">
        <app-info-block></app-info-block>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <p-skeleton width="100%" height="15rem" styleClass="mb-2"></p-skeleton>
</ng-template>
