/**
 * Link to the information page with discounts
 * @type {{SMSFINANCE: {link: string; days: number}; VIVUS: {link: string; days: number}; VIVUS_GRACE: {link: string}}}
 */
export const DiscontTerms = {
  SMSFINANCE: {
    link: 'https://www.smsfinance.ru/campaign?code=7-days-for-free-for-all',
    days: 7,
  },
  VIVUS: {
    link: 'https://www.vivus.ru/campaign?code=7-days-for-free-for-all',
    days: 7,
  },
  VIVUS_GRACE: {
    link: 'https://www.vivus.ru/discount-terms?name=STOCK_TERMS_GRACE_IN_TIME_CHEAPER',
  },
};

/**
 * Link to the information page with insurance
 * @type {{SMSFINANCE: {link: string; price: number}; VIVUS: {link: string; price: number}}}
 */
export const InsuranceLink = {
  SMSFINANCE: 'https://www.smsfinance.ru/pages/insurance',
  VIVUS: 'https://www.vivus.ru/pages/insurance',
};

/**
 *  Links to account smsfinance and vivus
 * @type {{SMSFINANCE: string; VIVUS: string}}
 */
export const PersonalAccount = {
  SMSFINANCE: 'https://www.smsfinance.ru/login',
  VIVUS: 'https://www.vivus.ru/login',
};

/**
 * Reference to the agreement on the use of ASP
 * @type {{SMSFINANCE: string; VIVUS: string}}
 */
export const AgreementASP = {
  // SMSFINANCE: 'https://www.smsfinance.ru/asp-rules',
  // VIVUS: 'https://www.vivus.ru/agreement/asp-rules',
  SMSFINANCE: 'https://www.smsfinance.ru/documents/redaction/19/0',
  VIVUS: 'https://www.vivus.ru/documents/redaction/19/0',
};

/**
 * Link to the partners website
 * @type {string}
 */
export const PartnerSitesLink = 'https://route.zaym.org.ru/';

/**
 * Link to the vivus/smsfinance for pollination
 * @type {string}
 */
export const PartnerSitesLinkPollination = {
  VIVUS: 'https://www.vivus.ru/',
  SMSFINANCE: 'https://www.smsfinance.ru/'
}

/**
 * Link to the partners website
 * @type {string}
 */
export const QiwiRatesSitesLink = 'https://qiwi.com/bank/rates';

/**
 * Link to the partners website
 * @type {string}
 */
export const SIABSitesLink = 'https://siab.ru/about/documents/';
export const FinstarSitesLink = 'https://finstarbank.ru/about/documents/';

/**
 * medical insurance
 */
export const MedicalInsuranceLink = 'https://drive.insurfinanceconsulting.ru/offer-med-online.pdf'

/**
 * Link to the partners website
 * @type {string}
 */
export const InsuranceSitesLink = 'https://www.smsfinance.ru/pages/insurance';

/**
 * Link to the partners website
 * @type {string}
 */
export const LawyerConsultingSiteLink =
  'https://adva.org.ru/media/content/company/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%90%D0%91%D0%9E%D0%9D%D0%95%D0%9D%D0%A2%D0%A1%D0%9A%D0%9E%D0%93%D0%9E_%D0%9E%D0%91%D0%A1%D0%9B%D0%A3%D0%96%D0%98%D0%92%D0%90%D0%9D%D0%98%D0%AF_ADVA_%D0%94%D0%9B%D0%AF_%D0%9A%D0%9B%D0%98%D0%95%D0%9D%D0%A2%D0%9E%D0%92_%D0%9E%D0%9E%D0%9E_%D0%9C%D0%9A%D0%9A_ii6R6Cp.pdf';

/**
 * Link to the partners website
 * @type {string}
 */
export const VetPetConsultingSiteLink =
  'https://vet-pet.online/wp-content/uploads/2021/11/oferta.-pravila-abonentskogo-veterinarnogo-obsluzhivaniya-vetpet-dlya-klientov-ooo-mkk-dzhet-mani-mikrofinans_1-1.pdf';

/**
 * Link to the partners website
 * @type {string}
 */
export const AstrologerConsultingSiteLink =
  'https://astars.online/wp-content/uploads/2023/03/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%9F%D0%A0%D0%95%D0%94%D0%9E%D0%A1%D0%A2%D0%90%D0%92%D0%9B%D0%95%D0%9D%D0%98%D0%AF_%D0%A3%D0%A1%D0%9B%D0%A3%D0%93_ASTARS_%D0%94%D0%9B%D0%AF_%D0%9A%D0%9B%D0%98%D0%95%D0%9D%D0%A2%D0%9E%D0%92_%D0%9E%D0%9E%D0%9E_%D0%9C%D0%9A%D0%9A-4.pdf';

/**
 * Link to the partners website
 * @type {string}
 */
export const PsychologistConsultingSiteLink =
  'https://vipsy.online/wp-content/uploads/2023/03/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%9F%D0%A0%D0%95%D0%94%D0%9E%D0%A1%D0%A2%D0%90%D0%92%D0%9B%D0%95%D0%9D%D0%98%D0%AF_%D0%A3%D0%A1%D0%9B%D0%A3%D0%93_VIPSY_%D0%94%D0%9B%D0%AF_%D0%9A%D0%9B%D0%98%D0%95%D0%9D%D0%A2%D0%9E%D0%92_%D0%9E%D0%9E%D0%9E_%D0%9C%D0%9A%D0%9A_%D0%94%D0%96%D0%95%D0%A2-2.pdf';

/**
 * Links to account in case of loan refusal
 * @type {{SMSFINANCE: string; VIVUS: string}}
 */
export const PersonalAccountChsale = {
  SMSFINANCE: 'https://www.smsfinance.ru/cabinet/chsale',
  VIVUS: 'https://www.vivus.ru/chsale',
};

/**
 * Link to QIWI website
 * @type {{QIWI: string; YANDEX: string}}
 */
export const PaymentSystemLink = {
  YANDEX: 'https://yoomoney.ru/',
  QIWI: 'https://qiwi.com/',
  QIWIMap: 'https://qiwi.com/replenish/map.action',
  QIWIPayment: {
    SMSFINANCE: 'https://qiwi.com/payment/form/30118',
    VIVUS: 'https://qiwi.com/payment/form/30170',
  },
  BANKMAP: 'https://www.pochtabank.ru/map',
  CONTACTMAP: 'https://www.contact-sys.com/where',
  EUROSETMAP: 'https://euroset.ru/novosibirsk/shops',
};

/**
 * Links to rules
 * @type {{
 *  SMSFINANCE: {
 *    provisionAndServicing: string;
 *    acceptanceOfServices: string;
 *    processingOfPersonalData: string;
 *    disclosureOfCreditHistory: string;
 *    agreementOnTheUseASP: string;
 *    getInformation: string;
 *  }; VIVUS: {
 *    provisionAndServicing: string;
 *    acceptanceOfServices: string;
 *    processingOfPersonalData: string;
 *    disclosureOfCreditHistory: string;
 *    agreementOnTheUseASP: string
 *    getInformation: string;
 *   };
 *  }}
 */
export const RulesLinks = {
  SMSFINANCE: {
    provisionAndServicing: 'https://www.smsfinance.ru/rulesfz',
    commonRulez: 'https://www.smsfinance.ru/documents/redaction/17/0',
    commonRulezJMM: 'https://www.smsfinance.ru/documents/redaction/18/0',
    acceptanceOfServices:
      'https://www.smsfinance.ru/document?title=SERVICE_AGREEMENT',
    processingOfPersonalData: 'https://www.smsfinance.ru/personal-data-rules',
    siteUsageRules: 'https://www.smsfinance.ru/campaign?code=SiteUsageRules',
    disclosureOfCreditHistory: 'https://www.smsfinance.ru/credit-history-rules',
    agreementOnTheUseASP: 'https://www.smsfinance.ru/asp-rules',
    getInformation: 'https://www.smsfinance.ru/personal-get-information',
    stockTermsGraceInTimeCheaper:
      'https://www.smsfinance.ru/discount-terms?name=STOCK_TERMS_GRACE_IN_TIME_CHEAPER',
  },
  VIVUS: {
    provisionAndServicing: 'https://www.vivus.ru/privacypolicy',
    commonRulez: 'https://www.vivus.ru/documents/redaction/17/0',
    commonRulezJMM: 'https://www.vivus.ru/documents/redaction/18/0',
    acceptanceOfServices: 'https://www.vivus.ru/agreement/service-policy',
    processingOfPersonalData: 'https://www.vivus.ru/agreement/privacy-policy',
    siteUsageRules: 'https://www.vivus.ru/campaign?code=SiteUsageRules',
    disclosureOfCreditHistory: 'https://www.vivus.ru/agreement/credit-history',
    agreementOnTheUseASP: 'https://www.vivus.ru/agreement/asp-rules',
    getInformation: 'https://www.vivus.ru/agreement/get-information',
    stockTermsGraceInTimeCheaper:
      'https://www.vivus.ru/discount-terms?name=STOCK_TERMS_GRACE_IN_TIME_CHEAPER',
  },
};

/**
 * Link to social net
 */
export const SocialList = [
  {
    title: 'Мы во ВКонтакте',
    link: {
      SMSFINANCE: 'https://vk.com/zaimy_online_smsfinans',
      VIVUS: 'https://new.vk.com/zaimy_online_vivus',
    },
    image: 'assets/images/icons/005-vk.png',
  },
  {
    title: 'Мы в Одноклассниках',
    link: {
      SMSFINANCE: 'http://www.odnoklassniki.ru/group/51850314776771',
      VIVUS: 'http://ok.ru/group/55034180206608',
    },
    image: 'assets/images/icons/002-odnoklassniki.png',
  },
  {
    title: 'Наше приложение для iOS',
    link: {
      SMSFINANCE:
        'https://app.adjust.com/um2sqg_9y7yhu?campaign=Site_Smsfinance_header',
      VIVUS: 'https://app.adjust.com/d43sea_ls4j2o?campaign=Site_Vivus_header',
    },
    image: 'assets/images/icons/004-apple.png',
  },
  {
    title: 'Наше приложение для Android',
    link: {
      SMSFINANCE:
        'https://app.adjust.com/9y7yhu_um2sqg?campaign=Site_Smsfinance_header',
      VIVUS: 'https://app.adjust.com/ls4j2o_d43sea?campaign=Site_Vivus_header',
    },
    image: 'assets/images/icons/003-android.png',
  },
];

export const CollectionAgencyNotice = {
  SMSFINANCE: 'https://www.smsfinance.ru/media/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD_%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_izn7ouY.pdf',
  VIVUS: 'http://www.vivus.ru/media/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD_%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf',
}
