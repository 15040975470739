import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  Sentry.init({
    dsn: 'https://72103bf46f2846238958bd9bbe21a52b@snt.smsfinanceit.ru/14',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
