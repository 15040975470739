import routesConst from './routes.const';

export const returnMessageList = {
  SCORING_FAILED: 'Вернуться',
  SCORING_EXPIRED: 'К запросу суммы займа',
  NO_LOAN_NO_REQUEST: 'К получению займа',
  NO_LOAN_REQUEST_CONFIRMATION: 'К получению займа',
  NO_LOAN_REQUEST_WAITING: 'К получению займа',
  LOAN_ISSUED: 'К займу',
  ACTIVE_LOAN: 'К займу',
};

export const childPageList = [
  routesConst.CABINET_DOCUMENT_PAGE,
  routesConst.USER_PROFILE_PAGE,
];
