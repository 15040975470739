import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import detectMobile from '../../../../utils/detect-mobile';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.sass'],
})

/**
 * TitleComponent - Stateless component. Required to display titles.
 * position - Position of the element horizontally. Default value - left.
 * size - Font size. The font size is specified in px. Default value - large.
 */
export class TitleComponent {
  @Input() position: string;
  @Input() size: string;
  @Input() style: any;

  public brand = environment.brand;
  public isMobile = detectMobile();
}
