import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {EsiaStatuses} from "../constants/global.constant";

@Injectable()
export class RegistrationService {

  private esiaStatuses = EsiaStatuses;
  private registrationInfo$ = new BehaviorSubject({data: null})
  constructor() {}

  public get registrationInfo(): Observable<any> {
    return this.registrationInfo$
  }

  public setRegistrationInfo(registrationForm: any) {
    this.registrationInfo$.next(registrationForm)
  }

  public getEsiaErrorMessage(error: string) {
    const {
      VALIDATION_EXCEPTION,
      ACCESS_DENIED_EXCEPTION,
      AUTHORIZATION_EXCEPTION,
      CLIENT_NOT_VERIFIED,
      UNKNOWN_EXCEPTION,
    } = this.esiaStatuses;

    switch (error) {
      case VALIDATION_EXCEPTION.type:
        return VALIDATION_EXCEPTION.message;
      case ACCESS_DENIED_EXCEPTION.type:
        return ACCESS_DENIED_EXCEPTION.message;
      case AUTHORIZATION_EXCEPTION.type:
        return AUTHORIZATION_EXCEPTION.message;
      case CLIENT_NOT_VERIFIED.type:
        return CLIENT_NOT_VERIFIED.message;
      case UNKNOWN_EXCEPTION.type:
        return UNKNOWN_EXCEPTION.message;
      default:
        return null
    }
  }
}
