import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { AnalyticsEventsService } from '../../../services/analytic.service';

@Directive({ selector: '[appAnalytics]' })
export class AppAnalyticsDirective {
  @Input() props;
  @Input() fieldName;
  @Input() formGroup;

  private startDate;
  private stopDate;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private analyticService: AnalyticsEventsService
  ) {}

  @HostListener('focusin') onFocusIn() {
    this.startTimer();
  }

  @HostListener('change') onChange() {
    if (this.props) {
      if (this.props.parent.get(this.props.fieldName).valid) {
        const time = this.endTimer();
        this.analyticService.sendTimeAnalytics(
          this.props.fieldName,
          time + '',
          time
        );
      }
    } else {
      if (this.formGroup.get(this.fieldName).valid) {
        const time = this.endTimer();
        this.analyticService.sendTimeAnalytics(this.fieldName, time + '', time);
      }
    }
  }

  startTimer() {
    this.startDate = Date.now();
  }

  endTimer() {
    this.stopDate = Date.now();
    const msStart = this.startDate;
    const msStop = this.stopDate;
    return Math.round((msStop - msStart) / 1000);
  }

  getErrorName(error) {
    let result = '';
    if (error.includes('pattern')) {
      result += 'неправильный формат ввода ';
    }

    if (error.includes('maxlength')) {
      result += 'превышена максимальная длина  ';
    }

    if (
      error.includes('notfulldate') ||
      error.includes('phonelength') ||
      error.includes('lengthpassportseries') ||
      error.includes('lengthpassportnumber') ||
      error.includes('lengthsubdivision')
    ) {
      result += 'заполнено не полностью ';
    }

    if (
      error.includes('notexisteddate') ||
      error.includes('nonexistentphone') ||
      error.includes('forbiddenseries')
    ) {
      result += 'такого значения не существует ';
    }

    if (error.includes('dob')) {
      result += 'возраст меньше 18 лет ';
    }

    if (error.includes('minyear')) {
      result += 'дата меньше 1917г ';
    }

    if (error.includes('coincidenceofphones')) {
      result += 'номер совпадает с основным ';
    }

    if (error.includes('notfuturedate')) {
      result += 'дата не может быть в будущем ';
    }

    if (error.includes('checkpassportdate')) {
      result += 'паспорт просрочен ';
    }

    if (error.includes('lengthpassword') || error.includes('middle')) {
      result += 'пароль недостаточно сложный ';
    }

    return result;
  }
}
