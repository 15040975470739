export enum JuicyProcessType {
  REGISTRATION = 'REGISTRATION',
  RESCORING = 'RESCORING',
}

export enum JuicySourceType {
  PHONE = 'PHONE',
  SITE = 'SITE',
}

export interface JuicyRequestParams {
  processType: JuicyProcessType;
  sourceType: JuicySourceType;
  juicyCookie: string;
}
