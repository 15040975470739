import {Component, Input, OnInit} from '@angular/core';
import {JtiBrands} from "../../../../constants/jti.const";
import {ActionsService} from "../../../../api/actions.service";
import {JTIAgeApprove, JtiBrand, JTIDto, JtiModel, JtiSteps} from "../../../../classes/jti.interface";
import {ClientInfoService} from "../../../../services/client-info.service";
import {Client} from "../../../../classes/client.class";
import {StorageService} from "../../../../services/storage.service";
import * as moment from "moment-mini-ts";
import {setServerDateFormat} from "../../../../utils/set-client-date-format";

@Component({
  selector: 'app-jti',
  templateUrl: './jti.component.html',
  styleUrls: ['./jti.component.sass'],
})
export class JtiComponent implements OnInit {

  @Input() client: Client | undefined

  public step = JtiSteps.FIRST
  public steps = JtiSteps
  public brands = JtiBrands

  public selectedBrand: JtiBrand

  constructor(
    private _actionService: ActionsService,
    private _clientService: ClientInfoService,
    private _storageService: StorageService
  ) {}

  ngOnInit() {
  }

  /**
   * Задаёт шаг отображения плашки
   * cabinet/waiting?source=scoring
   * @param step
   */
  setNextStepTo(step: JtiSteps) {
    this.step = step
  }


  selectBrand(brand: JtiBrand) {
    this.selectedBrand = brand
    this.step = JtiSteps.THIRD
  }

  selectModel(model: JtiModel) {
    this.step = JtiSteps.FOURTH

    const registrationFormNumber = this._storageService.get('REGISTRATION');
    const esia = this._storageService.get('ESIA');

    const prepareData = (jtiModel: JtiModel): JTIDto => {

      const ageApprove = esia === 'true' ? JTIAgeApprove.ESIA : JTIAgeApprove.PASSPORT

      const prepareGender = (gender: string) => (gender.includes('Мужской') ? 'M' : 'F')

      const {
        firstName,
        lastName,
        middleName,
        dob,
        email,
        sex,
        mobile } = this.client

      return {
        firstName: firstName,
        secondName: lastName,
        ageApprove: ageApprove,
        birthday: moment(dob).format('YYYY-MM-DD'),
        email: email,
        gender: prepareGender(sex),
        phone: mobile.toString(),
        registrationFormNumber: registrationFormNumber,
        subBrand: jtiModel.subbrandId,
        patronymic: middleName

      }
    }

    this._actionService.sendJtiLead(prepareData(model))
      .subscribe(
        v => {
          console.log(v)
          if (!v.data) {
            console.error(v)
          }
        })
  }

}
