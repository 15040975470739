export enum JtiSteps {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2,
  FOURTH = 3,
  EMPTY = 4,
}

export interface JtiModel {
  name: string
  subbrandId: string
}

export interface JtiBrand {
  name: string
  models: JtiModel[]
}

export enum JTIAgeApprove {
  PASSPORT = 'PASSPORT',
  ESIA = 'ESIA'
}

export interface JTIDto {
  firstName: string
  secondName: string
  patronymic: string
  gender: string
  birthday: string
  email: string
  phone: string
  ageApprove: JTIAgeApprove
  subBrand: string
  registrationFormNumber: string
}
