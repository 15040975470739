import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.components.html',
  styleUrls: ['./icon.components.sass'],
})

/**
 *
 */
export class IconComponent {
  @Input() imgPath: string;
  @Input() alt: string;
  @Input() style: any;
}
