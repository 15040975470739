import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable()
export class YandexMapService {
  @Output() mapFitToViewport: EventEmitter<boolean> = new EventEmitter();

  public initFitToViewport(): void {
    this.mapFitToViewport.emit(true);
  }

  public getFitToViewport(): EventEmitter<boolean> {
    return this.mapFitToViewport;
  }
}
