import { Component, HostListener, ViewChild } from '@angular/core';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'app-ui-helper',
  templateUrl: './ui-helper.component.html',
  styleUrls: ['./ui-helper.component.sass'],
})
export class UiHelperComponent {
  @ViewChild(TooltipComponent)
  public tooltip: TooltipComponent;

  @HostListener('mouseover') onFocusIn() {
    this.tooltip.openModal();
  }

  @HostListener('mouseleave') onFocusOut() {
    this.tooltip.closeModal();
  }
}
