import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ruLocatePipe',
})
export class ruLocatePipe implements PipeTransform {
  transform(value: string, format?: string) {
    const datePipe = new DatePipe('ru');
    value = datePipe.transform(value, format);
    return value;
  }
}
