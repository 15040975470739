import { Injectable, OnDestroy } from '@angular/core';
import { ActionsService } from '../api/actions.service';
import {
  ScoringType,
  TimeoutPeriod,
  ProductType,
} from '../constants/global.constant';
import Routes from '../constants/routes.const';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { ClientInfoService } from './client-info.service';
import { Client } from '../classes/client.class';

@Injectable()
export class ScoringService implements OnDestroy {
  private sub: any;
  private _product: string;
  private _client: Client;
  private _clientSub: any;

  constructor(
    private actionsService: ActionsService,
    private router: Router,
    private _storageService: StorageService,
    private _clientInfoService: ClientInfoService
  ) {
    this._product = this._storageService.check('LOAN_PRODUCT', 'cookie')
      ? this._storageService.get('LOAN_PRODUCT', 'cookie')
      : ProductType.PDL;

    this._clientSub = this._clientInfoService
      .getClient()
      .subscribe((response) => {
        this._client = response;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._clientSub.unsubscribe();
  }

  /**
   * scotingResult - Asynchronous request and receives in response three status: ACCEPTED, REJECTED, WAITING.
   * In case of receiving the WAITING status, the request is again.
   * @returns {Subscription}
   */
  scotingResult(strategy?) {
    const { ACCEPTED, REJECTED, WAITING } = ScoringType;

    const { SCORING_RESULT_SUCCESSFUL, SCORING_RESULT_FAILED } = Routes;

    const { SCORING_RESULT_TIME } = TimeoutPeriod;

    return (this.sub = this.actionsService
      .getScoringResult(strategy)
      .subscribe((res) => {
        const data = res.data;

        this._clientInfoService.setClient({
          ...this._client,
          limit: data.limit,
        });
        switch (data.state) {
          case ACCEPTED:
            if (strategy) {
              return this.router.navigate([SCORING_RESULT_SUCCESSFUL], {
                queryParams: {
                  strategy: strategy ? strategy : '',
                  result: true,
                },
                queryParamsHandling: 'merge',
              });
            } else {
              return this.router.navigate([SCORING_RESULT_SUCCESSFUL], {
                queryParamsHandling: 'merge',
              });
            }
          case REJECTED:
            if (strategy) {
              return this.router.navigate([SCORING_RESULT_SUCCESSFUL], {
                queryParams: {
                  strategy: strategy,
                  result: false,
                },
                queryParamsHandling: 'merge',
              });
            } else {
              return this.router.navigate([SCORING_RESULT_FAILED], {
                queryParamsHandling: 'merge',
              });
            }
          case WAITING:
            setTimeout(() => {
              return this.scotingResult(strategy);
            }, SCORING_RESULT_TIME);
            break;
          default:
            return;
        }
      }));
  }
}
