import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
})

/**
 * ButtonComponent  - Stateless component. Required to display button.
 * position - Position of the element horizontally. Default value - left.
 * size - Font size. The font size is specified in px. Default value - large.
 */
export class ButtonComponent {
  @Input() position: string;
  @Input() size: string;
  @Input() disabled: boolean;
  @Input() class: string;

  @Output() pressed: EventEmitter<any> = new EventEmitter();

  public pressButton(): void {
    this.pressed.emit();
  }

  public brand = environment.brand;
}
