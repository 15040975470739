export const JtiBrands = [
  {
    "name": "21 ВЕК",
    "models": [
      {
        "name": "KING SIZE",
        "subbrandId": "05BCE2C1-D271-43DA-87C5-E204A6A735CE"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "7FB852F4-42E8-4447-A445-1FFF2DCA11DF"
      }
    ]
  },
  {
    "name": "APOLLO",
    "models": [
      {
        "name": "SP",
        "subbrandId": "BD6595B1-BFE8-4DDF-AC4F-F29EAA9E40A5"
      }
    ]
  },
  {
    "name": "БАЛКАНСКАЯ ЗВЕЗДА",
    "models": [
      {
        "name": "HP",
        "subbrandId": "cb9f3380-a81c-6cc5-14b7-ba44a73a76d7"
      }
    ]
  },
  {
    "name": "BLOOMING",
    "models": [
      {
        "name": "SUPER SLIMS",
        "subbrandId": "0CD625BA-5D73-4D68-9B76-BE47F645F548"
      }
    ]
  },
  {
    "name": "BONBON",
    "models": [
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "f06fa0ae-e8c8-6555-9d85-7e5b65274623"
      }
    ]
  },
  {
    "name": "BOND",
    "models": [
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "0F561204-65F7-4A77-98CE-1769FC79DBE3"
      },
      {
        "name": "COMPACT KING SIZE SLIMS",
        "subbrandId": "A11A0961-0149-4FC6-99D1-1FCC6A04CF4C"
      },
      {
        "name": "REGULAR",
        "subbrandId": "DDB5D07E-BD7B-40FA-84C0-9F4196BBCB18"
      },
      {
        "name": "REGULAR PREMIUM",
        "subbrandId": "A5C66020-3C3A-4FF7-9DB3-8A7785D2A6C0"
      },
      {
        "name": "SPECIAL KING SIZE SUPER SLIMS",
        "subbrandId": "038c1214-26f3-4b35-03b4-737f6c29d02b"
      },
      {
        "name": "SPECIAL SLIMS",
        "subbrandId": "d21f7135-d76b-6319-2c96-e9b3914bdd6e"
      }
    ]
  },
  {
    "name": "CAMEL",
    "models": [
      {
        "name": "COMPACT",
        "subbrandId": "ADC713A5-91A2-4633-B30D-DE52090CB370"
      },
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "58EE1CA9-5878-ED14-A7AD-ED89AF9AA643"
      },
      {
        "name": "COMPACT CAPSULE 100",
        "subbrandId": "6421951b-48e3-6159-d583-9483ef415a53"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "C6CC9937-DC71-E090-99FC-63FB44D7E53A"
      },
      {
        "name": "KING SIZE (VALUE)",
        "subbrandId": "2af085b4-13ee-1e12-bf49-7b304b725ffe"
      }
    ]
  },
  {
    "name": "CHAPMAN",
    "models": [
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "c89b2a48-78af-578d-2e4a-c4a500ee4b23"
      },
      {
        "name": "REGULAR",
        "subbrandId": "d9ff3efd-6adf-685d-b65b-2ebdf6569d95"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "2eaf2b9b-b534-b9fc-5750-9892e82954bc"
      }
    ]
  },
  {
    "name": "CHESTERFIELD",
    "models": [
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "31673fa8-e75d-1d2c-4752-710d9de7ef34"
      },
      {
        "name": "KING SIZE SLIMS (LOW)",
        "subbrandId": "381b5552-4ff7-2662-31e5-88c47bcbb778"
      },
      {
        "name": "KING SIZE SLIMS (LOW) CAPSULE",
        "subbrandId": "d6c5690a-2daf-4fb9-b7f0-377abec85039"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "6E397783-4EE6-46FA-AB2C-AA7174F1C447"
      },
      {
        "name": "REGULAR",
        "subbrandId": "56346F5A-736D-44CD-8323-641F2095F14D"
      },
      {
        "name": "REGULAR (LOW)",
        "subbrandId": "7118f2b1-2392-3a74-d99e-9f83868fd037"
      }
    ]
  },
  {
    "name": "CONTINENT",
    "models": [
      {
        "name": "COMPACT",
        "subbrandId": "C2C20C86-FEF1-423A-9DFC-D26EA4B49CF7"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "2E54A890-E108-41AC-89F5-BFB99219EFEE"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "D5D988B9-6565-4451-909D-0ABF6B147AB8"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "EBE2BEB4-9653-456C-91CC-DEAFDF8A22C4"
      }
    ]
  },
  {
    "name": "DAVIDOFF",
    "models": [
      {
        "name": "DAVIDOFF",
        "subbrandId": "0a7d2719-01e5-525b-557c-c9943286fdb9"
      },
      {
        "name": "REACH FOCUS KING SIZE SUPER SLIMS",
        "subbrandId": "29c97886-dd09-37f2-f268-6904670797e4"
      },
      {
        "name": "REACH KING SIZE SLIMS",
        "subbrandId": "c5a594de-0875-ff21-dcbf-13d300752eaa"
      },
      {
        "name": "REACH KING SIZE SLIMS CAPSULE",
        "subbrandId": "885dfaa6-23ff-49ea-6aab-5cde8599d5a6"
      },
      {
        "name": "REFINE KING SIZE SUPER SLIMS",
        "subbrandId": "81cd1369-5b89-dbcf-3941-e02fcc5f1021"
      },
      {
        "name": "REGULAR",
        "subbrandId": "5A502C97-DB9B-496D-82AB-7865FFBAD84E"
      },
      {
        "name": "SHAPE KING SIZE SLIMS",
        "subbrandId": "C613C9C3-C485-4138-9417-0BC514EF65A5"
      },
      {
        "name": "SLIMS",
        "subbrandId": "1D52A1B5-862E-4115-9860-5217A663B54A"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "1DC18B57-9A42-44C7-B403-A98DB0EFE784"
      }
    ]
  },
  {
    "name": "ДОНСКОЙ ТАБАК",
    "models": [
      {
        "name": "COMPACT",
        "subbrandId": "1272C78E-E2F9-4474-8B9B-A56114CFDA33"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "0F89457D-945F-41E9-9780-942DFBFE0A7F"
      }
    ]
  },
  {
    "name": "DUNHILL",
    "models": [
      {
        "name": "FINE CUT",
        "subbrandId": "09E175C3-BF19-4A80-99F7-802F557318CB"
      },
      {
        "name": "REGULAR",
        "subbrandId": "58DA0635-D179-484C-8BBA-98498A0CBAAA"
      }
    ]
  },
  {
    "name": "ESSE",
    "models": [
      {
        "name": "CORE CAPSULE",
        "subbrandId": "91a447fb-baf0-c326-28c8-e3246f90c1b1"
      },
      {
        "name": "CORE SUPER SLIMS",
        "subbrandId": "2575e62f-71f5-951c-e115-9369df43f3b0"
      },
      {
        "name": "GOLDEN LEAF",
        "subbrandId": "26538b71-d02c-5a99-a846-2b8c41c5856d"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "5a9d79f7-80cb-1db3-54cf-0114629c7854"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "93791142-3777-4B5C-A811-4A26752C48FA"
      },
      {
        "name": "KING SIZE SUPER SLIMS CAPSULE",
        "subbrandId": "c9a2f4b5-5631-896b-61ed-788e1d6c3d53"
      },
      {
        "name": "SECRET",
        "subbrandId": "423e3789-63c7-25ea-ebac-8dcbe4c80e52"
      },
      {
        "name": "SPECIAL EDITION",
        "subbrandId": "A63785B7-EE48-4034-B418-AC8E4CDB6F9E"
      },
      {
        "name": "SUPER SLIMS AURA AROMA",
        "subbrandId": "afc8200b-4be7-5d71-1872-db39c217e2ed"
      }
    ]
  },
  {
    "name": "EVE",
    "models": [
      {
        "name": "SLIMS",
        "subbrandId": "52C6DF7F-62B0-41AF-93E5-DB20E953C17B"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "35A228A4-D1CF-428B-9B99-9A7407B3621A"
      }
    ]
  },
  {
    "name": "FIIT",
    "models": [
      {
        "name": "FIIT",
        "subbrandId": "8c3fa54b-b036-ffe3-9341-00264b1e3655"
      }
    ]
  },
  {
    "name": "GLO",
    "models": [
      {
        "name": "GLO",
        "subbrandId": "26cdb87b-eeee-1181-3f1f-8e7a880f31be"
      },
      {
        "name": "GLO 2",
        "subbrandId": "3f0a2369-8462-3f9c-87b3-4d56336f1b30"
      },
      {
        "name": "HYPER",
        "subbrandId": "96582a85-d541-8822-809b-b78f79be5ee1"
      },
      {
        "name": "HYPER PLUS",
        "subbrandId": "680583be-fa15-ebfc-1cd8-6ad1a9b2d33b"
      },
      {
        "name": "MINI",
        "subbrandId": "19383ded-27e0-5101-8776-8ffd1dd3657d"
      },
      {
        "name": "NANO",
        "subbrandId": "468d1853-cba9-4f5b-0df1-d856b4d17f22"
      },
      {
        "name": "PRO",
        "subbrandId": "cfbc3a4e-ce39-54f6-96d8-15f342fee6f2"
      },
      {
        "name": "PRO SLIM",
        "subbrandId": "b038e21f-1707-efe8-37ed-83e9658b65de"
      }
    ]
  },
  {
    "name": "HEETS",
    "models": [
      {
        "name": "CREATIONS",
        "subbrandId": "13897960-9ee9-1f67-8c42-9b381aef3423"
      },
      {
        "name": "REGULAR",
        "subbrandId": "7640098d-3541-d1ce-4d65-38af55b6761e"
      }
    ]
  },
  {
    "name": "IQOS",
    "models": [
      {
        "name": "IQOS",
        "subbrandId": "F5C17B1B-E51D-0015-6994-AD559930F712"
      },
      {
        "name": "2.4 PLUS",
        "subbrandId": "131be7a5-ba02-b3d9-284d-d555d0870010"
      },
      {
        "name": "3",
        "subbrandId": "ff714148-152d-efe0-b145-36977fff8695"
      },
      {
        "name": "3 DUOS",
        "subbrandId": "b3d15a3b-6f61-e607-3617-47280181df23"
      },
      {
        "name": "3 MULTI",
        "subbrandId": "7e268a35-2818-f919-9cee-adbbb555abb6"
      }
    ]
  },
  {
    "name": "JADE",
    "models": [
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "KING SIZE SLIMS CAPSULE"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "1dc29b82-bf0b-26e4-00d2-9ef6c1a59b3b"
      },
      {
        "name": "SUPER SLIMS AROMA",
        "subbrandId": "443d0198-e8ab-5318-c68e-6e3f385a4865"
      },
      {
        "name": "SUPER SLIMS CAPSULE",
        "subbrandId": "5c3d9057-2911-60c6-ef99-6f771b1c29c7"
      }
    ]
  },
  {
    "name": "KENT",
    "models": [
      {
        "name": "CRYSTAL KING SIZE SLIMS",
        "subbrandId": "de0bf93f-6723-f5ad-a6f7-e7e8f3e4392e"
      },
      {
        "name": "D-SERIES SLIMS",
        "subbrandId": "0D59CB39-D691-0458-98A4-A3B41BF69C07"
      },
      {
        "name": "NANO CAPSULE",
        "subbrandId": "F96384F8-6EDD-3B89-4C54-88476585C659"
      },
      {
        "name": "NANOTEK",
        "subbrandId": "FE275380-8796-414A-B1AE-2AA92FA6F361"
      },
      {
        "name": "REGULAR",
        "subbrandId": "4DD0E28F-A335-4ABF-B38E-1BD9C5503922"
      },
      {
        "name": "REGULAR (LOW)",
        "subbrandId": "4d92194b-9c4b-fba1-8ae8-2fced88da06c"
      },
      {
        "name": "SLIMS CAPSULE",
        "subbrandId": "F850AA54-C93A-D491-EEFF-18D7BAF1F763"
      },
      {
        "name": "SLIMS CAPSULE DUO",
        "subbrandId": "DB15A7FD-64A4-08F9-3424-5B5DFDF557FA"
      },
      {
        "name": "STICKS",
        "subbrandId": "ab5fcb0b-1c79-8f6d-fc22-8372577d1664"
      }
    ]
  },
  {
    "name": "KISS",
    "models": [
      {
        "name": "SUPER SLIMS",
        "subbrandId": "6F9948C7-8831-441D-ABF7-80B94170EF3F"
      },
      {
        "name": "SUPER SLIMS AROMA",
        "subbrandId": "6B2BCA43-490B-46A6-83AD-BF8EFEAA528E"
      },
      {
        "name": "SUPER SLIMS CAPSULE",
        "subbrandId": "a566bd9e-9fbc-f369-145b-9e9452a3c7cc"
      }
    ]
  },
  {
    "name": "L&M",
    "models": [
      {
        "name": "COMPACT 100",
        "subbrandId": "24497025-B0AC-4A31-BB58-D649D95208E9"
      },
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "737a2ba9-053a-3967-1930-e8b59480f17d"
      },
      {
        "name": "COMPACT KING SIZE SLIMS",
        "subbrandId": "5B52D92D-81D6-4E81-A465-B88E27F94E62"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "7EFC86C9-D932-4773-98DF-F0C92F428C5C"
      },
      {
        "name": "REGULAR",
        "subbrandId": "D3C050AB-5ADA-47A2-B442-628D0FD8B1D7"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "E8845825-9718-48E5-B7C7-2CC725593B30"
      }
    ]
  },
  {
    "name": "LD",
    "models": [
      {
        "name": "AUTOGRAPH CLUB",
        "subbrandId": "910AFAEE-5DE9-C505-7306-B1C3C3DC3DCB"
      },
      {
        "name": "AUTOGRAPH COMPACT",
        "subbrandId": "EA0D62F3-EDC3-2609-3871-10250DFFF331"
      },
      {
        "name": "AUTOGRAPH COMPACT 100",
        "subbrandId": "B4314665-83C9-456E-C37E-FE1A95F50565"
      },
      {
        "name": "AUTOGRAPH COMPACT CAPSULE",
        "subbrandId": "595a7e5d-e32b-e746-b990-f018dc5832dc"
      },
      {
        "name": "AUTOGRAPH COMPACT CAPSULE 100",
        "subbrandId": "18fd5f9c-19b8-23e8-d13c-3d8275e19672"
      },
      {
        "name": "AUTOGRAPH KING SIZE",
        "subbrandId": "B64B035B-4F94-58C0-B974-218A6135110C"
      },
      {
        "name": "AUTOGRAPH REG 100",
        "subbrandId": "429DA935-8A99-F8D6-D189-CE414C23F30B"
      },
      {
        "name": "AUTOGRAPH SUPER SLIMS",
        "subbrandId": "6A64DA16-6F5A-4602-B150-A675B81934AC"
      }
    ]
  },
  {
    "name": "LIL",
    "models": [
      {
        "name": "SOLID",
        "subbrandId": "9564131c-a67f-7ce6-4fa1-59eecaa799c1"
      },
      {
        "name": "SOLID 2.0",
        "subbrandId": "bb935331-d540-84a1-c0ec-a89f7200dd0d"
      }
    ]
  },
  {
    "name": "LUCKY STRIKE",
    "models": [
      {
        "name": "LUCKY STRIKE",
        "subbrandId": "A38FC69D-CD87-453D-A695-9DDC71F4AE79"
      },
      {
        "name": "(LOW)",
        "subbrandId": "7944f410-1f36-9d45-6300-83bac5171ffc"
      },
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "abf0b6a0-f8a6-79ea-457c-65faa9c4819d"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "957d23ff-0c50-2a0b-ffc5-67d18ca91e7d"
      },
      {
        "name": "SLIMS CAPSULE",
        "subbrandId": "18fb9976-e29e-5ae6-8e5f-e52cc39440a1"
      }
    ]
  },
  {
    "name": "MARLBORO",
    "models": [
      {
        "name": "CLEAR TASTE MICRO",
        "subbrandId": "5D03FCD5-F26B-4DF2-A436-90D0E5020840"
      },
      {
        "name": "CLEAR TASTE TOUCH",
        "subbrandId": "368CF533-A042-43A3-8775-36A2002EB840"
      },
      {
        "name": "CRAFTED",
        "subbrandId": "1a1ca86e-4a0d-18f3-7a78-395d6688be68"
      },
      {
        "name": "ICE",
        "subbrandId": "5E830E26-6937-488E-B1EF-4AF5309AF427"
      },
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "c33aa684-53a3-e11e-413d-a67dd14c3081"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "32F37EA6-F190-831A-EF3F-FDA9F582C6BB"
      },
      {
        "name": "PLUS",
        "subbrandId": "C78F3EA8-E4C3-4716-88F1-367E7ACE04A9"
      },
      {
        "name": "REGULAR",
        "subbrandId": "661EA6B2-E8B1-4356-8A8B-3F836FC038A6"
      },
      {
        "name": "VISTA",
        "subbrandId": "d78f3a34-f957-eea0-ba12-03c2bc4caa79"
      }
    ]
  },
  {
    "name": "МАКСИМ",
    "models": [
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "D5640BBB-9394-416A-94D3-8B5659CF33E0"
      },
      {
        "name": "PREMIUM",
        "subbrandId": "F146F0D4-7D74-4538-844F-D828004BCCD4"
      },
      {
        "name": "REGULAR",
        "subbrandId": "2DBB4D7C-1FD9-4402-97CD-CE0ACBF9EBF5"
      },
      {
        "name": "REGULAR 100",
        "subbrandId": "F9AE18C7-F3B7-43BC-9024-11A904EC7192"
      }
    ]
  },
  {
    "name": "MEVIUS",
    "models": [
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "fff2c7e7-eda2-493f-0908-6b6e46fc5d79"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "484CCF96-4FD6-4EDD-92A9-10ECE7C28465"
      },
      {
        "name": "KING SIZE SSB",
        "subbrandId": "AD554299-9627-49EB-ADF1-0686A859362C"
      }
    ]
  },
  {
    "name": "NEO",
    "models": [
      {
        "name": "DEMI",
        "subbrandId": "9bd4fa9d-26f9-dc7a-5e5e-acfadfc5f405"
      },
      {
        "name": "REGULAR",
        "subbrandId": "a7ccf07a-a9e0-d05d-47f2-799906b29f21"
      }
    ]
  },
  {
    "name": "NEXT",
    "models": [
      {
        "name": "COMPACT 100",
        "subbrandId": "8959EAE0-A8B5-4B77-8298-D93A6C0656E6"
      },
      {
        "name": "COMPACT KING SIZE SLIMS",
        "subbrandId": "E59C94BE-2AC2-46B2-BD92-E529296CF885"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "5C00784A-440E-4E1E-BD10-E1152974CB3D"
      },
      {
        "name": "REGULAR",
        "subbrandId": "9CC50788-E144-427E-AEAD-317D19FF509D"
      },
      {
        "name": "SELECTION",
        "subbrandId": "4ABD6A32-408B-40B6-A40D-B99050C18C18"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "B7EAC73C-29E1-4BE6-B609-6F9B0A2240B7"
      },
      {
        "name": "SUPER SLIMS CAPSULE",
        "subbrandId": "08c79b43-2cd8-2642-120c-d087bfeba122"
      }
    ]
  },
  {
    "name": "ОПТИМА",
    "models": [
      {
        "name": "COMPACT KING SIZE SLIMS",
        "subbrandId": "75F6BBB6-58EC-4A3C-BF0E-A6650EDC35A0"
      }
    ]
  },
  {
    "name": "PALL MALL",
    "models": [
      {
        "name": "NANOKINGS",
        "subbrandId": "ACB72EA7-D3F0-4803-A1C2-C11914FC9954"
      },
      {
        "name": "REGULAR",
        "subbrandId": "B6DF36FC-FA26-476A-9419-F1FACAF9D41E"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "31BB3836-4A6A-43DE-92E0-C3D607835EB1"
      }
    ]
  },
  {
    "name": "PARKER&SIMPSON",
    "models": [
      {
        "name": "KING SIZE",
        "subbrandId": "82F37244-4BE8-4B73-8A0E-9A5BD3A0F17F"
      },
      {
        "name": "KING SIZE CAPSULE",
        "subbrandId": "8652acd3-ccf5-d757-3143-1d99249b3430"
      },
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "D8A2C946-BCFC-496C-A7B6-42265D44EE1A"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "767C8C34-32DC-B607-4726-CAAC9A9B2349"
      },
      {
        "name": "REGULAR 100",
        "subbrandId": "b29cf3f5-b433-2452-6675-1f21af066e83"
      },
      {
        "name": "SP",
        "subbrandId": "55dbaf16-2782-1c1c-4f6f-d59395e42644"
      }
    ]
  },
  {
    "name": "PARLIAMENT",
    "models": [
      {
        "name": "CARAT SLIMS 90",
        "subbrandId": "90fd1160-92ef-b29a-bd25-b45ad5a6ca70"
      },
      {
        "name": "CARAT SUPER SLIMS 90",
        "subbrandId": "67E48C49-A9F3-45CE-9C3D-848647031421"
      },
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "d199b1f6-1523-b41a-37a4-997b32a199c0"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "ebc8ea4b-3b80-fd29-789c-fd50a51d9d85"
      },
      {
        "name": "P",
        "subbrandId": "69BB8A57-2219-4A1E-80EB-3FC4CD47D959"
      },
      {
        "name": "P COMPACT CAPSULE",
        "subbrandId": "7a29b0fe-cac5-914a-1390-7603ec165064"
      },
      {
        "name": "REGULAR",
        "subbrandId": "B8DCB5B5-2425-46A3-A20A-8B5DAE94B6DF"
      },
      {
        "name": "RESERVE KING SIZE SUPER SLIMS",
        "subbrandId": "01EAB14D-DB51-4E80-BA32-427A0D424BF2"
      },
      {
        "name": "RESERVE SUPER SLIMS 100",
        "subbrandId": "CEC282EC-587A-4ABD-B648-89B90F05DA1A"
      },
      {
        "name": "SLIMS 100",
        "subbrandId": "5e11154e-99b4-4ec9-d281-5d83173fdb8d"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "83dd0477-3977-ac3a-c0b5-aedd1decd303"
      },
      {
        "name": "VOYAGE MIX KING SIZE SUPER SLIMS CAPSULE",
        "subbrandId": "965b1bd2-2155-26f8-c18a-5127a730b2a7"
      }
    ]
  },
  {
    "name": "ПЁТР I",
    "models": [
      {
        "name": "COMPACT",
        "subbrandId": "637d059c-08d0-4719-bb77-eb8dff782631"
      },
      {
        "name": "KS",
        "subbrandId": "23812ff1-ac4d-4419-a269-5477e251cb1e"
      },
      {
        "name": "COMPACT 100",
        "subbrandId": "74823C12-B8F4-E29E-C089-F2DA539F5927"
      },
      {
        "name": "OSOBYE",
        "subbrandId": "E7A7BBD0-4A7A-4287-9B2D-87157EBBC652"
      },
      {
        "name": "30",
        "subbrandId": "4c63d72a-e451-4a89-a8a3-55538231c2df"
      }
    ]
  },
  {
    "name": "PHILIP MORRIS",
    "models": [
      {
        "name": "KING SIZE",
        "subbrandId": "9AE4CCD1-9A58-4CEB-87D0-0064505AD490"
      },
      {
        "name": "KING SIZE SLIMS",
        "subbrandId": "BDC9BA09-AC37-415D-B284-90D79AEFB1C1"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE",
        "subbrandId": "6E8DFE22-43E6-0247-DDB1-F3820BEE4B1B"
      },
      {
        "name": "KING SIZE SLIMS CAPSULE VALUE",
        "subbrandId": "3d5ce3b0-93d6-3a21-27e2-06a850fb9311"
      }
    ]
  },
  {
    "name": "PLAY",
    "models": [
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "3B576B57-D67C-8D42-CDA3-CF6298871607"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "E3813822-6141-4E8C-8FD7-71C021CC2E10"
      },
      {
        "name": "SUPER SLIMS CAPSULE",
        "subbrandId": "9f5e8990-6800-7106-bb87-52d109a625dc"
      }
    ]
  },
  {
    "name": "ROTHMANS",
    "models": [
      {
        "name": "DEMI CAPSULE",
        "subbrandId": "9261300C-E92E-4185-84B8-C69818511241"
      },
      {
        "name": "DEMI KING SIZE SLIMS",
        "subbrandId": "4585BC66-4F59-4E3A-BE34-FCB1B26A980F"
      },
      {
        "name": "NANOKINGS",
        "subbrandId": "784F9F6A-161B-4047-9A45-248CEFC578B7"
      },
      {
        "name": "REGULAR",
        "subbrandId": "987E2A5C-1A6C-4948-BEF5-90CFA1F5E162"
      },
      {
        "name": "SLIMS",
        "subbrandId": "77dbb01c-a6b5-f358-4ccd-6119711b6db4"
      },
      {
        "name": "SUPER SLIMS 100",
        "subbrandId": "EB20683D-F26C-4D3B-BE41-DF038D429FD8"
      },
      {
        "name": "SUPER SLIMS 100 CLICK",
        "subbrandId": "9ac08612-4e79-a769-b710-521a7ca26c03"
      }
    ]
  },
  {
    "name": "ROYALS",
    "models": [
      {
        "name": "DEMI CAPSULE",
        "subbrandId": "e0ea7a81-fb82-bdae-636d-17eda5b4fddd"
      },
      {
        "name": "DEMI KING SIZE SLIMS",
        "subbrandId": "9987A7B3-4CDE-4D55-9135-D278CBA7D383"
      },
      {
        "name": "NANOKINGS",
        "subbrandId": "127790BF-4FF0-9E29-1547-31187DAEBF9E"
      },
      {
        "name": "NANOKINGS CAPSULE",
        "subbrandId": "a70dafe8-7b32-5a07-fad9-4c8d77245abc"
      },
      {
        "name": "REGULAR",
        "subbrandId": "9DD95D0D-BACB-478E-A13B-BEFFBCEF689E"
      }
    ]
  },
  {
    "name": "РУССКИЙ CТИЛЬ",
    "models": [
      {
        "name": "100",
        "subbrandId": "91c1ca8d-37fb-0c37-4534-093a1682f22a"
      },
      {
        "name": "COMPACT",
        "subbrandId": "1fa1f2ea-a40c-4484-8e87-bb14e512cf68"
      },
      {
        "name": "REGULAR",
        "subbrandId": "4e193fee-c754-4c80-878e-d9de0c046e47"
      }
    ]
  },
  {
    "name": "SHUANGXI",
    "models": [
      {
        "name": "SSL",
        "subbrandId": "374E4F88-B14A-4EC2-AB37-D996CECF52D2"
      }
    ]
  },
  {
    "name": "SOBRANIE",
    "models": [
      {
        "name": "AROMA",
        "subbrandId": "b6f64ee2-d89e-1267-ca71-19899e4eaac8"
      },
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "35B72CF3-0E10-1ACC-47DD-4BA4C520EC04"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "35672AB2-BBBD-4E5D-A4FF-B607596909C3"
      },
      {
        "name": "KING SIZE SUPER SLIMS",
        "subbrandId": "DE5B2E2C-E27D-4336-95D8-01808B57C643"
      },
      {
        "name": "LAUBE",
        "subbrandId": "2f7ab113-0da3-b717-e706-18a07bddce8e"
      },
      {
        "name": "MENTHOL",
        "subbrandId": "f7b4917c-79eb-f6b7-0984-d3a052613eb6"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "753DD8DC-5EE6-4F75-B1D7-8591D941E6D8"
      }
    ]
  },
  {
    "name": "STYLE",
    "models": [
      {
        "name": "SUPER SLIMS",
        "subbrandId": "e018638f-1f9f-43dc-b73a-7c96e9497181"
      }
    ]
  },
  {
    "name": "ТРОЙКА",
    "models": [
      {
        "name": "TROIKA",
        "subbrandId": "004AF242-86E6-4995-B0D1-B6B5BD528D70"
      }
    ]
  },
  {
    "name": "VICEROY",
    "models": [
      {
        "name": "VICEROY",
        "subbrandId": "F3121B10-30E5-43C7-BDBA-14ED739759E8"
      }
    ]
  },
  {
    "name": "VOGUE",
    "models": [
      {
        "name": "AROMA",
        "subbrandId": "3C9747DE-157F-4276-B035-20088749C170"
      },
      {
        "name": "CLASUPER SLIMSIC MOMENTS",
        "subbrandId": "8f518e7a-1cce-00da-267e-371a60f014c7"
      },
      {
        "name": "CORE",
        "subbrandId": "72993B60-DEAD-466F-82C3-3DB281221C3E"
      },
      {
        "name": "MIX MOMENTS",
        "subbrandId": "f3d9e7ba-60c8-b030-1d62-6e201c65b8d1"
      },
      {
        "name": "STICKS",
        "subbrandId": "faebcc7b-10ff-7952-feec-ab7502680cd3"
      },
      {
        "name": "UNIQUE CLICK",
        "subbrandId": "1307F7EA-E8E5-8DC4-6166-A20F9B10D4A8"
      }
    ]
  },
  {
    "name": "WEST",
    "models": [
      {
        "name": "COMPACT KING SIZE SLIMS",
        "subbrandId": "89A148AB-2370-4A58-A9C1-02FE7BF392BF"
      },
      {
        "name": "DUO",
        "subbrandId": "F36A38E0-023A-40BB-9B2E-C89A63B4AB9C"
      },
      {
        "name": "FUSION KING SIZE SUPER SLIMS",
        "subbrandId": "5508D75D-F448-43A7-94DC-F69CF9B25571"
      },
      {
        "name": "NF",
        "subbrandId": "FDD70D18-4967-4466-BEC9-21696DA773E0"
      },
      {
        "name": "ORIGINAL",
        "subbrandId": "ff262a65-ad20-0d96-61da-082dc005cbb6"
      },
      {
        "name": "STREAMTEC",
        "subbrandId": "10966501-8AA8-47D0-9FCF-A17E6F97E230"
      },
      {
        "name": "STREAMTEC 25",
        "subbrandId": "0E98A36C-4D30-43DC-BEE6-E5B6FC3AE8EF"
      }
    ]
  },
  {
    "name": "WINSTON",
    "models": [
      {
        "name": "CLUB",
        "subbrandId": "4e670405-5859-1fe2-94fb-008dd9bd343e"
      },
      {
        "name": "COMPACT",
        "subbrandId": "A04AABAB-5CA9-418D-8AFE-E09F23107CF0"
      },
      {
        "name": "COMPACT 100",
        "subbrandId": "6008cd05-4d34-0325-7833-6dd85dfcc410"
      },
      {
        "name": "COMPACT CAPSULE",
        "subbrandId": "D0F2A339-B656-83B4-058E-7E44F2B8178C"
      },
      {
        "name": "KING SIZE",
        "subbrandId": "F8CEAF53-A1D6-46EC-8382-AAA83E1C6C53"
      },
      {
        "name": "REGULAR",
        "subbrandId": "5ff87307-b6ed-d7f0-286b-5bce277deec5"
      },
      {
        "name": "SUPER SLIMS",
        "subbrandId": "A37D4A10-CEC0-4A79-9C73-01B8F3F84ED5"
      },
      {
        "name": "XS",
        "subbrandId": "34FEDDC2-1456-BD92-452E-C7E2A3965415"
      },
      {
        "name": "XS CAPSULE",
        "subbrandId": "A74F0458-F032-48BA-BFD2-AC618554536C"
      },
      {
        "name": "XSTYLE",
        "subbrandId": "8D75BA99-9249-453D-8790-A4EDE898BD23"
      }
    ]
  },
  {
    "name": "ЯВА",
    "models": [
      {
        "name": "GOLD DEMI",
        "subbrandId": "6B89AB0E-A81C-BA1B-47DC-4A93C64A37E7"
      },
      {
        "name": "GOLD REGULAR",
        "subbrandId": "15C3D4B5-0E9C-47D0-8817-8F6102C08F5E"
      },
      {
        "name": "GOLD REGULAR 100",
        "subbrandId": "D95986BA-677F-4146-B49A-CB2E07AF1048"
      },
      {
        "name": "GOLD TURBO",
        "subbrandId": "1E3F7048-0E7E-4ACE-8310-7ED848EFCF36"
      },
      {
        "name": "HP",
        "subbrandId": "699B920B-11C2-456D-BAEB-23BD2677D94D"
      },
      {
        "name": "SP",
        "subbrandId": "B79B46B2-C3C0-488D-96F7-4F9C48744A34"
      },
      {
        "name": "WHITE GOLD",
        "subbrandId": "9E0C162B-9BBB-4437-A95E-8738EF0CE69E"
      },
      {
        "name": "WHITE GOLD REGULAR 100",
        "subbrandId": "5e2aa600-e57e-d4b9-b394-d601d01f8e39"
      }
    ]
  },
  {
    "name": "Другой бренд",
    "models": [
      {
        "name": "Другое BAT",
        "subbrandId": "38950B66-CD7F-4F96-A3C9-8C78ED0CC96D"
      },
      {
        "name": "Другое ITG",
        "subbrandId": "f1668dd8-efa6-b672-93ff-0465ee54f313"
      }
    ]
  }
]
