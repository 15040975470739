import { Client } from './client.class';
import { CampaignInformation } from '../components/credit-calculator/credit-calculator.class';

export interface Loans {
  amount: number; // Loan amount
  amountRemain: number; // Current amount to return
  amountToReturn: number; // General amount to return
  client?: Client; // Client info
  creationDate: number; // Issued date
  returnDate: number; // Date of repayment
  gracePeriod: number; // Period without percent
  id: number; // Loan id
  loanPeriod: number; // Current period for loan
  stake: number; // Curent percent stake
  transferCommission: number; // Transfer commission depend of payment type
  lastDigits?: number; // If payment type - 'CARD', last 4 digits of card
  penalty: number; // Loan fee
  dayPenalty: number; // Cost 1 day of penalty
  activeCreditRequestId: number;
  fee: number; // Loan interest
  dayFee: number; // Cost 1 day of fee
  paymentSchedules?: PaymentSchedule[]; // If annuity loan, payment schedules
  prolongationState?: ProlongationStateList; // Current state for prolongation
  availableProlongationDays?: number; // Current date for prolongation
  insuranceInfo: InsuranceInfo; // Info about insurance,
  mainDeptRemain: number; // Main debt remainder
  loanConfirmationInfo: LoanConfirmationInfo;
  productType: string;
  recurringState: string;
  ownMoneyRemain: number; // Own funds
  loanPaymentTypeInfo: LoanPaymentTypeInfo;
  campaignInformation: CampaignInformation; // информация об акции
  graceProlongAmount: number; // стоимость продления акции по грейсу
  restrictionDate: number; // дата запрета по ОД
  isLastPayment: boolean; // последний платеж по графику платежей
  astrologerConsultingIncluded?: boolean; // флаг КП Астролог
  psychologistConsultingIncluded?: boolean; // флаг КП Психолог
  legalEntity?: LegalEntityList; // На какую компанию оформлен займ
  commissionRemain: number; // комиссия
  overdueCommissionRemain: number; // процентная комиссия
  additionalProductsAvailable: boolean; // панель выбора доп продуктов доступна клиенту
  psySubscriptionAvailable?: boolean;
  psySubscriptionActive: boolean;
  dietInfoSubscriptionActive: boolean;
  lawyerConsultingInfo: LawyerConsultingInfo;
  vetPetConsultingInfo: VetPetConsultingInfo;
  dietPlanInfoDto: DietPlanInfoDto;
  dietConsultingInfoDto: DietConsInfoDto;
  psychologistConsultingInfo: PsychologistConsultingInfo;
  astrologerConsultingInfo: AstrologerConsultingInfo;
  elderInfoDto: ElderInfoDto;
  dateConclusionLastAdditionalAgreementOnProlong: number; // Дата заключения последнего доп.соглашения о продлении займа
  currentLoanOverdue: boolean; //true - продлил займ на просрочке, false - продлил займ в основном периоде
  countProlong: number; //информация о количестве продлений
  dateEndAgreementOnProlong: number; // дата окончания действия доп.соглашения о продлении займа
  subscriptionAvailable: SubscriptionType; // вид подписки, который нужно отображать в продлении
}

export interface LoanCustomerData {
  active: boolean;
  amount: number;
  contractNumber: string;
  issueDate: number;
  legalEntity: string;
  productType: string;
  status: string;
}

export interface LawyerConsultingInfo {
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface VetPetConsultingInfo {
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface DietPlanInfoDto {
  type: string;
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface DietConsInfoDto {
  type: string;
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface ElderInfoDto {
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface PsychologistConsultingInfo {
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface AstrologerConsultingInfo {
  instantPayment: boolean;
  purchaseAmount: number;
}

export interface PaymentSchedule {
  paymentDate: number; // Payment date
  debt: number; // Main debt
  fee: number; // Commission
  remain: number; // Remain
  status: string; // Payment status
}

export enum ProlongationStateList {
  AVAILABLE = 'AVAILABLE', // Prolongation available
  UNAVAILABLE = 'UNAVAILABLE', // Prolongation unavailable for current client state (ex. grace period, annuity)
  ACTIVE = 'ACTIVE', // Loan has active prolongation
  CONFIRMATION = 'CONFIRMATION', // Waiting confirmation for prolongation
  ILLEGAL = 'ILLEGAL', // Client can no longer prolong his loan
}

export enum LegalEntityList {
  JMM = 'JMM',
  FOUR_FINANCE = 'FOUR_FINANCE',
}

export enum SubscriptionType {
  DIETINFO = 'DIETINFO',
  MLPSY = 'MLPSY',
}

export interface InsuranceInfo {
  availability: boolean; // Insurance availability information
  detailsUrl: string; // Link to insurance information
  insuranceIncluded: boolean; // Current insurance state,
  showInsurance: boolean; //подходит для отображения чего-либо, обрабатывается на бэке по условию: true - если есть страховка или была страхова и её отключили, false - если страховку изначально не подключали
  insuranceCost?: number; // Cost of insurance
  instantPayment: boolean;
  type: string;
}

export interface LoanConfirmationInfo {
  msLeft: number;
}

export interface LoanPaymentTypeInfo {
  additionalFields: AdditionalFields;
  loanPaymentType: string;
}

export interface AdditionalFields {
  code: string;
  transactionId: string;
}
